import * as Sentry from '@sentry/react';
import React, { useEffect, useContext } from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from "./histroy";
import App from "./app/App";
import Login from "./modules/auth/Login";
import PrivateRoute from "./PrivateRoute";
import ResetPassword from "./modules/auth/ResetPassword";
import ChangePassword from "./modules/auth/ChangePassword";
import Authorize from "./modules/auth/Authorize";
import Logout from "./modules/auth/Logout";
import { ROUTES } from "./common/constants";
import { AppContext } from "./AppContext";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "assets/vendors/style";

const Routes = () => {
  const { initializeAuth } = useContext(AppContext);

  useEffect(() => {
    initializeAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const MyFallbackComponent = ({ error, componentStack }) => (
    <div>
      <p>
        <strong>Oops! An error occured!</strong>
      </p>
      <p>Below is the details…</p>
      <p>
        <strong>Error:</strong> {error.toString()}
      </p>
      <p>
        <strong>Stacktrace:</strong> {componentStack}
      </p>
    </div>
  );

  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router history={history}>
        <Switch>
          <Route exact path={ROUTES.RESET} component={ResetPassword} />
          <Route exact path={ROUTES.VERIFY} component={ChangePassword} />
          <Route exact path={ROUTES.CHANGE_PASSWORD} component={ChangePassword} />
          <Route exact path={ROUTES.AUTHORIZE} component={Authorize} />
          <Route exact path={ROUTES.LOGOUT} component={Logout} />
          <Route path={ROUTES.LOGIN} component={Login} />
          <PrivateRoute path="/" component={App} />
        </Switch>
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default Routes;
