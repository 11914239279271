import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "../../common/constants";
import LanguagesList from "./languagesList";
import LanguagesChapterContent from "./LanguagesChapterContent";
import "./languages.less";

const Languages = () => {
  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path={`${ROUTES.LANGUAGES_CHAPTER_EDIT}/:id`}
          component={LanguagesChapterContent}
        />
        <Route
          exact
          path={`${ROUTES.LANGUAGES}`}
          component={LanguagesList}
        />
      </Switch>
    </Fragment>
  );
};

export default Languages;
