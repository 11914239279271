import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "../../common/constants";
import OrganizationList from "./OrganizationList";
import OrganizationAdd from "./OrganizationAdd";
import OrganizationView from "./OrganizationView";
const Organization = () => {
  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path={`${ROUTES.ORGANIZATIONS_EDIT}`}
          component={OrganizationAdd}
        />
        <Route
          exact
          path={`${ROUTES.ORGANIZATIONS_EDIT}/:id`}
          component={OrganizationAdd}
        />
        <Route
          exact
          path={`${ROUTES.ORGANIZATIONS_VIEW}`}
          component={OrganizationView}
        />
        <Route
          exact
          path={`${ROUTES.ORGANIZATIONS}`}
          component={OrganizationList}
        />
      </Switch>
    </Fragment>
  );
};

export default Organization;
