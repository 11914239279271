import gql from "graphql-tag";

export const GET_LANGUAGES = gql`
  query languages($skip: Int, $first: Int, $orderBy: LanguageOrderByInput) {
    languages(skip: $skip, first: $first, orderBy: $orderBy) {
      total
      data {
        id
        name
        share_title
        share_description
        availability
        is_active
        book_id
        text_dam_id
        audio_dam_id
        created_at
        updated_at
        bible_id
        audience_target
        video_dam_id
        service_type
        owner_name
        repo_name
      }
    }
  }
`;

export const GET_CHAPTERS = gql`
  query chapters($where: chaptersWhereInput) {
    chapters(where: $where) {
      data{
        chapter_title
        chapter_id
        videos{
          thumbnail_url
          video_url
          description
        }
        language_id
      }
      count
      language {
        name
        video_dam_id
      }
    }
  }
`;

export const GET_CHAPTER_DETAIL = gql`
  query chapter($where: ChapterWhereInput){
    chapter(where: $where){
      chapter_title
      videos{
        id
        video_url
        thumbnail_url
        description
        type
      }
      chapter_id
      language_id
    }
  }
`;
