import gql from "graphql-tag";

export const GET_DONATIONS = gql`
  query donations(
    $skip: Int
    $first: Int
    $orderBy: DonationOrderByInput
    $where: DonationWhereInput
  ) {
    donations(skip: $skip, first: $first, orderBy: $orderBy, where: $where ) {      
      total
      total_revenue      
      number_of_gifts
      average_gift 
      data {
        id
        amount
        donation_date
        donation_type 
        user {
          first_name
          last_name
        }        
        created_at
        updated_at
      }
    }
  }
`;