import React, { useState, useRef, useEffect } from "react";
import { Card, Tooltip, Button, Switch, message } from "antd";
import { get } from "lodash";
import CommonTable from "../../components/CommonTable";
import { GET_USERS } from "./graphql/Queries";
import AddUserModal from "./AddUserModal";
import { ROLES } from "../../common/constants";
import { EditOutlined } from "@ant-design/icons";
import { formatPhoneNumber } from "../../common/utils";
import client from "../../apollo";
import { UPDATE_USER } from "./graphql/Mutations";
import { GET_ORGANIZATIONS } from "../organization/graphql/Queries";

const UserList = () => {
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState();
  const tableRef = useRef();
  const [organizationOptions, setOrganizationOptions] = useState([]);

  // useEffect(() => {
  //   tableRef.current = React.createRef();
  // }, []);

  useEffect(() => {
    client.query({
      query: GET_ORGANIZATIONS,
      fetchPolicy: "network-only"
    }).then((res) => {
      setOrganizationOptions(get(res, "data.organizations.data"));
    }).catch(() => { 
    }).finally(() => {
    });
  }, []);

  const handleAdd = () => {
    setShowModal(true);
  };

  const onEditHandle = (data) => {
    setUserData(data);
    setShowModal(true);
  };

  const onSwichHandle = async (value, id) => {
    message.loading("Updating...");
    try {
      await client.mutate({
        mutation: UPDATE_USER,
        variables: { is_active: value, id: id },
        refetchQueries: [
          {
            query: GET_USERS,
          },
        ],
      });
    } catch (error) {
      return error;
    }
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      width: "10%",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      width: "10%",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "20%",
    },
    {
      title: "Contact Number",
      dataIndex: "phone_no",
      width: "20%",
      render: (phone) => formatPhoneNumber(phone),
    },
    {
      title: "Position",
      dataIndex: "roles",
      width: "20%",
      render: (roles) => ROLES[roles],
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "10%",
      render: (id, record) => {
        return (
          <div className="action-icons">
            <Tooltip title="Switch">
              <Switch
                defaultChecked={record.is_active}
                onChange={(e) => {
                  onSwichHandle(e, id);
                }}
              />
            </Tooltip>
            <Tooltip title="Edit">
              <Button type="link" onClick={() => onEditHandle(record)}>
                <EditOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <Card>
      {showModal && (
        <AddUserModal
          tableRef={tableRef.current}
          showModal={showModal}
          setShowModal={setShowModal}
          setUserData={setUserData}
          userData={userData}
          isUpdate={userData ? true : false}
          organizationOptions={organizationOptions}
        />
      )}

      <div className="d-flex align-items-center justify-content-between">
        <h1>User Management</h1>
        <Button type="primary" onClick={handleAdd}>
          Add User
        </Button>
      </div>
      <hr />
      <CommonTable
        // ref={tableRef}
        setTableMethods={({ refreshList }) => {
          tableRef.current = refreshList;
        }}
        query={GET_USERS}
        columns={columns}
        responseAccessor="data.users.data"
        totalAccessor="data.users.total"
      />
    </Card>
  );
};

export default UserList;
