import React, { useState, useContext } from "react";
import { Layout, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Link, withRouter } from "react-router-dom";
import { ROUTES, MODULES } from "../../../common/constants";
import logo from "../../../assets/logo.png";
import icons from "../../../assets/drawer-icons/drawerIcons";
import { AppContext } from "../../../AppContext";

const { Sider } = Layout;

function Sidebar({ location: { pathname }, history }) {
  const { getCurrentUserRole } = useContext(AppContext);
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const onMenuSelect = (e) => {
    history.push(e.key);
  };

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="gx-layout-sider-header">
        <div className="gx-linebar">
          <div className="gx-icon-btn icon" onClick={toggle}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
        </div>
        <Link to="/" className="gx-site-logo">
          <img src={logo} alt="Pocket Testament League" />
        </Link>
      </div>
      <div className="gx-sidebar-content">
        {
          (getCurrentUserRole() === "SUPER_ADMIN" || getCurrentUserRole() === "ADMIN") ?
          <Menu
            theme="lite"
            mode="inline"
            selectedKeys={[`/${pathname.split("/")[1]}`]}
            defaultSelectedKeys={[ROUTES.DASHBOARD]}
            onSelect={onMenuSelect}
          >
            <Menu.Item
              key={ROUTES.DASHBOARD}
              className="d-flex align-items-center"
            >
              <i className="anticon">
                <icons.Dashboard />
              </i>
              <span>{MODULES.DASHBOARD}</span>
            </Menu.Item>
            <Menu.Item
              key={ROUTES.TWENTY_ONE_DAY_CHALLENGE}
              className="d-flex align-items-center"
            >
              <i className="anticon">
                <icons.DaysChallenge />
              </i>
              <span>{MODULES.TWENTY_ONE_DAY_CHALLENGE}</span>
            </Menu.Item>
            <Menu.Item
              key={ROUTES.SALVATION_TEXT}
              className="d-flex align-items-center"
            >
              <i className="anticon">
                <icons.SalvationText />
              </i>
              <span>{MODULES.SALVATION_TEXT}</span>
            </Menu.Item>
            <Menu.Item
              key={ROUTES.LANGUAGES}
              className="d-flex align-items-center"
            >
              <i className="anticon">
                <icons.Languages />
              </i>
              <span>{MODULES.LANGUAGES}</span>
            </Menu.Item>
            <Menu.Item
              key={ROUTES.DONATION}
              className="d-flex align-items-center"
            >
              <i className="anticon">
                <icons.Donation />
              </i>
              <span>{MODULES.DONATION}</span>
            </Menu.Item>          
            <Menu.Item
              key={ROUTES.ORGANIZATIONS}
              className="d-flex align-items-center"
            >
              <i className="anticon">
                <icons.Organizations />
              </i>
              <span>{MODULES.ORGANIZATIONS}</span>
            </Menu.Item>
            <Menu.Item
              key={ROUTES.USERS_MANAGEMENT}
              className="d-flex align-items-center"
            >
              <i className="anticon">
                <icons.Covers />
              </i>
              <span>{MODULES.USERS_MANAGEMENT}</span>
            </Menu.Item>
            <Menu.Item
              key={ROUTES.NOTIFICATION}
              className="d-flex align-items-center"
            >
              <i className="anticon">
                <icons.Notification />
              </i>
              <span>{MODULES.NOTIFICATION}</span>
            </Menu.Item>
          </Menu>
          :
          <Menu
            theme="lite"
            mode="inline"
            selectedKeys={[`/${pathname.split("/")[1]}`]}
            defaultSelectedKeys={[ROUTES.DASHBOARD]}
            onSelect={onMenuSelect}
          >
            <Menu.Item
              key={ROUTES.DASHBOARD}
              className="d-flex align-items-center"
            >
              <i className="anticon">
                <icons.Dashboard />
              </i>
              <span>{MODULES.DASHBOARD}</span>
            </Menu.Item>
          </Menu>
        }
      </div>
    </Sider>
  );
}

export default withRouter(Sidebar);
