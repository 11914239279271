import React, { useContext, useEffect, useState } from "react";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Form, Input, Button, Spin, message } from "antd";
import { REFRESH_TOKEN, ROUTES } from "../../common/constants";
import { formValidatorRules } from "../../common/utils";
import logo from "../../assets/logo.png";
import client from "../../apollo";
import { AppContext } from "../../AppContext";
import { LOGIN } from "./graphql/Mutations";
import { get } from "lodash";
import apiInstance from "../../common/api";
const { required, email } = formValidatorRules;

const Authorize = (props) => {
  const { initializeAuth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);


  function successCallback(accessToken) {
    initializeAuth(accessToken);
    if (props?.location?.state?.currentPage) {
      props.history.replace(props.history.location.state.currentPage);
    } else {
      props.history.replace("/");
    }
  }

  const onFinish = async () => {
    const token = localStorage.getItem(REFRESH_TOKEN);
    let values = { refresh_token: token }
    var queryString = Object.keys(values)
      .map((key) => key + "=" + values[key])
      .join("&");
    setLoading(true);
    if (token) {
      try {
        const response = await apiInstance("/refresh-token", {
          method: "POST",
          data: queryString,
          headers: {
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },

        });
        const accessToken = get(response, "data.access_token");
        localStorage.setItem(REFRESH_TOKEN, get(response, "data.refresh_token"));
        if (successCallback) {
          successCallback(accessToken);
        }
      } catch (error) {
        if (get(error, "response.data.message")) {
          message.error(error?.response?.data?.message);
        } else if (error?.message) {
          message.error(error?.message);
        } else {
          message.error("Something went wrong");
        }
        if(error?.response?.status === 401){
          setTimeout(() => {
            props.history.replace("/logout");
          }, 500);
        }
      } finally {
        setLoading(false);
      }
    } else {
      props.history.replace("/logout");
    }
  };

  useEffect(() => {
    onFinish()
  }, []);

  return (
    <div className="gx-login-container">
      <Spin spinning={loading} wrapperClassName="gx-login-content">
      </Spin>
    </div>
  );
};

export default Authorize;
