import React, { Fragment, useState, useEffect, useContext } from "react";
import { get } from "lodash";
import { Card, Spin, message } from "antd";
import apiInstance from "../../common/api";
import { AppContext } from "../../AppContext";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [organizationIframe, setOrganizationIframe] = useState('');
  const { state: { selectedOrganization: { uuid: selectedUuid } } } = useContext(AppContext);

  const onLoadIframe = () => {
    if (organizationIframe) {
      setLoading(false);
    }
  };

  const getOrganizationIframeHandler = async (orgUuid) => {
    setLoading(true);
    try {
      const response = await apiInstance("/ptl/app/analytics", {
        method: "GET",
        params: {
          id: orgUuid
        },
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      });
      if (response) {
        const iframeURL = get(response, "data.url");
        if (iframeURL) {
          setOrganizationIframe(iframeURL);
        }
      }
    } catch (error) {
      if (get(error, "response.data.message")) {
        message.error(error?.response?.data?.message);
      } else if (error?.message) {
        message.error(error?.message);
      } else {
        message.error("Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (selectedUuid && !(selectedUuid === "all")) {
      getOrganizationIframeHandler(selectedUuid);
    }
  }, [selectedUuid]);

  return (
    <Fragment>
      {
        selectedUuid && selectedUuid === "all" ?
          <Spin spinning={loading}>
            <Card className="iframe-container">
              <iframe width="100%" height="100%" frameBorder="0" allowFullScreen
                title="all"
                onLoad={onLoadIframe}
                src="https://datastudio.google.com/embed/reporting/e9a7b907-d133-4a32-9647-95f0cdca8ae4/page/vm0ZC" />
            </Card>
          </Spin>
        :
          <Spin spinning={loading}>
            <Card className="iframe-container">
              <iframe width="100%" height="100%" frameBorder="0" allowFullScreen
                title={organizationIframe}
                onLoad={onLoadIframe}
                src={organizationIframe}
              />
            </Card>
          </Spin>
      }
    </Fragment>
  )
};

export default Dashboard;
