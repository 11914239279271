import React, { Fragment, useState, useEffect } from "react";
import {
  ArrowLeftOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { Card, Row, Col, Tooltip, Button, Spin, Modal } from "antd";
import SalvationContent from "./SalvationContent";
import client from "../../apollo";
import { CREATE_SALVATION_TEXT, DELETE_SALVATION_CONTENT } from "./graphql/Mutations";
import { GET_SALVATION } from "./graphql/Queries";
import { get } from "lodash";
import { formatDate } from "../../common/utils";
import { secondaryDateFormat } from "../../common/constants";

const { confirm } = Modal;

const SalvationTextAdd = (props) => {
  const [version, setVersion] = useState();
  const [isEdit, setIsEdit] = useState(true);
  const [isView, setIsView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [salvationText, setSalvationText] = useState();
  const [salvationContent, setSalvationContent] = useState();
  const [isContentActive, setIsContentActive] = useState(false);
  const [queryLoading, setQueryLoading] = useState(false);

  const salvationTextId = get(props, "match.params.id");

  const getSalvationData = () => {
    client
      .query({
        query: GET_SALVATION,
        fetchPolicy: "network-only",
        variables: {
          id: parseInt(salvationTextId),
        },
      })
      .then(({ data: { salvationText } }) => {
        setSalvationText(salvationText);
        setVersion(salvationText.version);
      })
      .catch((e) => e)
      .finally(() => {
        setQueryLoading(false);
      });
  }

  useEffect(() => {
    if (salvationTextId) {
      setQueryLoading(true);
      getSalvationData()
    }
  }, [salvationTextId]);

  const updateFound = () => {
    if (salvationTextId) {
      client
        .query({
          query: GET_SALVATION,
          fetchPolicy: "network-only",
          variables: {
            id: parseInt(salvationTextId),
          },
        })
        .then(({ data: { salvationText } }) => {
          setSalvationText(salvationText);
          setVersion(salvationText.version);
        })
        .catch((e) => e);
    }
  };

  const onAddVersion = async (values) => {
    const variables = { version, salvationContents: values };
    setLoading(true);
    client
      .mutate({
        mutation: CREATE_SALVATION_TEXT,
        variables: variables,
      })
      .then(() => {
        props.history.goBack();
      })
      .catch((e) => e)
      .finally(() => {
        setLoading(false);
      });
  };

  const onDeleteHandle = (id) => {
    confirm({
      closable: true,
      icon: <ExclamationCircleOutlined />,
      title: "Are you Sure?",
      content: "Do you want to remove this Salvation Content?",
      okType: "danger",
      onOk: async () => {
        await client
          .mutate({
            mutation: DELETE_SALVATION_CONTENT,
            variables: { id: parseInt(id) },
          })
          .then(() => {
            setQueryLoading(true);
            getSalvationData()
          })
          .catch((e) => e)
      },
    });
  };

  const { salvation_contents: salvationContents, updated_at } =
    salvationText || {};
  return (
    <Fragment>
      {isContentActive ? (
        <SalvationContent
          version={version}
          salvationText={salvationText}
          salvationContent={salvationContent}
          setSalvationContent={setSalvationContent}
          setVersion={setVersion}
          isEdit={isEdit}
          isView={isView}
          setIsView={setIsView}
          setIsEdit={setIsEdit}
          updateFound={updateFound}
          salvationTextId={salvationTextId}
          setIsContentActive={setIsContentActive}
          onAddVersion={onAddVersion}
          setLoading={setLoading}
          loading={loading}
        />
      ) : (
        <Fragment>
          <Card>
            <div className="d-flex flex-row align-items-center justify-content-start">
              <h2>
                <ArrowLeftOutlined
                  className="mr-2"
                  onClick={() => {
                    props.history.goBack();
                  }}
                />
              </h2>
              <div className="d-flex flex-column ">
                <h2>
                  {!queryLoading ? (
                    version || "Salvation Text"
                  ) : (
                    <Spin className="mb-0 ml-1" />
                  )}
                </h2>
                {updated_at && (
                  <div>
                    {`Last updated on ${formatDate(updated_at, secondaryDateFormat)}`}
                  </div>
                )}
              </div>
            </div>
          </Card>
          <Row className="mr-4 ml-4">
            {salvationContents &&
              salvationContents.map((content) => (
                <Col key={content.id} xl={6} md={8} sm={12} xs={24}>
                  <Card
                    className="salvation-card"
                    onClick={() => {
                      setIsContentActive(true);
                    }}
                  >
                    <h2>{content && content.language && content.language.name ? content.language.name : null}</h2>
                    <div className="card-content-container">{content.title}</div>
                    <div className="action-icons mt-1">
                      <Tooltip title="Edit">
                        <Button
                          className="mb-0 mr-0"
                          style={{ marginRight: "0px" }}
                          type="link"
                          onClick={() => {
                            setIsEdit(true);
                            setIsView(false);
                            setSalvationContent(content);
                          }}
                        >
                          <EditOutlined />
                        </Button>
                      </Tooltip>
                      <Tooltip title="View">
                        <Button
                          type="link"
                          className="mb-0 mr-0"
                          onClick={() => {
                            setIsEdit(false);
                            setIsView(true);
                            setSalvationContent(content);
                          }}
                        >
                          <EyeOutlined />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <Button
                          type="link"
                          danger
                          className="mb-0 mr-0"
                          onClick={(e) => {
                            e && e.stopPropagation()
                            setIsEdit(false);
                            onDeleteHandle(content.id)
                          }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Tooltip>
                    </div>
                  </Card>
                </Col>
              ))}
            <Col xl={6} md={8} sm={12} xs={24}>
              <Card
                className="salvation-card"
                onClick={() => {
                  setIsEdit(true);
                  setIsContentActive(true);
                  setSalvationContent();
                }}
              >
                + Add language and content
              </Card>
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};
export default SalvationTextAdd;
