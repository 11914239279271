import React, { Fragment, useState, useEffect } from "react";
import {
  ArrowLeftOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { Card, Row, Col, Tooltip, Button, Spin, Modal } from "antd";
import TwentyOneDayChallengeContent from "./TwentyOneDayChallengeContent";
import client from "../../apollo";
import { CREATE_DAY_CHALLENGE, DELETE_DAY_CONTENT } from "./graphql/Mutations";
import { GET_CHALLENGE } from "./graphql/Queries";
import { get } from "lodash";
import { formatDate } from "../../common/utils";
import { secondaryDateFormat } from "../../common/constants";

const { confirm } = Modal;

const TwentyOneDayChallengeAdd = (props) => {
  const [day, setDay] = useState();
  const [isEdit, setIsEdit] = useState(true);
  const [queryLoading, setQueryLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dayChallenge, setDayChallenge] = useState();
  const [dayContent, setDayContent] = useState();
  const [isContentActive, setIsContentActive] = useState(false);
  const [addaNewLanguage, setAddaNewLanguage] = useState(false)

  const dayId = get(props, "match.params.id");

  useEffect(() => {
    if (dayId) {
      getChallenges(dayId)
    }
  }, [dayId]);

  const getChallenges = (dayId) => {
    setQueryLoading(true);
    client
      .query({
        query: GET_CHALLENGE,
        fetchPolicy: "network-only",
        variables: {
          id: parseInt(dayId),
        },
      })
      .then(({ data: { dayChallenge } }) => {
        setDayChallenge(dayChallenge);
        setDay(dayChallenge.day);
      })
      .catch((e) => e)
      .finally(() => {
        setQueryLoading(false);
      });
  }

  const updateFound = () => {
    if (dayId) {
      client
        .query({
          query: GET_CHALLENGE,
          fetchPolicy: "network-only",
          variables: {
            id: parseInt(dayId),
          },
        })
        .then(({ data: { dayChallenge } }) => {
          setDayChallenge(dayChallenge);
          setDay(dayChallenge.day);
        })
        .catch((e) => e);
    }
  };

  const onAddDay = async (values) => {
    const variables = { day, dayContents: values };
    setLoading(true);
    client
      .mutate({
        mutation: CREATE_DAY_CHALLENGE,
        variables: variables,
      })
      .then(() => {
        props.history.goBack();
      })
      .catch((e) => e)
      .finally(() => {
        setLoading(false);
      });
  };

  const onDeleteDayContent = (content) => {
    if (content && content.id) {
      confirm({
        closable: true,
        icon: <ExclamationCircleOutlined />,
        title: "Are you Sure?",
        content: "Do you want to remove this content?",
        okType: "danger",
        onOk: () => {
          setLoading(true);
          client
            .mutate({
              mutation: DELETE_DAY_CONTENT,
              variables: { id: parseInt(content.id) },
            })
            .then(() => {
              getChallenges(dayId)
            })
            .catch((e) => e)
            .finally(() => {
              setLoading(false);
            });
        },
      });
    }
  };

  const { updated_at, day_contents: dayContents } = dayChallenge || {};
  return (
    <Fragment>
      {isContentActive ? (
        <TwentyOneDayChallengeContent
          day={day}
          dayChallenge={dayChallenge}
          dayContent={dayContent}
          setDayContent={setDayContent}
          setDay={setDay}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          updateFound={updateFound}
          dayId={dayId}
          setIsContentActive={setIsContentActive}
          onAddDay={onAddDay}
          setLoading={setLoading}
          loading={loading}
          addaNewLanguage={addaNewLanguage}
          setAddaNewLanguage={setAddaNewLanguage}
        />
      ) : (
        <Fragment>
          <Card>
            <div className="d-flex flex-row align-items-center justify-content-start">
              <h2>
                <ArrowLeftOutlined
                  className="mr-2"
                  onClick={() => {
                    props.history.goBack();
                  }}
                />
              </h2>
              {!queryLoading ? (
                <div className="d-flex flex-column ">
                  <h2> {day} </h2>
                  {updated_at && (
                    <div>
                      {`Last updated on ${formatDate(updated_at, secondaryDateFormat)}`}
                    </div>
                  )}
                </div>
              ) : (
                <Spin />
              )}
            </div>
          </Card>
          <Row className="mr-4 ml-4">
            {dayContents &&
              dayContents.map((content) => (
                content && <Col key={content.id} xl={6} md={8} sm={12} xs={24}>
                  <Card
                    className="salvation-card"
                  >
                    <h2>{content.language && content.language.name ? content.language.name : ''}</h2>
                    <div className="card-content-container">{content.title ? content.title : ''}</div>
                    <div className="action-icons mt-1">
                      <Tooltip title="Edit">
                        <Button
                          className="mb-0 mr-0"
                          type="link"
                          onClick={() => {
                            setIsEdit(true);
                            setAddaNewLanguage(false)
                            setDayContent(content);
                            setIsContentActive(true);
                          }}
                        >
                          <EditOutlined />
                        </Button>
                      </Tooltip>
                      <Tooltip title="View">
                        <Button
                          type="link"
                          className="mb-0 mr-0"
                          onClick={() => {
                            setIsEdit(false);
                            setAddaNewLanguage(false)
                            setDayContent(content);
                            setIsContentActive(true);
                          }}
                        >
                          <EyeOutlined />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <Button
                          type="link"
                          danger
                          className="mb-0 mr-0"
                          onClick={() => {
                            onDeleteDayContent(content)
                          }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Tooltip>
                    </div>
                  </Card>
                </Col>
              ))}
            <Col xl={6} md={8} sm={12} xs={24}>
              <Card
                className="salvation-card"
                onClick={() => {
                  setIsEdit(true);
                  setIsContentActive(true);
                  setDayContent();
                  setAddaNewLanguage(true)
                }}
              >
                + Add language and content
              </Card>
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};
export default TwentyOneDayChallengeAdd;
