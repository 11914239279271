import gql from "graphql-tag";

export const CREATE_LANGUAGE = gql`
  mutation createLanguage($data: LanguageCreateInput!) {
    createLanguage(data: $data) {
      message
      status
      data {
        id
        name
        share_title
        share_description
        availability
        is_active
        created_at
        owner_name
        repo_name
        updated_at
      }
    }
  }
`;

export const UPDATE_LANGUAGE = gql`
  mutation updateLanguage($data: LanguageCreateInput!, $where: LanguageWhereUniqueInput!) {
    updateLanguage( data: $data, where: $where ) {
      message
      status
      data {
        id
        name
        share_title
        share_description
        availability
        is_active
        created_at
        updated_at
      }
    }
  }
`;

export const DELETE_LANGUAGE = gql`
  mutation deleteLanguage($id: ID) {
    deleteLanguage(where: { id: $id }) {
      message
      status
    }
  }
`;

export const GENERATE_SIGNED_URL = gql`
  query generateSignedUrl($data: SignedUrlDataInput){
    getSignedPutUrl(data: $data){
      fileName
      signedUrl
      getUrl
    }
  }
`;

export const ADD_CHAPTER_VIDEOS = gql`
  mutation addVideo($where: ChapterWhereInput, $data: [AddVideoInput]!){
    addVideo(where: $where, data: $data){
      chapter_title
      chapter_id
      videos{
        video_url
        id
        thumbnail_url
        description
        type
      }
    }
  }
`;

export const UPDATE_CHAPTER_VIDEOS = gql`
  mutation updateVideos($where: ChapterWhereInput, $data: [UpdateVideoInput]!){
    updateVideos(where: $where, data: $data){
      chapter_title
      chapter_id
      videos{
        video_url
        id
        thumbnail_url
        description
        type
      }
    }
  } 
`;
