import { useEffect, useContext } from "react"; 
import { AppContext } from "../../AppContext"; 

const Logout = () => { 
    const { dispatch } = useContext(AppContext); 
    
    useEffect(() => { 
        dispatch({ type: "LOGOUT" }); 
        window.location = "/login"; 
    }, []); 

    return null; 
} 

export default Logout;