import React from "react";
import moment from "moment";
import { defaultDateFormat, REGEX } from "./constants";
import { GET_SIGNED_PUT_URL } from "../modules/organization/graphql/Mutations";
import client from "../apollo";
import { message } from "antd";
import { get } from "lodash";

export const formatDate = (
  datetime,
  format = `${defaultDateFormat} hh:mm A`,
) => {
  if (datetime && moment && format) {
    return moment(datetime).format(format);
  }

  return datetime;
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const formValidatorRules = {
  required: {
    // required: true,
    // message: "Required",
    validator(rule, value) {      
      if (!value) {        
        return Promise.reject("Required");
      } else if (value && typeof (value) === 'string' && value.trim().length === 0) {
        return Promise.reject("Required");
      } else if (value && typeof (value) === 'object' && Array.isArray(value) && value.length === 0) {
        return Promise.reject("Required");
      }
      return Promise.resolve();
    },
  },
  imgRequired: {
    required: true,
    message: "Required",
  },
  email: {
    type: "email",
    message: "Input is not a valid e-mail",
  },
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.resolve();
      }
      if (!Number(value) || !REGEX.NUMBER.test(Number(value))) {
        return Promise.reject("Should be a valid Number");
      }
      return Promise.resolve();
    },
  }),
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj.setUTCHours(timeObj.getUTCHours());
  formattedDateTime = new Date(formattedDateTime).setUTCMinutes(
    timeObj.getUTCMinutes(),
  );
  formattedDateTime = new Date(formattedDateTime).toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice).toLocaleString("en", {
    style: "currency",
    currency: "USD",
  });
};

export const formItemProps = { normalize: (value) => value.trim() };

export const createMarkup = (htmlstring) => ({ __html: htmlstring });

export const getHtmlElement = (htmlstring) => {
  if (!htmlstring) {
    return "";
  }

  return <div dangerouslySetInnerHTML={createMarkup(htmlstring)}></div>;
};

export const uploadFile = async (signedUrl, sourceFile) => {
  const srcFile = sourceFile;
  try {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.status);
          } else {
            resolve(xhr.response);
          }
        }
      };
      xhr.open("PUT", signedUrl);
      xhr.setRequestHeader("Content-Type", srcFile.type);
      xhr.send(srcFile);
    });
  } catch (err) {
    throw new Error(err);
  }
};
export const getUniqueFileName = (fileName, uniqueNo) => {
  if (!fileName) {
    return "";
  }

  const dotPosition = fileName.lastIndexOf(".");
  const firstPartOfName = fileName.slice(0, dotPosition);
  const extension = fileName.slice(dotPosition);
  const timestamp = new Date().getTime();
  const number = typeof uniqueNo === "number" ? uniqueNo : "";

  return `${firstPartOfName || ""}_${timestamp}${number}${extension || ""}`;
};

export const uploadAndGetUrl = async (fileObj, moduleName, id) => {
  const imageName = fileObj.name;
  const contentType = fileObj.type;
  const extension = imageName.slice(imageName.lastIndexOf(".") + 1);
  const fileName = `${id}-${moduleName}-${new Date().getTime()}.${extension}`;
  const imageResponse = await client.query({
    query: GET_SIGNED_PUT_URL,
    variables: { fileName: fileName.replace(/ /g,"_"), contentType },
  });
  const signedUrl = get(imageResponse, "data.getSignedPutUrl.signedUrl");
  if (signedUrl) {
    const status = await uploadFile(signedUrl, fileObj);
    if (status === 200) {
      return get(imageResponse, "data.getSignedPutUrl.getUrl");
    } else {
      message.error("Files are not uploaded on signed url");
      return get(imageResponse, "data.getSignedPutUrl.getUrl");
    }
  }
};

export const getQueryParams = (url) => {
  let queryParams = {};
  //create an anchor tag to use the property called search
  let anchor = document.createElement("a");
  //assigning url to href of anchor tag
  anchor.href = url;
  //search property returns the query string of url
  let queryStrings = anchor.search.substring(1);
  let params = queryStrings.split("&");

  for (var i = 0; i < params.length; i++) {
    var pair = params[i].split("=");
    queryParams[pair[0]] = decodeURIComponent(pair[1]);
  }
  return queryParams;
};
