import React, { useState, useRef, useEffect, Fragment } from "react";
import {
  Card,
  Tooltip,
  Button,
  Divider,
  Tabs,
  Upload,
  Form,
  Input,
  Spin,
  message,
  Select,
  Switch,
  Row,
  Col
} from "antd";
import {
  ArrowLeftOutlined,
  CameraOutlined,
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from "@ant-design/icons";
import { get, cloneDeep, find, forEach, map, isEmpty, isString, omit, nth } from "lodash";
import client from "../../apollo";
import { getBase64, formValidatorRules, uploadFile } from "../../common/utils";
import { ROUTES, SALVATION_TYPES } from "../../common/constants";
import TextEditor from "../../components/TextEditor";
import { ColorPicker } from "../../components/ColorPicker";
import AddBlockDialog from "./components/AddBlockDialog";
import Covers from "./pages/Covers";
import { GET_ORGANIZATION } from "./graphql/Queries";
import { GET_LANGUAGES } from "../languages/graphql/Queries";
import {
  CREATE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  GET_SIGNED_PUT_URL,
} from "./graphql/Mutations";
import uploadThumbnail from "../../assets/images/upload-picture.png";

const { required } = formValidatorRules;
const { TabPane } = Tabs;
const { Option } = Select;

const OrganizationAdd = (props) => {
  const [form] = Form.useForm()
  const [logoUrl, setLogoUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("DETAILS");
  const [editValue, setEditValue] = useState();
  const [blocks, setBlocks] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [infoTextsLength, setInfoTextsLength] = useState(0);
  const [organization, setOrganization] = useState("");
  const [queryLoading, setQueryLoading] = useState(false);
  const [organizationName, setOrganizationName] = useState("");
  const [openBlock, setOpenBlock] = useState(false);
  const formRef = useRef();
  const [coverImageUrl, setCoverImageUrl] = useState();
  const [isVideoPlayerOpen, setIsVideoPlayerOpen] = useState(true);
  const [isCustomUiSelected, setIsCustomUiSelected] = useState(false)
  const [welcomeVideoUrl, setWelcomeVideoUrl] = useState()
  const [qrCodeUrl, setQrCodeUrl] = useState()
  const [salvationVideoUrl, setSalvationVideoUrl] = useState()
  const [challengeVideoUrl1, setChallengeVideoUrl1] = useState()
  const [challengeVideoUrl2, setChallengeVideoUrl2] = useState()
  const [welcomeVideoValidation, setWelcomeVideoValidation] = useState(false)
  const [salvationLinkValidation, setSalvationLinkValidation] = useState(false)
  const [challengeValidation, setChallengeValidation] = useState(false)
  let organizationId = get(props, "match.params.id");

  useEffect(() => {
    getLanguagesData()
  }, []);

  useEffect(() => {
    if (organizationId) {
      getOrganizationData(organizationId)
    }
  }, [organizationId]);

  useEffect(() => {
    if (!queryLoading && organization) {
      const cloneOrganization = { ...organization };
      setOrganizationName(cloneOrganization?.name);
      setLogoUrl(cloneOrganization?.logo);
      setCoverImageUrl(cloneOrganization?.cover_image);
      setIsVideoPlayerOpen(cloneOrganization?.is_default_video_player);
      cloneOrganization.logo = [
        {
          uid: "-1",
          status: "done",
          name: cloneOrganization.logo,
          url: cloneOrganization.logo,
        },
      ];
      cloneOrganization.cover_image = [
        {
          uid: "-1",
          status: "done",
          name: cloneOrganization.cover_image,
          url: cloneOrganization.cover_image,
        },
      ];
      if (organization?.intro_texts?.length) {
        forEach(organization?.intro_texts, (intro) => {
          cloneOrganization[`language_${intro?.language_id}`] = intro?.info_text
          cloneOrganization[`video_url_${intro?.language_id}`] = intro?.video_url
        })
      }
      formRef.current.setFieldsValue(cloneOrganization);
    }
  }, [queryLoading, organization]);

  const onTabSwiched = (e) => {
    setCurrentTab(e);
  };

  const getLanguagesData = () => {
    setQueryLoading(true);
    client
      .query({
        query: GET_LANGUAGES,
        fetchPolicy: "network-only",
      })
      .then(({ data: { languages: { data } } }) => {
        setLanguages(data);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setQueryLoading(false);
      });
  }

  const getOrganizationData = (organizationId) => {
    setQueryLoading(true);
    client
      .query({
        query: GET_ORGANIZATION,
        fetchPolicy: "network-only",
        variables: {
          id: parseInt(organizationId),
        },
      })
      .then(({ data: { organization } }) => {
        if (organization.intro_texts === null) {
          organization.intro_texts = []
        }
        setWelcomeVideoUrl(organization?.welcome_videos?.[0]?.thumbnail_url)
        setChallengeVideoUrl1(organization?.day_challenge_links?.[0]?.thumbnail_url)
        setChallengeVideoUrl2(get(nth(organization?.day_challenge_links, 1), 'thumbnail_url'))
        form.setFieldsValue({
          is_custom: `${organization?.is_custom}`,
          welcome_video_url: organization?.welcome_videos?.[0]?.video_url,
          welcome_video_thumbnail_url: organization?.welcome_videos?.[0]?.thumbnail_url,
          welcome_video_description: organization?.welcome_videos?.[0]?.description,
          challenge_description1: organization?.day_challenge_links?.[0]?.description,
          challenge_description2: get(nth(organization?.day_challenge_links, 1), 'description'),
          day_challenge_url1: organization?.day_challenge_links?.[0]?.day_challenge_url,
          day_challenge_url2: get(nth(organization?.day_challenge_links, 1), 'day_challenge_url'),
          challenge_thumbnail_url1: organization?.day_challenge_links?.[0]?.thumbnail_url,
          challenge_thumbnail_url2: get(nth(organization?.day_challenge_links, 1), 'thumbnail_url')
        })
        map(organization?.salvation_links, (item) => {
          if (item?.type === SALVATION_TYPES.QR_CODE) {
            setQrCodeUrl(item?.thumbnail_url)
            form.setFieldsValue({ qrCode_thumbnail_url: item?.thumbnail_url })
          } else {
            setSalvationVideoUrl(item?.thumbnail_url)
            form.setFieldsValue({
              salvation_video_url: item?.salvation_url,
              salvation_description: item?.description,
              salvation_thumbnail_url: item?.thumbnail_url
            })
          }
        })
        setIsCustomUiSelected(organization?.is_custom)
        setOrganization(organization);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setQueryLoading(false);
      });
  }

  const uploadAndGetUrl = async (fileObj, moduleName, id) => {
    const imageName = fileObj.name;
    const contentType = fileObj.type;
    const extension = imageName.slice(imageName.lastIndexOf(".") + 1);
    let fileName
    if (id !== undefined) {
      fileName = `${id}-${moduleName}-${new Date().getTime()}.${extension}`;
    }
    else {
      fileName = `${moduleName}-${new Date().getTime()}.${extension}`;
    }
    const imageResponse = await client.query({
      query: GET_SIGNED_PUT_URL,
      variables: { fileName, contentType },
    });
    const signedUrl = get(imageResponse, "data.getSignedPutUrl.signedUrl");
    if (signedUrl) {
      const status = await uploadFile(signedUrl, fileObj);
      if (status === 200) {
        return get(imageResponse, "data.getSignedPutUrl.getUrl");
      } else {
        message.error("Files are not uploaded on signed url");
        return get(imageResponse, "data.getSignedPutUrl.getUrl");
      }
    }
  };

  const handleMutationCall = async (values) => {
    setLoading(true);
    let cloneValues = cloneDeep(values)
    const keys = Object.keys(values)
    let intro_texts = []
    forEach(keys, (key) => {
      if (key?.indexOf('language_') === 0 || key?.indexOf('video_url_') === 0) {
        if (values[key]?.length) {
          const id = key?.split("_")?.pop();
          const existingRecord = find(intro_texts, ["language_id", id]);

          if (!existingRecord?.language_id) {
            intro_texts = [
              ...intro_texts,
              {
                language_id: id,
                info_text: key === `language_${id}` ? values[key] : "",
                video_url: key === `video_url_${id}` ? values[key] : "",
              },
            ];
          } else {
            const recordCopy = {
              ...existingRecord,
              info_text:
                key === `language_${id}` ? values[key] : existingRecord?.info_text,
              video_url:
                key === `video_url_${id}` ? values[key] : existingRecord?.video_url,
            };
            intro_texts = intro_texts?.map((item) => {
              return item?.language_id === id ? recordCopy : item;
            });
          }
        }
        delete cloneValues[key]
      }
    })
    cloneValues.intro_texts = intro_texts
    let data = {
      ...cloneValues,
      is_custom: values?.is_custom || false,
    }

    const welcomeVideos = []
    const salvationLinks = []
    const dayChallengeLinks = []

    if (values?.qrCode_thumbnail_url && typeof values?.qrCode_thumbnail_url === 'object') {
      const qrCodeThumbnail = get(values, "qrCode_thumbnail_url.file.originFileObj")
        ? await uploadAndGetUrl(
          get(values, "qrCode_thumbnail_url.file.originFileObj"),
          "organization-qrCode-thumbnail",
        )
        : values?.qrCode_thumbnail_url;
      salvationLinks.push({
        thumbnail_url: qrCodeThumbnail,
        type: SALVATION_TYPES.QR_CODE
      })
    } else {
      salvationLinks.push({
        thumbnail_url: values?.qrCode_thumbnail_url,
        type: SALVATION_TYPES.QR_CODE
      })
    }

    if (data?.is_custom === true) {
      data = {
        ...data,
        welcome_videos: welcomeVideos,
        salvation_links: salvationLinks,
        day_challenge_links: dayChallengeLinks
      }
    }

    if (values?.welcome_video_thumbnail_url && typeof values?.welcome_video_thumbnail_url === 'object') {
      const thumbnail = get(values, "welcome_video_thumbnail_url.file.originFileObj")
        ? await uploadAndGetUrl(
          get(values, "welcome_video_thumbnail_url.file.originFileObj"),
          "organization-welcome-video-thumbnail",
        )
        : values?.welcome_video_thumbnail_url;
      welcomeVideos.push({
        thumbnail_url: thumbnail,
        description: values?.welcome_video_description || "",
        video_url: values?.welcome_video_url
      })
    } else {
      welcomeVideos.push({
        thumbnail_url: values?.welcome_video_thumbnail_url || "",
        description: values?.welcome_video_description || "",
        video_url: values?.welcome_video_url
      })
    }

    if (values?.salvation_thumbnail_url && typeof values?.salvation_thumbnail_url === 'object') {
      const thumbnail = get(values, "salvation_thumbnail_url.file.originFileObj")
        ? await uploadAndGetUrl(
          get(values, "salvation_thumbnail_url.file.originFileObj"),
          "organization-salvation-thumbnail",
        )
        : values?.salvation_thumbnail_url;
      salvationLinks.push({
        thumbnail_url: thumbnail,
        salvation_url: values?.salvation_video_url,
        description: values?.salvation_description,
        type: SALVATION_TYPES.SALVATION_LINK
      })
    } else {
      salvationLinks.push({
        thumbnail_url: values?.salvation_thumbnail_url || "",
        salvation_url: values?.salvation_video_url,
        description: values?.salvation_description || "",
        type: SALVATION_TYPES.SALVATION_LINK
      })
    }

    if (values?.challenge_thumbnail_url1 && typeof values?.challenge_thumbnail_url1 === 'object') {
      const thumbnail = get(values, "challenge_thumbnail_url1.file.originFileObj")
        ? await uploadAndGetUrl(
          get(values, "challenge_thumbnail_url1.file.originFileObj"),
          "organization-challenge-thumbnail_url1",
        )
        : values?.challenge_thumbnail_url1;
      dayChallengeLinks.push({
        thumbnail_url: thumbnail,
        day_challenge_url: values?.day_challenge_url1,
        description: values?.challenge_description1,
      })
    } else {
      dayChallengeLinks.push({
        thumbnail_url: values?.challenge_thumbnail_url1 || "",
        day_challenge_url: values?.day_challenge_url1,
        description: values?.challenge_description1 || "",
      })
    }

    if (values?.challenge_thumbnail_url2 && typeof values?.challenge_thumbnail_url2 === 'object') {
      const thumbnail = get(values, "challenge_thumbnail_url2.file.originFileObj")
        ? await uploadAndGetUrl(
          get(values, "challenge_thumbnail_url2.file.originFileObj"),
          "organization-challenge-thumbnail_url2",
        )
        : values?.challenge_thumbnail_url2;
      dayChallengeLinks.push({
        thumbnail_url: thumbnail,
        day_challenge_url: values?.day_challenge_url2,
        description: values?.challenge_description2,
      })
    } else {
      dayChallengeLinks.push({
        thumbnail_url: values?.challenge_thumbnail_url2 || "",
        day_challenge_url: values?.day_challenge_url2,
        description: values?.challenge_description2 || "",
      })
    }

    if (cloneValues.logo) {
      data.logo = get(cloneValues, "logo.file.originFileObj")
        ? await uploadAndGetUrl(
          get(cloneValues, "logo.file.originFileObj"),
          "organization-logo",
          organizationId,
        )
        : cloneValues.logo?.[0].url;
    } else {
      data.logo = null
    }

    if (cloneValues?.cover_image?.length === 0) {
      data["cover_image"] = null
    } else if (cloneValues?.cover_image) {
      data["cover_image"] = get(cloneValues, "cover_image.file.originFileObj")
        ? await uploadAndGetUrl(
          get(cloneValues, "cover_image.file.originFileObj"),
          "organization-cover",
          organizationId,
        )
        : cloneValues?.cover_image?.[0]?.url;
    }
  
    const variables = omit(data, ['challenge_description1',
      'challenge_description2',
      'challenge_thumbnail_url1',
      'challenge_thumbnail_url2',
      'day_challenge_url1',
      'day_challenge_url2',
      'qrCode_thumbnail_url',
      'salvation_description',
      'salvation_thumbnail_url',
      'salvation_video_url',
      'welcome_video_description',
      'welcome_video_thumbnail_url',
      'welcome_video_url'])
    if (!organizationId) {
      const { name, intro_texts } = cloneValues;
      const organizationResponse = await client.mutate({
        mutation: CREATE_ORGANIZATION,
        variables: { data: { name, intro_texts, ...variables } },
      });
      organizationId = get(
        organizationResponse,
        "data.createOrganization.data.id",
      );
    } else {
      await client.mutate({
        mutation: UPDATE_ORGANIZATION,
        variables: { data: { ...variables }, where: { id: parseInt(organizationId) } },
      });
    }
    props.history.push(ROUTES?.ORGANIZATIONS);
  }

  const onOrganizationFinish = async (values) => {
    try {
      if (values?.is_custom === true) {
        if (values?.welcome_video_url && values?.salvation_video_url && values?.day_challenge_url1 && values?.day_challenge_url2) {
          setWelcomeVideoValidation(false)
          setSalvationLinkValidation(false)
          setChallengeValidation(false)
          await handleMutationCall(values)
          return

        }
        if (isEmpty(values?.welcome_video_url)) {
          setWelcomeVideoValidation(true)
        } else {
          setWelcomeVideoValidation(false)
        }
        if (isEmpty(values?.salvation_video_url)) {
          setSalvationLinkValidation(true)
        } else {
          setSalvationLinkValidation(false)
        }
        if ((isEmpty(values?.day_challenge_url1) || isEmpty(values?.day_challenge_url2))) {
          setChallengeValidation(true)
        } else {
          setChallengeValidation(false)
        }
      }
      else {
        await handleMutationCall(values)
        return
      }
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  const onAddedCover = () => {
    getOrganizationData(organizationId)
  }

  const onFormChange = (values, allValues) => {
    const infoTextsLength = formRef && formRef.current && formRef.current.getFieldValue('intro_texts') && formRef.current.getFieldValue('intro_texts').length || 0;
    setInfoTextsLength(infoTextsLength);
  }

  const changeDefaultVideoPlayerHandler = (checked) => {
    setIsVideoPlayerOpen(checked);
  }

  if (queryLoading) return <Spin className="spin"/>

  return (
    <Card>
      {/* Dialog start */}
      <AddBlockDialog
        edit={editValue}
        onAddBlock={(blockData) => {
          if (editValue && editValue.cellIndex !== (undefined || null)) {
            blocks[editValue.cellIndex] = blockData;
            setBlocks([...blocks]);
          } else {
            if (!blocks) {
              setBlocks([blockData]);
            } else {
              blocks.push(blockData);
              setBlocks([...blocks]);
            }
          }
          setEditValue();
        }}
        openBlock={openBlock}
        setOpenBlock={setOpenBlock}
      />
      {/* Dialog End */}

      <div className="d-flex flex-row align-items-center justify-content-between">
        <h2 className="d-flex flex-row align-items-center break-word">
          <ArrowLeftOutlined
            className="mr-2"
            onClick={() => {
              props.history.goBack();
            }}
          />
          {organizationId ? (
            queryLoading ? (
              <Spin />
            ) : (
              organizationName
            )
          ) : (
            organizationName
          )}
        </h2>
      </div>
      <Divider />
      <Tabs
        defaultActiveKey="DETAILS"
        size="large"
        onChange={(e) => {
          onTabSwiched(e);
        }}
      >
        <TabPane tab="Details" key="DETAILS">
          <Form
            layout="vertical"
            ref={formRef}
            form={form}
            onFinish={(values) => {
              onOrganizationFinish(values);
            }}
            onFinishFailed={(data) => {
              if (currentTab === "SALVATION_TEXT") {
                message.error("Please fill required fields in Details");
              }
              if (isEmpty(data?.values?.welcome_video_url)) {
                setWelcomeVideoValidation(true)
              } else {
                setWelcomeVideoValidation(false)
              }
              if (isEmpty(data?.values?.salvation_video_url)) {
                setSalvationLinkValidation(true)
              } else {
                setSalvationLinkValidation(false)
              }
              if (isEmpty(data?.values?.day_challenge_url1) || isEmpty(data?.values?.day_challenge_url2)) {
                setChallengeValidation(true)
              } else {
                setChallengeValidation(false)
              }
            }}
            onValuesChange={(values, allValues) => {
              onFormChange(values, allValues)
            }}
            initialValues={{
              ...organization,
              is_custom: false,
            }}
          >
            <div className="d-flex flex-sm-column flex-lg-row">
              <Form.Item className="col-lg-2 logo" name="logo">
                <Upload
                  accept="image/x-png, image/jpeg, image/jpg"
                  customRequest={() => {
                    return false;
                  }}
                  showUploadList={false}
                  listType="picture-card"
                  onChange={(info) => {
                    getBase64(info.file.originFileObj, (imageUrl) => {
                      setLogoUrl(imageUrl);
                    });
                  }}
                >
                  <div className="mt-3">
                    {logoUrl && (
                      <img
                        src={logoUrl}
                        alt="avatar"
                        style={{ width: "100%" }}
                      />
                    )}
                    <CameraOutlined
                      style={{
                        fontSize: "22px",
                        position: "absolute",
                        bottom: "4px",
                        right: "4px",
                      }}
                    />
                  </div>
                  {logoUrl && (
                    <Button
                      type="link"
                      danger
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setLogoUrl(null);
                        formRef.current.setFieldsValue({
                          logo: '',
                        });
                      }}
                      style={{
                        position: "absolute",
                        right: "-12px",
                        top: "-8px",
                      }}
                    >
                      <DeleteOutlined />
                      {/* <CloseOutlined /> */}
                    </Button>
                  )}
                </Upload>
              </Form.Item>
              <div className="col-lg-8">
                <Form.Item rules={[required]} name="name" label="Name">
                  <Input
                    allowClear
                    onChange={(e) => {
                      setOrganizationName(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item name="colors" label="Organization Color">
                  <ColorPicker />
                </Form.Item>
                <Form.Item name="is_default_video_player" label="Default Video Player State">
                  <Switch
                    checked={isVideoPlayerOpen}
                    onChange={(value) => {
                      changeDefaultVideoPlayerHandler(value);
                    }}
                  />
                </Form.Item>
                {
                  languages?.map((language) => {
                    return (
                      <Fragment key={language?.id}>
                        <Form.Item
                          label={`Intro Text (${language?.name})`}
                          name={`language_${language?.id}`}
                        >
                          <TextEditor />
                        </Form.Item>
                        <Form.Item
                          label={`Video URL (${language?.name})`}
                          name={`video_url_${language?.id}`}
                        >
                          <Input allowClear />
                        </Form.Item>
                      </ Fragment>
                    );
                  })
                }
                <Form.Item
                  name="cover_image"
                  className="cover-image"
                  label="Upload Cover Image"
                >
                  <Upload
                    accept="image/x-png, image/jpeg, image/jpg"
                    customRequest={() => {
                      return false;
                    }}
                    showUploadList={false}
                    listType="picture-card"
                    onChange={(info) => {
                      getBase64(info.file.originFileObj, (imageUrl) => {
                        setCoverImageUrl(imageUrl);
                      });
                    }}
                  >
                    <div>
                      {coverImageUrl && (
                        <img
                          src={coverImageUrl}
                          alt="avatar"
                          style={{
                            width: "100%",
                            height: "102px",
                            objectFit: "contain",
                          }}
                        />
                      )}
                    </div>
                    {coverImageUrl && (
                      <Button
                        type="link"
                        danger
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          formRef.current.setFieldsValue({
                            cover_image: [],
                          });
                          setCoverImageUrl(null);
                        }}
                        style={{
                          position: "absolute",
                          right: "0px",
                          top: "0",
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    )}
                  </Upload>
                </Form.Item>
                <Form.Item label='Custom UI' name='is_custom'>
                  <Switch checked={isCustomUiSelected} onChange={(e) => {
                    if (e === true) {
                      setIsCustomUiSelected(true)
                    }
                    else {
                      setIsCustomUiSelected(false)
                    }
                  }} />
                </Form.Item>
                {isCustomUiSelected && (
                  <>
                    <div className={`${welcomeVideoValidation ? "videos-div error" : "videos-div"}`}>
                      <h4 className="label">Welcome Video</h4>
                      <Row className="d-flex flex-horizontal">
                        <Col span={3}>
                          <Form.Item name='welcome_video_thumbnail_url'>
                            <Upload
                              accept="image/x-png, image/jpeg, image/jpg"
                              customRequest={() => {
                                return false;
                              }}
                              className="thumbnail-uploader"
                              showUploadList={false}
                              listType="picture-card"
                              onChange={(info) => {
                                getBase64(info.file.originFileObj, (imageUrl) => {
                                  setWelcomeVideoUrl(imageUrl);
                                });
                              }}
                            >
                              <div>
                                <img
                                  src={welcomeVideoUrl || uploadThumbnail}
                                  alt="avatar"
                                />
                                {welcomeVideoUrl && (
                                  <Button
                                    type="link"
                                    danger
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      formRef.current.setFieldsValue({
                                        welcome_video_thumbnail_url: null,
                                      });
                                      setWelcomeVideoUrl(null);
                                    }}
                                    className="delete-button"
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                )}
                              </div>
                            </Upload>
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item name='welcome_video_url'
                            rules={[
                              ({ getFieldValue }) => ({
                                required: (getFieldValue(
                                  'welcome_video_thumbnail_url'
                                ) || getFieldValue(
                                  'welcome_video_description'
                                )),
                                message: 'Url is required',
                              })
                            ]}
                          >
                            <Input
                              placeholder="Enter Video URL"
                              allowClear
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name='welcome_video_description'>
                            <TextEditor className="language-video-description-editor" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    {welcomeVideoValidation && (
                      <span className="validation-error"> Required </span>
                    )}
                    <Form.Item
                      className="cover-image"
                      label="Upload QR Code Image"
                      name='qrCode_thumbnail_url'
                      rules={[required]}
                    >
                      <Upload
                        accept="image/x-png, image/jpeg, image/jpg"
                        customRequest={() => {
                          return false;
                        }}
                        showUploadList={false}
                        listType="picture-card"
                        onChange={(info) => {
                          getBase64(info.file.originFileObj, (imageUrl) => {
                            setQrCodeUrl(imageUrl);
                          });
                        }}
                      >
                        <div>
                          {qrCodeUrl && (
                            <img
                              src={qrCodeUrl}
                              alt="avatar"
                              style={{
                                width: "100%",
                                height: "102px",
                                objectFit: "contain",
                              }}
                            />
                          )}
                        </div>
                        {qrCodeUrl && (
                          <Button
                            type="link"
                            danger
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              formRef.current.setFieldsValue({
                                qrCode_thumbnail_url: null,
                              });
                              setQrCodeUrl(null);
                            }}
                            style={{
                              position: "absolute",
                              right: "0px",
                              top: "0",
                            }}
                          >
                            <DeleteOutlined />
                          </Button>
                        )}
                      </Upload>
                    </Form.Item>
                    <div className={`${salvationLinkValidation ? "videos-div error" : "videos-div"}`}>
                      <h4 className="label">Salvation Video Link</h4>
                      <Row className="d-flex flex-horizontal">
                        <Col span={3}>
                          <Form.Item name='salvation_thumbnail_url'>
                            <Upload
                              accept="image/x-png, image/jpeg, image/jpg"
                              customRequest={() => {
                                return false;
                              }}
                              className="thumbnail-uploader"
                              showUploadList={false}
                              listType="picture-card"
                              onChange={(info) => {
                                getBase64(info.file.originFileObj, (imageUrl) => {
                                  setSalvationVideoUrl(imageUrl);
                                });
                              }}
                            >
                              <div>
                                <img
                                  src={salvationVideoUrl || uploadThumbnail}
                                  alt="avatar"
                                />
                                {salvationVideoUrl && (
                                  <Button
                                    type="link"
                                    danger
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      formRef.current.setFieldsValue({
                                        salvation_thumbnail_url: null,
                                      });
                                      setSalvationVideoUrl(null);
                                    }}
                                    className="delete-button"
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                )}
                              </div>
                            </Upload>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name='salvation_video_url'
                            rules={[
                              ({ getFieldValue }) => ({
                                required: (getFieldValue(
                                  'salvation_thumbnail_url'
                                ) || getFieldValue(
                                  'salvation_description'
                                )),
                                message: 'Url is required',
                              })
                            ]}>
                            <Input
                              placeholder="Enter URL"
                              allowClear
                            />
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item name='salvation_description'>
                            <Input
                              placeholder="Enter Video Description"
                              allowClear
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    {salvationLinkValidation && (
                      <span className="validation-error"> Required </span>
                    )}
                    <div className={`${challengeValidation ? "videos-div error" : "videos-div"}`}>
                      <h4 className="label">21 Day challenge Videos Link</h4>
                      <Row className="d-flex flex-horizontal">
                        <Col span={3}>
                          <Form.Item name='challenge_thumbnail_url1'>
                            <Upload
                              accept="image/x-png, image/jpeg, image/jpg"
                              customRequest={() => {
                                return false;
                              }}
                              className="thumbnail-uploader"
                              showUploadList={false}
                              listType="picture-card"
                              onChange={(info) => {
                                getBase64(info.file.originFileObj, (imageUrl) => {
                                  setChallengeVideoUrl1(imageUrl);
                                });
                              }}
                            >
                              <div>
                                <img
                                  src={challengeVideoUrl1 || uploadThumbnail}
                                  alt="avatar"
                                />
                                {challengeVideoUrl1 && (
                                  <Button
                                    type="link"
                                    danger
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      formRef.current.setFieldsValue({
                                        challenge_thumbnail_url1: null,
                                      });
                                      setChallengeVideoUrl1(null);
                                    }}
                                    className="delete-button"
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                )}
                              </div>
                            </Upload>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name='day_challenge_url1'>
                            <Input
                              placeholder="Enter URL"
                              allowClear
                            />
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item name='challenge_description1'>
                            <Input
                              placeholder="Enter Video Description"
                              allowClear
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="d-flex flex-horizontal">
                        <Col span={3}>
                          <Form.Item name='challenge_thumbnail_url2'>
                            <Upload
                              accept="image/x-png, image/jpeg, image/jpg"
                              customRequest={() => {
                                return false;
                              }}
                              className="thumbnail-uploader"
                              showUploadList={false}
                              listType="picture-card"
                              onChange={(info) => {
                                getBase64(info.file.originFileObj, (imageUrl) => {
                                  setChallengeVideoUrl2(imageUrl);
                                });
                              }}
                            >
                              <div>
                                <img
                                  src={challengeVideoUrl2 || uploadThumbnail}
                                  alt="avatar"
                                />
                                {challengeVideoUrl2 && (
                                  <Button
                                    type="link"
                                    danger
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      formRef.current.setFieldsValue({
                                        challenge_thumbnail_url2: null,
                                      });
                                      setChallengeVideoUrl2(null);
                                    }}
                                    className="delete-button"
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                )}
                              </div>
                            </Upload>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name='day_challenge_url2'>
                            <Input
                              placeholder="Enter URL"
                              allowClear
                            />
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item name='challenge_description2'>
                            <Input
                              placeholder="Enter Video Description"
                              allowClear
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    {challengeValidation && (
                      <span className="validation-error"> Required </span>
                    )}
                  </>
                )}
                <div className="form-group row">
                  <div className="d-flex col-lg-12 align-items-center justify-content-end border-top">
                    <Tooltip title="Cancel">
                      <Button
                        className="mr-3"
                        onClick={() => {
                          props.history.goBack();
                        }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                    <Tooltip title="Save Details">
                      <Button
                        type="primary"
                        loading={loading}
                        htmlType="submit"
                      >
                        Save
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </TabPane>
        {organizationId && <TabPane tab="Covers" key="COVERS">
          <Covers organizationId={organizationId} covers={organization.covers}
            onAddedCover={() => { onAddedCover() }}
            onDeleteCover={(id) => { getOrganizationData(id || organizationId) }} />
        </TabPane>}
      </Tabs>
    </Card>
  );
};

export default OrganizationAdd;
