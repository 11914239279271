import React, { useRef, useState } from "react";
import { Card, Tooltip, Button, Modal } from "antd";
import CommonTable from "../../components/CommonTable";
import { EditOutlined, ExclamationCircleOutlined, DeleteOutlined, VideoCameraAddOutlined } from "@ant-design/icons";
import { GET_LANGUAGES } from "./graphql/Queries";
import { DELETE_LANGUAGE } from "./graphql/Mutations";
import client from "../../apollo";
import AddLanguageModal from "./AddLanguageModal";
import { LANGUAGES_FEATURES, ROUTES } from "../../common/constants";

const { confirm } = Modal;

const LanguagesList = ({ history }) => {
  const [showModal, setShowModal] = useState(false);
  const [languageData, setLanguageData] = useState();
  const tableRef = useRef();

  const handleAdd = () => {
    setShowModal(true);
  };

  const onEditHandle = (data) => {
    setLanguageData(data);
    setShowModal(true);
  };

  const onDeleteHandle = (id) => {
    confirm({
      closable: true,
      icon: <ExclamationCircleOutlined />,
      title: "Are you Sure?",
      content: "Do you want to remove this Language?",
      okType: "danger",
      onOk: async () => {
        client
          .mutate({
            mutation: DELETE_LANGUAGE,
            variables: { id: parseInt(id) },
            refetchQueries: [
              {
                query: GET_LANGUAGES,
                fetchPolicy: "network-only",
              },
            ],
          })
          .then(() => {
            tableRef.current();
          })
          .catch((e) => e);
      },
    });
  };

  const onEditChapterHandle = (id) => {
    history.push(`${ROUTES.LANGUAGES_CHAPTER_EDIT}/${id}`);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 100,
    },
    {
      title: "Availability",
      dataIndex: "availability",
      render: (values) =>
        values.map((value) => LANGUAGES_FEATURES[value]).join(", "),
      width: 350,
    },
    {
      title: "Share Title",
      dataIndex: "share_title",
      width: 200,
    },
    {
      title: "Share Description",
      dataIndex: "share_description",
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "130px",
      render: (id, record) => {
        return (
          <div className="action-icons">
            <Tooltip title="Video">
              <Button type="link" onClick={() => onEditChapterHandle(id)}>
                <VideoCameraAddOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Edit">
              <Button type="link" onClick={() => onEditHandle(record)}>
                <EditOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button type="link" danger onClick={() => onDeleteHandle(id)}>
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <Card>
      {showModal && (
        <AddLanguageModal
          tableRef={tableRef.current}
          showModal={showModal}
          setShowModal={setShowModal}
          setLanguageData={setLanguageData}
          languageData={languageData}
          isUpdate={languageData ? true : false}
        />
      )}
      <div className="d-flex align-items-center justify-content-between">
        <h1>Languages</h1>
        <Button
          type="primary"
          onClick={() => {
            handleAdd();
          }}
        >
          Add language
        </Button>
      </div>
      <hr />
      <CommonTable
        setTableMethods={({ refreshList }) => {
          tableRef.current = refreshList;
        }}
        query={GET_LANGUAGES}
        columns={columns}
        responseAccessor="data.languages.data"
        totalAccessor="data.languages.total"
      />
    </Card>
  );
};

export default LanguagesList;
