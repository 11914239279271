import React, { useEffect, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { trim } from "lodash";

const TextEditor = (props) => {
  const { value = "", onChange, theme, disabled, defaultValue = "", customStyle = {}, className, toolbar, setEditorValue, ...rest } = props;

  const [values, setValues] = useState(value)


  useEffect(() => {
    if (value) {
      setValues(value)
    }
  }, [value])

  const editorToolbar = toolbar || [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'strike', 'underline'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ['link']
  ];

  const modules = useMemo(
    () => ({
      toolbar: toolbar || [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'strike', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link']
      ]
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <ReactQuill
      className={className}
      style={customStyle}
      theme={theme || "snow"}
      readOnly={disabled || false}
      value={values || ""}
      modules={modules}
      onChange={(content, delta, source, editor) => {
        const newValue = trim(editor.getText()) ? content : "";
        setValues(newValue)
        onChange(newValue);
      }}
      {...rest}
    />
  );
};

export default TextEditor;
