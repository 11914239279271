import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Tooltip,
  Card,
  Divider,
  DatePicker,
} from "antd";
import {
  formValidatorRules,
} from "../../common/utils";
import moment from "moment";

import client from "../../apollo";
import {
  SEND_NOTIFICATION,
} from "./graphql/Mutations";
import { isEmpty } from "lodash";

const { TextArea } = Input;
const { required } = formValidatorRules;

const SendNotificationForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  // trim form values
  const trimFormValues = (val, key) => {
    const newValue = val.target.value.trim();
    form.setFieldsValue({ [key]: newValue });
  };

  const onSendNotification = async (values) => {
    setLoading(true);

    let variables = {
      title: values.title,
      description: values.description,
      notification_type: "MANUAL_NOTIFICATION"
    }
    if (values.scheduled_time) {
      let date = moment.utc(values.scheduled_time).format('YYYY-MM-DD HH:mm:ss')
      let time = moment(values.scheduled_time).format('HH:mm')
      variables.scheduled_date = date
      variables.scheduled_time = time
    }
    setLoading(true);
    client
      .mutate({
        mutation: SEND_NOTIFICATION,
        variables: variables,
      })
      .then(() => { form.resetFields(); })
      .catch((e) => e)
      .finally(() => {
        setLoading(false);
      });

  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current < moment().startOf('day')
  }

  return (
    <Card>
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          onSendNotification(values);
        }}
      >
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-row align-items-center justify-content-start">
            <div className="d-flex flex-column ">
              <h2>Notification</h2>
            </div>
          </div>
        </div>
        <Divider />
        <div className="">
          <Form.Item name="title"
            rules={[{
              validator(rule, value, callback) {
                let newValue = value && value.trim()
                if (newValue) {
                  if (isEmpty(newValue) || newValue == "") {
                    callback("Required");
                  }
                  if (newValue.length > 50) {
                    callback("Max length is 50 characters.");
                  }
                } else {
                  callback("Required");
                }
                callback();
              }
            }]}
            label="Title">
            <Input allowClear onBlur={(val) => trimFormValues(val, 'title')} />
          </Form.Item>
          <Form.Item name="description"
            rules={[{
              validator(rule, value, callback) {

                let newValue = value && value.trim()
                if (newValue) {
                  if (isEmpty(newValue) || newValue == "") {
                    callback("Required");
                  }
                  if (newValue.length > 150) {
                    callback("Max length is 150 characters.");
                  }
                } else {
                  callback("Required");
                }
                callback();
              }
            }]}


            label="Description">
            <TextArea allowClear rows={8} className="pr-4 pt-3" onBlur={(val) => trimFormValues(val, 'description')} />

          </Form.Item>
          <Form.Item name="scheduled_time"
            rules={[{
              validator(rule, value, callback) {
                if (value) {
                  let today = moment().format('YYYY-MM-DD')
                  let current = moment(value).format('YYYY-MM-DD')
                  if (moment(today).isSame(moment(current), 'day')) {
                    let currentTime = moment(value)
                    let time = currentTime.isAfter(moment())
                    if (!time) {
                      callback("Please select Future time.");
                    }
                  }
                }
                callback();
              }
            }]}

            label="Schedule Time">
            <DatePicker allowClear={true}
              format="YYYY-MM-DD hh:mm a"
              disabledDate={disabledDate}
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              className="notification-date-picker"
              placeholder="Select Schedule Time" />
              <label>Note: Pressing the 'send notification' without inputting a scheduled time will send the notification immediately.</label>
          </Form.Item>
          <Tooltip title="Send Notification">
            <Button type="primary" loading={loading} htmlType="submit">
              Send Notification
            </Button>
          </Tooltip>
        </div>
      </Form>
    </Card >
  );
};

export default SendNotificationForm;
