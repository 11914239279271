import React, { Fragment, useState, useRef } from "react";
import { Modal, Form, Select, Input, Tooltip, Button, Divider, Upload, message } from "antd";
import { get } from "lodash";
import { useQuery } from "react-apollo";
import { DeleteOutlined, CameraOutlined } from "@ant-design/icons";
import { getBase64, formValidatorRules, uploadFile } from "../../../common/utils";
import { GET_SIGNED_PUT_URL, CREATE_COVER } from "../graphql/Mutations";
import client from "../../../apollo";
import { GET_ORGANIZATION } from "../graphql/Queries"

const { required, imgRequired } = formValidatorRules;

const CoverFormModal = (props) => {
  const [logoUrl, setLogoUrl] = useState();
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const handleAdd = () => {
    formRef.current.submit();
  }

  const handleCancel = () => {
    props.setShowCoverModal(false)
  }

  const uploadAndGetUrl = async (fileObj, moduleName, id) => {
    const imageName = fileObj.name;
    const contentType = fileObj.type;
    const extension = imageName.slice(imageName.lastIndexOf(".") + 1);
    const fileName = `${id}-${moduleName}-${new Date().getTime()}.${extension}`;
    const imageResponse = await client.query({
      query: GET_SIGNED_PUT_URL,
      variables: { fileName, contentType },
    });
    const signedUrl = get(imageResponse, "data.getSignedPutUrl.signedUrl");
    if (signedUrl) {
      const status = await uploadFile(signedUrl, fileObj);
      if (status === 200) {
        return get(imageResponse, "data.getSignedPutUrl.getUrl");
      } else {
        message.error("Files are not uploaded on signed url");
        return get(imageResponse, "data.getSignedPutUrl.getUrl");
      }
    }
  };

  const onCoverAddFinish = async (values) => {
    setLoading(true);
    try {
      if (props.organizationId) {
        values.image = get(values, "image.file.originFileObj")
          ? await uploadAndGetUrl(
            get(values, "image.file.originFileObj"),
            "cover-image",
            props.organizationId,
          )
          : values.image[0].url;

        await client.mutate({
          mutation: CREATE_COVER,
          variables: { ...values, organization_id: parseInt(props.organizationId) },
          refetchQueries: [
            {
              query: GET_ORGANIZATION,
              variables: { id: parseInt(props.organizationId) },
              fetchPolicy: "network-only",
            },
          ],
        });
        
        if (props.onAddedCover) {
          props.onAddedCover()
        }
        props.setShowCoverModal(false)
      }
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={'Add Cover'}
      className="cover-modal"
      visible={props.showCoverModal}
      onOk={handleAdd}
      okText={'Add Cover'}
      confirmLoading={loading}
      onCancel={handleCancel}
    >
      <Form
        layout="vertical"
        ref={formRef}
        onFinish={(values) => {
          onCoverAddFinish(values);
        }}
        onFinishFailed={() => {
          message.error("Please fill required fields");
        }}
      >
        <div className="d-flex flex-sm-column flex-lg-row align-items-center justify-content-center">
          <Form.Item className="col-lg-2 logo" rules={[imgRequired]} name="image">
            <Upload
              accept="image/x-png, image/jpeg, image/jpg"
              customRequest={() => {
                return false;
              }}
              showUploadList={false}
              listType="picture-card"
              onChange={(info) => {
                getBase64(info.file.originFileObj, (imageUrl) => {
                  setLogoUrl(imageUrl);
                });
              }}
            >
              <div className="mt-3">
                {logoUrl && (
                  <img src={logoUrl} alt="avatar" style={{ width: "100%" }} />
                )}
                <CameraOutlined
                  style={{
                    fontSize: "22px",
                    position: "absolute",
                    bottom: "4px",
                    right: "4px",
                  }}
                />
              </div>
              {logoUrl && (
                <Button type="link" danger
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setLogoUrl(null);
                  }}
                  style={{
                    position: "absolute",
                    right: "-12px",
                    top: "-8px",
                  }}
                >
                  <DeleteOutlined />
                </Button>
              )}
            </Upload>
          </Form.Item>
          <div className="col-lg-8 flex-sm-column">
            <Form.Item rules={[required]} name="name" label="Name">
              <Input allowClear />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  )
};

export default CoverFormModal;