import gql from "graphql-tag";

export const SEND_NOTIFICATION = gql`
  mutation sendNotification($title:String, $description:String, $scheduled_date:String, $scheduled_time:String, $notification_type:NotificationType) {
    sendNotification(data: {
      title: $title
      description: $description
      scheduled_date: $scheduled_date
      scheduled_time: $scheduled_time
      notification_type: $notification_type
    }) {
      message
      status
    }
  }
`;