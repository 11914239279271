import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "../../common/constants";
import TwentyOneDayChallengeAdd from "./TwentyOneDayChallengeAdd";
import TwentyOneDayChallengeList from "./TwentyOneDayChallengeList";
import TwentyOneDayChallengeContent from "./TwentyOneDayChallengeContent";
const TwentyOneDaysChallenge = () => {
  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path={`${ROUTES.TWENTY_ONE_DAY_CHALLENGE_CREATE}`}
          component={TwentyOneDayChallengeContent}
        />
        <Route
          exact
          path={`${ROUTES.TWENTY_ONE_DAY_CHALLENGE_EDIT}/:id`}
          component={TwentyOneDayChallengeAdd}
        />
        <Route
          exact
          path={`${ROUTES.TWENTY_ONE_DAY_CHALLENGE}`}
          component={TwentyOneDayChallengeList}
        />
      </Switch>
    </Fragment>
  );
};

export default TwentyOneDaysChallenge;
