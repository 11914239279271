import React from "react";
import { Avatar, Button, Tooltip } from "antd";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import { getHtmlElement } from "../../../common/utils";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

export const DragHandle = SortableHandle(() => (
  <span className="gx-draggable-icon gx-pt-2" style={{ padding: "0px 8px" }}>
    <i className="icon icon-expand" style={{ fontSize: 25 }} />
  </span>
));

class BlockCell extends React.Component {
  render() {
    const {
      block,
      editBlock,
      cellIndex,
      deleteBlock,
      hideButtons = false,
      isView,
    } = this.props;
    const { image, text, imageUrl } = block || {};

    return (
      <div
        style={{ borderBottom: "solid 1px #e8e8e8" }}
        className="gx-dragndrop-item d-flex flex-column align-items-normal"
      >
        {!hideButtons && !isView && (
          <div className="d-flex justify-content-end align-items-center">
            <Tooltip title="Drag">
              <DragHandle />
            </Tooltip>
            <Tooltip title="Edit">
              <Button
                type="link"
                className="m-0 p-2 d-flex align-items-center"
                onClick={() => editBlock(block, cellIndex)}
              >
                <EditOutlined style={{ fontSize: "22px" }} />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                type="link"
                className="m-0 p-2 d-flex align-items-center"
                danger
                onClick={() => deleteBlock(block, cellIndex)}
              >
                <DeleteOutlined style={{ fontSize: "22px" }} />
              </Button>
            </Tooltip>
          </div>
        )}
        <div>
          {image && imageUrl && (
            <img
              src={imageUrl}
              alt="avatar"
              style={{
                width: "100%",
                height: "102px",
                objectFit: "contain",
              }}
            />
          )}
          {typeof image === "string" && (
            <img
              src={image}
              alt="avatar"
              style={{
                width: "100%",
                height: "102px",
                objectFit: "contain",
              }}
            />
          )}

          {text && (
            <div style={{ background: "#f5f5f5", padding: "20px" }} className="break-word">
              {getHtmlElement(text)}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SortableElement(BlockCell);
