import gql from "graphql-tag";

export const GET_SALVATION_TEXT = gql`
  query salvationTexts(
    $skip: Int
    $first: Int
    $orderBy: SalvationTextOrderByInput
  ) {
    salvationTexts(skip: $skip, first: $first, orderBy: $orderBy) {
      total
      data {
        id
        version
        created_at
        updated_at
        salvation_contents {
          id
          title
          description
          salvation_text_id
          gospel_completion_text
          language_id
          language {
            id
            name
          }
          blocks
          created_at
          updated_at
        },
        is_active,
        organization {
          name
          id
        }
        organization_id
      }
    }
  }
`;
export const GET_SALVATION = gql`
  query salvationText($id: ID!) {
    salvationText(where: { id: $id }) {
      id
      version
      salvation_contents {
        id
        title
        description
        salvation_text_id
        gospel_completion_text
        language_id
        language {
          id
          name
        }
        blocks
        created_at
        updated_at
      }
      is_active,
      organization {
        name
        id
      }
      organization_id
      created_at
      updated_at
    }
  }
`;
export const GET_LANGUAGES = gql`
  query languages {
    languages {
      id
      name
    }
  }
`;
