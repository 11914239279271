import React, { useState } from "react";
import { Form, Button, Input, Spin, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { formValidatorRules } from "../../common/utils";
import { ROUTES } from "../../common/constants";
import client from "../../apollo";
import {
  RESET_PASSWORD,
  TOKEN_VALIDATION,
  EMAIL_VALIDATION,
} from "./graphql/Mutations";
import { get } from "lodash";
const { required } = formValidatorRules;

const ChangePassword = (props) => {
  if (props.location.pathname === ROUTES.VERIFY) {
    const _query = new URLSearchParams(props.history.location.search);
    const resetToken = _query.get("token");
    client
      .query({
        query: EMAIL_VALIDATION,
        fetchPolicy: "network-only",
        variables: {
          resetToken: resetToken,
        },
      })
      .catch((e) => e);
  }
  const [loading, setLoading] = useState(false);
  const onFinish = ({ password }) => {
    setLoading(true);
    const _query = new URLSearchParams(props.history.location.search);
    const resetToken = _query.get("token");
    client
      .mutate({
        mutation: TOKEN_VALIDATION,
        variables: { resetToken },
      })
      .then(() => {
        return client.mutate({
          mutation: RESET_PASSWORD,
          variables: { password },
          context: {
            headers: {
              "x-token": resetToken,
            },
          },
        });
      })
      .then((data) => {
        const responseMessage = get(data, "data.updateUserPassword.message");
        message.success(responseMessage);
        props.history.replace("/login");
      })
      .catch((e) => {
        if (get(e, "response.data.message")) {
          message.error(e.response.data.message);
        } else if (e && e.message) {
          message.error(e.message);
        } else {
          message.error("Something went wrong");
        }
        return e;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-mb-4">
          <h2>Change Password</h2>
          <p>Enter a new password for your account</p>
        </div>
        <Spin spinning={false}>
          <Form
            name="change-password"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            className="gx-login-form gx-form-row0"
          >
            <Form.Item name="password" rules={[required]}>
              <Input.Password
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item
              name="retype-password"
              rules={[
                required,
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || value !== getFieldValue("password")) {
                      return Promise.reject("Passwords do not match");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Retype password"
              />
            </Form.Item>
            <div className="d-flex">
              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  className="mr-2"
                  htmlType="submit"
                >
                  Change Password
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    props.history.push(ROUTES.LOGIN);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default ChangePassword;
