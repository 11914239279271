import React, { useState, useContext, useEffect } from "react";
import Modal from "antd/lib/modal/Modal";
import { Input, Form, Select } from "antd";
import ReactInputMask from "react-input-mask";
import { formValidatorRules } from "../../common/utils";
import client from "../../apollo";
import { CREATE_USER, UPDATE_USER } from "./graphql/Mutations";
import { GET_USERS } from "./graphql/Queries";
import { AppContext } from "../../AppContext";

const { Option } = Select;
const { required, email } = formValidatorRules;

const AddUserModal = (props) => {
  const { isUpdate, showModal, setShowModal, userData, setUserData, organizationOptions } = props;
  const { getCurrentUserRole } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!isUpdate) {
      form.setFieldsValue({
        organization_id: "1"
      })
    }
  }, [isUpdate, form])

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
    setUserData();
  };

  const onUserSubmitFinish = async (values) => {
    setLoading(true);
    const variables = isUpdate ? { ...values, id: userData.id } : { ...values };

    try {
      await client.mutate({
        mutation: isUpdate ? UPDATE_USER : CREATE_USER,
        variables: variables,
        refetchQueries: [
          {
            query: GET_USERS,
            fetchPolicy: "network-only",
          },
        ],
      });
      props.tableRef();
      setShowModal(false);
      setUserData();
      form.resetFields();
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = () => {
    setShowModal(true);
    form.submit();
  };

  return (
    <Modal
      title={isUpdate ? "Edit user" : "Add user"}
      visible={showModal}
      confirmLoading={loading}
      onOk={handleAdd}
      className="dialog"
      okText={isUpdate ? "Save" : "Add"}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        initialValues={userData}
        layout="vertical"
        onFinish={onUserSubmitFinish}
      >
        <Form.Item rules={[required]} name="first_name" label="First Name">
          <Input allowClear />
        </Form.Item>
        <Form.Item rules={[required]} name="last_name" label="Last name">
          <Input allowClear />
        </Form.Item>
        <Form.Item
          label="Contact Number"
          name="phone_no"
          rules={[
            required,
            () => ({
              validator(rule, value) {
                if (value) {
                  value = value.split(" ").join("");
                  const numberPattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
                  if (!numberPattern.test(value)) {
                    return Promise.reject("should be a valid phone number");
                  }
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <ReactInputMask
            mask="(999) 999-9999"
            placeholder="(___) ___-____"
            min={10}
            className="col"
            allowClear
          >
            {(inputProps) => <Input {...inputProps} />}
          </ReactInputMask>
        </Form.Item>
        <Form.Item rules={[required, email]} name="email" label="Email">
          <Input allowClear />
        </Form.Item>
        <Form.Item rules={[required]} name="roles" label="Position">
          <Select className="mr-3" placeholder="Position" allowClear>
            {getCurrentUserRole() === "SUPER_ADMIN" && (
              <Option key={"SUPER_ADMIN"} value={"SUPER_ADMIN"}>
                Super admin
              </Option>
            )}
            <Option key={"ADMIN"} value={"ADMIN"}>
              Admin
            </Option>
            <Option key={"ORGANIZATION_USER"} value={"ORGANIZATION_USER"}>
              Organization User
            </Option>
          </Select>
        </Form.Item>
        {(getCurrentUserRole() === "SUPER_ADMIN" || getCurrentUserRole() === "ADMIN") && (
          <Form.Item name="organization_id" label="Organization">
            <Select className="mr-3" placeholder="Select Organization" allowClear>
              {
                organizationOptions.map((org) => <Option key={org?.id} value={org?.id}>
                  {org?.name}
                </Option>
                )
              }
            </Select>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default AddUserModal;
