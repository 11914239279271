import React, { useState, useRef } from "react";
import { Card, Tooltip, Button, Switch, message, Modal } from "antd";
import CommonTable from "../../components/CommonTable";
import { GET_ORGANIZATIONS, GET_ORGANIZATION } from "./graphql/Queries";
import { formatDate } from "../../common/utils";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { DELETE_ORGANIZATION } from "./graphql/Mutations";
import client from "../../apollo";
import "./organization.less";

const { confirm } = Modal;

const OrganizationList = ({ history }) => {
  const refreshListRef = useRef();
  const handleAdd = () => {
    history.push(`/organizations/edit`);
  };

  const onEditHandle = (id) => {
    history.push(`/organizations/edit/${id}`);
  };

  const onDeleteHandle = (id) => {
    confirm({
      closable: true,
      icon: <ExclamationCircleOutlined />,
      title: "Are you Sure?",
      content: "Do you want to remove this Organization?",
      okType: "danger",
      onOk: async () => {
        client
          .mutate({
            mutation: DELETE_ORGANIZATION,
            variables: { id: parseInt(id) },
            refetchQueries: [
              {
                query: GET_ORGANIZATIONS,
                fetchPolicy: "network-only",
              },
            ],
          })
          .then(() => {
            refreshListRef.current();
          })
          .catch((e) => e);
      },
    });
  };
  const columns = [
    {
      title: "Logo",
      dataIndex: "logo",
      width: "40px",
      render: (image, record) => (
        <img
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
          src={image}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "20%",
      render: (value) => value && <div className="break-word">{value}</div>
    },
    {
      title: "Share URL",
      dataIndex: "shared_link",
      width: "20%",
    },
    {
      title: "Created By",
      dataIndex: "creator",
      width: "20%",
      render: (value) => value && `${value.first_name} ${value.last_name}`,
    },
    {
      title: "Created On",
      dataIndex: "created_at",
      width: "20%",
      render: (date) => formatDate(date, "MM/DD/YYYY"),
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "130px",
      render: (id, record) => {
        return (
          <div className="action-icons">
            <Tooltip title="Edit">
              <Button type="link" onClick={() => onEditHandle(id)}>
                <EditOutlined />
              </Button>
            </Tooltip>
            {record && !record.is_default && <Tooltip title="Delete">
              <Button type="link" danger onClick={() => onDeleteHandle(id)}>
                <DeleteOutlined />
              </Button>
            </Tooltip>}
            <Tooltip title="View">
              <Button
                type="link"
                onClick={() => history.push(`/organizations/view/${id}`)}
              >
                <EyeOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <Card className="organization-content">
      <div className="d-flex align-items-center justify-content-between">
        <h1>Organization Management</h1>
        <Button type="primary" onClick={handleAdd}>
          Add Organization
        </Button>
      </div>
      <hr />
      <CommonTable
        query={GET_ORGANIZATIONS}
        columns={columns}
        setTableMethods={({ refreshList }) => {
          refreshListRef.current = refreshList;
        }}
        responseAccessor="data.organizations.data"
        totalAccessor="data.organizations.total"
      />
    </Card>
  );
};

export default OrganizationList;
