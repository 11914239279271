import React, { useState, useRef, useEffect } from "react";
import Modal from "antd/lib/modal/Modal";
import TextEditor from "../../../components/TextEditor";
import { Upload, Form, Button } from "antd";
import { getBase64 } from "../../../common/utils";
import { DeleteOutlined } from "@ant-design/icons";

const AddBlockDialog = ({ onAddBlock, openBlock, setOpenBlock, edit }) => {
  const [blockModalImageUrl, setBlockModalImageUrl] = useState();
  const [image, setImage] = useState();
  const [blockText, setBlockText] = useState("");
  const formRef = useRef("");

  useEffect(() => {
    if (edit) {
      const { block: { text, image, imageUrl } = {} } = edit;
      if (text) {
        setBlockText(text);
      } else {
        if (!imageUrl) {
          setBlockModalImageUrl(image);
        } else {
          setBlockModalImageUrl(imageUrl);
        }
        setImage(image);
      }
    }
  }, [edit]);
  return (
    <Modal
      destroyOnClose={true}
      title="Add Block"
      visible={openBlock}
      onOk={() => {
        onAddBlock(
          blockText
            ? { text: blockText }
            : image
            ? { image: image, imageUrl: blockModalImageUrl }
            : null,
        );
        formRef.current.resetFields();
        setOpenBlock(false);
        setBlockModalImageUrl();
        setBlockText();
        setImage();
      }}
      onCancel={() => {
        setOpenBlock(false);
        setBlockModalImageUrl();
        setBlockText();
        setImage();
      }}
    >
      <Form ref={formRef}>
        {(!edit || (edit && !blockModalImageUrl)) && (
          <Form.Item name="text">
            <TextEditor
              defaultValue={blockText}
              value={blockText}
              disabled={blockModalImageUrl}
              onChange={(e) => {
                setBlockText(e);
              }}
            />
          </Form.Item>
        )}
        {!edit && (
          <div className="mt-2 mb-2 d-flex align-item-center justify-content-center">
            <h5 className="text-align-center mb-0">OR</h5>
          </div>
        )}
        {(!edit || (edit && !blockText)) && (
          <Form.Item name="image">
            <Upload
              className="cover-image"
              disabled={blockText}
              accept="image/x-png, image/jpeg, image/jpg"
              customRequest={() => {
                return false;
              }}
              showUploadList={false}
              listType="picture-card"
              onChange={(info) => {
                setImage(info);
                getBase64(info.file.originFileObj, (imageUrl) => {
                  setBlockModalImageUrl(imageUrl);
                });
              }}
            >
              <div>
                {blockModalImageUrl && (
                  <img
                    src={blockModalImageUrl}
                    alt="avatar"
                    style={{
                      width: "100%",
                      height: "102px",
                      objectFit: "contain",
                    }}
                  />
                )}
              </div>
            </Upload>
            {blockModalImageUrl && (
              <Button
                type="link"
                danger
                onClick={() => {
                  setBlockModalImageUrl(null);
                }}
                style={{
                  position: "absolute",
                  right: "0px",
                  top: "0",
                }}
              >
                <DeleteOutlined />
              </Button>
            )}
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default AddBlockDialog;
