import React, { useState, useEffect, useRef } from "react";
import {
  Select,
  Form,
  Input,
  Button,
  Tooltip,
  Card,
  Divider,
  Modal,
  Upload,
  Space,
  Table,
  message,
  Spin,
} from "antd";
import { useLazyQuery, useQuery } from "react-apollo";
import { GET_LANGUAGES } from "../organization/graphql/Queries";
import {
  uploadAndGetUrl,
  getBase64,
  formatDate,
  getHtmlElement,
  formValidatorRules,
  uploadFile,
} from "../../common/utils";
import {
  ArrowLeftOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CameraOutlined,
  PlusOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";
import { cloneDeep, every, filter, get, isArray, isEmpty, map, omit,  uniqBy, uniqueId } from "lodash";
import { v4 as uuid } from "uuid";
import client from "../../apollo";
import {
  CREATE_DAY_CHALLENGE,
  UPDATE_DAY_CHALLENGE,
  CREATE_DAY_CONTENT,
  UPDATE_DAY_CONTENT,
  DELETE_DAY_CHALLENGE,
} from "./graphql/Mutations";
import TextEditor from "../../components/TextEditor";
import history from "../../histroy";
import { ROUTES, secondaryDateFormat } from "../../common/constants";
import uploadThumbnail from "../../assets/images/upload-picture.png";
import { GENERATE_SIGNED_URL } from "../languages/graphql/Mutations";


const { Option } = Select;
const { confirm } = Modal;
const { required, imgRequired } = formValidatorRules;

const TwentyOneDayChallengeContent = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(true)
  const [sections, setSections] = useState([]);
  const [checkValidation, setCheckValidation] = useState(false);
  const [video, setVideo] = useState([{
    thumbnail_url: [],
    video_url: "",
    description: "",
    index: 0
  }]);
  const [logoUrl, setLogoUrl] = useState();
  const [toggleData, setToggleData] = useState(false);
  const [tableData, setTableData] = useState([{
    thumbnail_url: [],
    video_url: "",
    description: "",
    index: 0
  }]);
  const [imageAddded, setImageAddded] = useState(false);
  const dayFormRef = useRef("");

const [languages, setLanguages] = useState([]);
const [languageLoading, setLanguageLoading] = useState(true)

  const [getLanguages] = useLazyQuery(
    GET_LANGUAGES,
    {
      fetchPolicy: "network-only",
      onCompleted(res){
        const alreadySelectedLanguages = dayContent?.map((item) => item?.language?.id)
        let uniqueLanguages = [];
         uniqueLanguages = filter(res?.languages?.data,(language)=>{
            if(!alreadySelectedLanguages?.includes(language?.id)) {
              return  uniqueLanguages.push({language})
            }
        })
        if(props?.dayContent){
          const withDefaultLanguage = uniqBy([props?.dayContent?.language,...uniqueLanguages],'id')
          setLanguages([...withDefaultLanguage])
        } else {
          setLanguages([...uniqueLanguages])
        }
        setLanguageLoading(false)
      },
      onError(){
        setLanguageLoading(false)
      }
    },
  );


  useEffect(() => {
    getLanguages()
  }, [])
  
  useEffect(() => {
    if (props?.isEdit) {
      setIsEdit(true);
    }
  }, [props?.isEdit]);

  useEffect(() => {
    if (props?.dayContent && props?.dayContent?.sections) {
      getLanguages()
      setSections(props?.dayContent?.sections);
      setLogoUrl(props?.dayContent?.image);
    }
    if (props?.dayContent && props?.dayContent?.videos) {
      if (isEmpty(props?.dayContent?.videos)) {
        setVideo([{}]);
        setTableData([{}])
      }
      else {
        if (props?.addaNewLanguage) {
          setTableData([{}])
          setVideo([{}]);
        }
        else {
          const videoDataCopy = props?.dayContent.videos?.map((video, itemIndex) => {
            return ({
              thumbnail_url: !isArray(video?.thumbnail_url)
                ? video?.thumbnail_url === '' ? [] : [
                  {
                    uid: uniqueId(),
                    name: video?.video_url,
                    status: "done",
                    url: video?.thumbnail_url,
                  },
                ]
                : ((isArray(video?.thumbnail_url) && !isEmpty(video?.thumbnail_url)) ? video?.thumbnail_url : []),
              video_url: video?.video_url,
              description: video?.description,
              index: itemIndex,
            })
          });
          setTableData(videoDataCopy)
          setVideo(videoDataCopy);
        }
      }
    }
    setDataLoading(false)
  }, [props?.dayContent]);

  const onLanguageChange = (e) => {
    setDataLoading(true)
    const dayContents = get(props, "dayChallenge.day_contents");
    const dayChallengeContents = dayContents?.find((text) => {
      return text?.language_id === e;
    });
    if (dayChallengeContents?.sections) {
      setSections(dayChallengeContents?.sections);
      setLogoUrl(dayChallengeContents?.image);
    }
    if (dayChallengeContents && dayChallengeContents?.videos) {
      if (isEmpty(dayChallengeContents?.videos)) {
        setVideo([{}]);
        setTableData([{}])
      }
      else {
        if (props?.addaNewLanguage) {
          setTableData([{}])
          setVideo([{}]);
        }
        else {
          const videoDataCopy = dayChallengeContents.videos?.map((video, itemIndex) => {
            return ({
              thumbnail_url: !isArray(video?.thumbnail_url)
                ? video?.thumbnail_url === '' ? [] : [
                  {
                    uid: uniqueId(),
                    name: video?.video_url,
                    status: "done",
                    url: video?.thumbnail_url,
                  },
                ]
                : ((isArray(video?.thumbnail_url) && !isEmpty(video?.thumbnail_url)) ? video?.thumbnail_url : []),
              video_url: video?.video_url,
              description: video?.description,
              index: itemIndex,
            })
          });
          setTableData(videoDataCopy)
          setVideo(videoDataCopy);
        }
      }
    }

    props.setDayContent(dayChallengeContents)
    setDataLoading(false)
  };

  const url = props && props?.match && props?.match.url;

  useEffect(() => {
    if (ROUTES.TWENTY_ONE_DAY_CHALLENGE_CREATE === url) {
      setIsEdit(true);
    }
  }, [url]);


  const uploadButton = (
    <div className="add-avatar-container">
      <img src={uploadThumbnail} alt="add-avatar-thumbnail" role="presentation" />
    </div>
  );

  const onSalvationFinish = async (values) => {
    try {
      const tableDataCopy = cloneDeep(tableData);
      let videos = cloneDeep(isEdit ? video : tableData);
      setLoading(true);
      if (tableDataCopy?.length > 0) {
        if (!(isEmpty(tableDataCopy[0]?.description) && isEmpty(tableDataCopy[0]?.video_url) && isEmpty(tableDataCopy[0]?.thumbnail_url))) {
          const isAllVideoUrlAdded = every(tableDataCopy, (item) => Boolean(item?.video_url));
          if (!isAllVideoUrlAdded) {
            setCheckValidation(true);
            message.error("Video URLs should not be empty.");
            setLoading(false);
            return;
          }
        }
        await Promise.all(
          map(tableDataCopy, async (item, index) => {
            if (item?.thumbnail_url?.length > 0) {
              const file = item?.thumbnail_url[0];
              const { name, type, originFileObj } = file;
              if (originFileObj) {
                const variables = {
                  data: { fileName: name.replace(/ /g, "_"), contentType: type },
                };
                await client
                  .mutate({
                    mutation: GENERATE_SIGNED_URL,
                    variables: variables,
                  })
                  .then(async (imageResponse) => {
                    const signedUrl = get(imageResponse, "data.getSignedPutUrl.signedUrl");

                    if (signedUrl) {
                      const status = await uploadFile(signedUrl, file?.originFileObj);
                      if (status === 200) {
                        const url = get(imageResponse, "data.getSignedPutUrl.getUrl");
                        videos = map(videos, (item, childIndex) => {
                          const newObj = omit(item, 'index')
                          return index === childIndex
                            ? videos = {
                              ...newObj,
                              thumbnail_url: url || "",
                            }
                            : videos = { ...newObj };
                        });

                      } else {
                        message.error("Files are not uploaded on signed url");
                      }
                    }
                  })
                  .catch(() => { })
                  .finally(() => { });
              } else {
                videos = map(videos, (item, childIndex) => {
                  const newObj = omit(item, 'index')
                  return index === childIndex
                    ?
                    videos = {
                      ...newObj,
                      thumbnail_url: file?.url || "",
                    }
                    : videos = { ...newObj };
                });
              }
            }
            else {
              videos = map(videos, (item, childIndex) => {
                const newObj = omit(item, 'index')
                if (isEmpty(newObj)) {
                  return (
                    videos = []
                  )
                }
                else {
                  if (isEmpty(item?.description) && isEmpty(item?.video_url) && isEmpty(item?.thumbnail_url)) {
                    return (
                      videos = []
                    )
                  }
                  else {
                    return (
                      videos = {
                        ...newObj,
                        thumbnail_url: ""
                      }
                    )
                  }
                }
              })
            }
          })
        )
      }

      const { day, ...rest } = values;
      values = rest;
      rest.image = rest.image = get(rest, "image.file.originFileObj")
        ? await uploadAndGetUrl(
          get(values, "image.file.originFileObj"),
          "day-challenge-content",
          uuid(),
        )
        : values?.image[0].url;
      values.language_id = parseInt(values?.language_id);
      if (props?.dayId) {
        if (props?.dayChallenge.day !== day) {
          client
            .mutate({
              mutation: UPDATE_DAY_CHALLENGE,
              variables: { day, id: parseInt(props?.dayId) },
            })
            .catch((e) => e);
        }
        const variables = {
          ...values,
          day_challenge_id: parseInt(props?.dayId),
          videos: isEmpty(videos[0]) ? [] : videos
        };
        if (props.dayContent && props?.dayContent?.id) {
          variables.id = props?.dayContent?.id;
        }
        client
          .mutate({
            mutation: props.dayContent ? UPDATE_DAY_CONTENT : CREATE_DAY_CONTENT,
            variables: variables,
          })
          .then(() => {
            props.setIsContentActive(false);
          })
          .catch((e) => {
            setLoading(false);
            return e
          })
          .finally(() => {
            props.updateFound();
            props.setLoading(false);
          });
      } else {
        const variables = { day, dayContents: { ...values, videos: isEmpty(videos[0]) ? [] : videos } };
        setLoading(true);
        client
          .mutate({
            mutation: CREATE_DAY_CHALLENGE,
            variables: variables,
          })
          .then(() => {
            props.history.goBack();
          })
          .catch((e) => {
            setLoading(false);
            return e
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
    catch (error) {
      setLoading(false);
      console.log(error)
    }
  };

  const onEditHandle = () => {
    props.setIsEdit(true);
  };

  const deleteVideoHandler = (e, rowIndex) => {
    e.preventDefault();
    e.stopPropagation();
    setToggleData(false);
    const updatedList = cloneDeep(tableData);
    updatedList.splice(rowIndex, 1);
    setTableData(updatedList);
    setVideo(updatedList)
  };


  const upDownChangeHandler = (e, oldIndex, newIndex) => {
    e.preventDefault();
    e.stopPropagation();
    setToggleData(false);
    if (newIndex < 0) return;
    const items = cloneDeep(isEdit ? video : tableData);
    const item = items.splice(oldIndex, 1)[0];
    items.splice(newIndex, 0, item);
    setTableData(items);
    setVideo(items)
  };

  const onChangeInputHandler = (e, rowIndex, key) => {
    e.preventDefault();
    e.stopPropagation();
    const value = e?.target?.value;
    if (value === '') {
      setTableData([{
        video_url: ""
      }])
      setVideo([{
        video_url: ""
      }])
    }
    else {
      const tableDataCopy = cloneDeep(isEdit ? video : tableData).map((item, index) => {
        return index === rowIndex
          ? {
            ...item,
            [key]: value,
          }
          : { ...item };
      });
      setTableData(tableDataCopy);
      setVideo(tableDataCopy)
    }
  };

  const onDeleteHandle = (id) => {
    confirm({
      closable: true,
      icon: <ExclamationCircleOutlined />,
      title: "Are you Sure?",
      content: "Do you want to remove this Salvation Text?",
      okType: "danger",
      onOk: async () => {
        client
          .mutate({
            mutation: DELETE_DAY_CHALLENGE,
            variables: { id: parseInt(id) },
          })
          .catch((e) => e)
          .finally(() => {
            history.push(ROUTES.TWENTY_ONE_DAY_CHALLENGE);
          });
      },
    });
  };

  const onChangeDescriptionHandler = (value, rowIndex, key) => {
    const tableDataCopy = cloneDeep(isEdit ? video : tableData)?.map((item, index) => {
      return index === rowIndex
        ? {
          ...item,
          [key]: value,
        }
        : { ...item };
    });
    setTableData(tableDataCopy);
    setVideo(tableDataCopy)
  };

  const addVideoHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setToggleData(false);
    setCheckValidation(false);
    const tableDataCopy = cloneDeep(tableData);
    tableDataCopy.push({});
    setTableData(tableDataCopy);
    setVideo(tableDataCopy)
  };



  const columns = [
    {
      title: "",
      dataIndex: "",
      width: 30,
      className: "drag-visible click-for-refresh",
      render: (value, record, index) => (
        <div className="up-down-btn-container">
          <Button
            type="text"
            disabled={index === 0}
            onClick={(e) => upDownChangeHandler(e, index, index - 1)}
          >
            <ArrowUpOutlined />
          </Button>
          <Button
            type="text"
            disabled={index === tableData?.length - 1}
            onClick={(e) => upDownChangeHandler(e, index, index + 1)}
          >
            <ArrowDownOutlined />
          </Button>
        </div>
      ),
    },
    {
      title: "",
      width: 90,
      dataIndex: "thumbnail_url",
      render: (value, record, index) => {
        return (
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            fileList={value}
            onChange={(info) => handleChange(info, index)}
            showUploadList={{
              showPreviewIcon: false,
              showDownloadIcon: false,
              showRemoveIcon: true,
            }}
            maxCount={1}
            beforeUpload={() => {
              return false;
            }}
          >
            {(!value || value?.length === 0) && uploadButton}
          </Upload >
        );
      },
    },
    {
      title: "",
      width: "40%",
      dataIndex: "video_url",
      render: (value, record, index) => {
        return (
          <Input
            placeholder="Enter Video URLs"
            value={value}
            className={checkValidation && value?.length === 0 ? "custom-error-input" : ""}
            onChange={(e) => onChangeInputHandler(e, index, "video_url")}
            allowClear
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "description",
      render: (value, record, index) => {
        return (
          <TextEditor
            className="language-video-description-editor"
            key={record?.id}
            value={value || ''}
            onChange={(text) => {
              onChangeDescriptionHandler(text, index, "description");
            }}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: 50,
      render: (value, record, index) => {

        return (
          <div className="d-flex delete-add-button">
            <DeleteOutlined
              id="video-custom-delete-btn"
              onClick={(e) => deleteVideoHandler(e, index)}
            />
            <Button onClick={(e) => addVideoHandler(e)} className="video-add-button" icon={<PlusOutlined />} />
          </div>
        );


      },
    },
  ];


  const handleChange = (info, key) => {
    const tableDataCopy = cloneDeep(tableData);
    if (info?.fileList?.length > 0) {
      setImageAddded(true)
      const updatedTableData = tableDataCopy?.map((item, index) => {
        return index === key
          ? {
            ...item,
            thumbnail_url: [
              {
                ...info?.fileList[0],
                url: URL?.createObjectURL(info?.file),
              },
            ],
          }
          : { ...item };
      });
      setTableData(updatedTableData);
      setVideo(updatedTableData)
      return;
    } else {
      setImageAddded(false)
      const updatedTableData = tableDataCopy?.map((item, index) => {
        return index === key
          ? {
            ...item,
            thumbnail_url: [],
          }
          : { ...item };
      });
      setTableData(updatedTableData);
      setVideo(updatedTableData)
    }
  };

  const getSrc = (videoObj) => {
    return (isArray(videoObj?.thumbnail_url) && !isEmpty(videoObj?.thumbnail_url)) ? videoObj?.thumbnail_url?.[0]?.url : (videoObj?.thumbnail_url || uploadThumbnail)
  }

  const dayContent = get(props, "dayChallenge.day_contents");

  return (
    <Card>
      {isEdit && (
        <Spin spinning={languageLoading} >
          <Form
            layout="vertical"
            ref={dayFormRef}
            initialValues={{
              sections: [{}, {}, {}],
              videos: [{}],
              ...props?.dayContent,
              day: props?.day,
              image: props?.dayContent && [
                {
                  uid: "-1",
                  status: "done",
                  name: props?.dayContent?.image,
                  url: props?.dayContent?.image,
                },
              ],
            }}
            onFinish={(values) => {
              onSalvationFinish(values);
            }}
          >
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="d-flex flex-row align-items-center justify-content-start">
                <h2>
                  <ArrowLeftOutlined
                    className="mr-2"
                    onClick={() => {
                      if (props?.dayId) {
                        props.setIsContentActive(false);
                      } else {
                        history.goBack();
                      }
                    }}
                  />
                </h2>
                <div className="d-flex flex-column ">
                  <h2>{props.day}</h2>
                  {props?.dayContent && (
                    <div>
                      {`Last updated on ${formatDate(props?.dayContent?.updated_at,secondaryDateFormat)}`}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <div className="d-flex col-lg-12 align-items-center justify-content-end">
                  <Tooltip title="Cancel">
                    <Button
                      className="mr-3"
                      onClick={() => {
                        if (props && props?.isEdit) {
                          history.goBack();
                        } else {
                          props.history.goBack();
                        }
                        setCheckValidation(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Save 21 Day Challenge">
                    <Button type="primary" loading={loading} htmlType="submit">
                      Save
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
            <Divider />
            <div className="d-flex flex-sm-column flex-lg-row">
              <Form.Item
                className="col-lg-2 text-center logo"
                name="image"
                valuePropName="fileList"
                rules={[imgRequired]}
              >
                <Upload
                  accept="image/x-png, image/jpeg, image/jpg"
                  customRequest={() => {
                    return false;
                  }}
                  showUploadList={false}
                  listType="picture-card"
                  onChange={(info) => {
                    getBase64(info?.file?.originFileObj, (imageUrl) => {
                      setLogoUrl(imageUrl);
                    });
                  }}
                >
                  <div className="mt-3">
                    {logoUrl && (
                      <img src={logoUrl} alt="avatar" className="fill-width" />
                    )}
                    <CameraOutlined
                      style={{
                        fontSize: "22px",
                        position: "absolute",
                        bottom: "4px",
                        right: "4px",
                      }}
                    />
                  </div>
                  {logoUrl && (
                    <Button
                      type="link"
                      danger
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setLogoUrl(null);
                        dayFormRef.current.setFieldsValue({
                          image: '',
                        });
                      }}
                      style={{
                        position: "absolute",
                        right: "-12px",
                        top: "-8px",
                      }}
                    >
                      <DeleteOutlined />
                    </Button>
                  )}
                </Upload>
              </Form.Item>
              <div className="col-lg-8">
                <Form.Item name="day" rules={props?.dayId ? [] : [required]} label="Day">
                  <Input allowClear disabled={props?.dayId ? true : false} />
                </Form.Item>
                <Form.Item
                  name="language_id"
                  rules={[required]}
                  label="Select language"
                >
                  <Select
                    className="mr-3"
                    placeholder="Languages"
                    loading={languageLoading}
                    allowClear
                  >
                    {languages.map((language) => (
                        <Option key={language.id} value={language.id}>
                          {language?.name}
                        </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="title" rules={[required]} label="Title">
                  <Input allowClear />
                </Form.Item>
                <Form.Item name="sub_title" label="Sub Title">
                  <Input allowClear />
                </Form.Item>
              </div>
            </div>
            <div className="videos-section">
              <p>VIDEOS</p>
              <Divider />
              {tableData?.length > 0 && (
                <Table
                  pagination={false}
                  showHeader={false}
                  dataSource={isEdit ? video : tableData}
                  columns={columns}
                  rowKey="index"
                />
              )}
            </div>
            <Form.List name="sections">
              {(fields, { add, remove }) => {
                return (
                  <div style={{ padding: "20px", marginLeft: "20px" }}>
                    {fields.map((field, index) => (
                      <div
                        className="form-group row"
                        style={{ position: "relative" }}
                        key={field.key}
                      >
                        <Form.Item
                       className="fill-width"
                          name={[field.name, "title"]}
                          fieldKey={[field.fieldKey, "title"]}
                          label={`SECTION ${index + 1}`}
                        >
                          <Input />
                        </Form.Item>
                        <Divider />
                        <Form.Item
                       className="fill-width"
                          name={[field.name, "description"]}
                          fieldKey={[field.fieldKey, "description"]}
                        >
                          <TextEditor allowClear />
                        </Form.Item>
                      </div>
                    ))}
                  </div>
                );
              }}
            </Form.List>
          </Form>
        </Spin>
      )}
      {!isEdit && (
        <div className="d-flex flex-column">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className="d-flex flex-row align-items-center justify-content-start">
              <h2>
                <ArrowLeftOutlined
                  className="mr-2"
                  onClick={() => {
                    if (!props.dayId) {
                      history.push(ROUTES.SALVATION_TEXT);
                    } else {
                      props.setIsContentActive(false);
                    }
                  }}
                />
              </h2>
              <div className="d-flex flex-column ">
                <h2>{props.day}</h2>
                {props?.dayContent && (
                  <div>
                    {`Last updated on ${formatDate(props?.dayContent?.updated_at,secondaryDateFormat)}`}
                  </div>
                )}
              </div>
            </div>

            <div className="action-icons d-flex justify-content-end">
              <Select
                className="mr-3"
                placeholder="Languages"
                loading={languageLoading}
                defaultValue={
                  props && props?.dayContent && props?.dayContent.language_id
                }
                onChange={onLanguageChange}
              >
                {dayContent &&
                  dayContent.map(({ language }) => (
                    language && language.id && language.name && <Option key={language.id} value={language.id}>
                      {language.name}
                    </Option>
                ))}
              </Select>
              <Button
                type="link"
                className="m-0 pr-0"
                onClick={() => onEditHandle(props.dayId)}
              >
                <EditOutlined style={{ fontSize: "20px" }} />
              </Button>
              <Button
                type="link"
                className="mb-0"
                danger
                onClick={() => onDeleteHandle(props.dayId)}
              >
                <DeleteOutlined style={{ fontSize: "20px" }} />
              </Button>
            </div>
          </div>
          <Divider />
          {dataLoading ? <Spin /> : (
            <div style={{ margin: "0 24px" }}>
              <img
                style={{
                  width: "130px",
                  objectFit: "cover",
                  borderRadius: "10px",
                  height: "130px",
                  border: "1px dashed",
                  padding: "8px",
                }}
                src={logoUrl}
                alt={props.day}
              />
              {
                !isEmpty(video?.[0]) && (
                  <div className="video-view-section">
                    {video.map((video, index) => {
                      return (
                        <div className="video-view-div d-flex" key={video?.id}>
                          <div className="thumbnail-container">
                            <img src={getSrc(video)}
                              alt={`${video?.description}`}
                              role="presentation"
                              onError={(e) => {
                                e.target.src = uploadThumbnail;
                              }} height='100%' width='40px' />
                          </div>
                          <div className="video-view-div-text">
                            <div className="video-url">{video?.video_url}</div>
                            <div className="video-description" dangerouslySetInnerHTML={{ __html: video?.description || '' }} />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              }
              <div style={{ marginTop: "24px" }}>
                {sections.map((section, index) => (
                  <div key={index}>
                    <h1>{section?.title}</h1>
                    {getHtmlElement(section?.description)}
                  </div>
                ))}
              </div>
            </div>)}
        </div>
      )}
    </Card>
  );
};

export default TwentyOneDayChallengeContent;
