import React, { Fragment } from "react";
import DonationList from "./pages/DonationList";

const Donations = () => {
  return (
    <Fragment>
      <DonationList/>
    </Fragment>
  );
};

export default Donations;
