/* Routes  */
export const ROUTES = {
  DASHBOARD: "/dashboard",
  GOSPEl_TEXT: "/gospel-text",
  SALVATION_TEXT: "/salvation-text",
  SALVATION_TEXT_CREATE: "/salvation-text/create",
  SALVATION_TEXT_EDIT: "/salvation-text/edit",
  SALVATION_TEXT_VIEW: "/salvation-text/view/:id",
  LANGUAGES: "/languages",
  LANGUAGES_CHAPTER_EDIT: "/languages/chapter/edit",
  DONATION: "/donation",
  COVERS: "/covers",
  ORGANIZATIONS: "/organizations",
  ORGANIZATIONS_EDIT: "/organizations/edit",
  ORGANIZATIONS_VIEW: "/organizations/view/:id",
  TWENTY_ONE_DAY_CHALLENGE: "/twenty-on-days",
  TWENTY_ONE_DAY_CHALLENGE_CREATE: "/twenty-on-days/create",
  TWENTY_ONE_DAY_CHALLENGE_EDIT: "/twenty-on-days/edit",
  TWENTY_ONE_DAY_CHALLENGE_VIEW: "/twenty-on-days/view/:id",
  USERS_MANAGEMENT: "/users-management",
  USERS: "/users",
  LOGOUT: "/logout",
  LOGIN: "/login",
  RESET: "/forgot-password",
  CHANGE_PASSWORD: "/reset",
  VERIFY: "/verify",
  NOTIFICATION:"/notification",
  AUTHORIZE:"/authorize"
};

/*  Modules */
export const MODULES = {
  DASHBOARD: "Dashboard",
  TWENTY_ONE_DAY_CHALLENGE: "21 Day Challenge",
  GOSPEl_TEXT: "Gospel Text",
  SALVATION_TEXT: "Salvation Text",
  LANGUAGES: "Languages",
  DONATION: "Donation",
  COVERS: "Covers",
  ORGANIZATIONS: "Organizations",
  USERS_MANAGEMENT: "User Management",
  NOTIFICATION:"Notification"
};

/* Date and time */
export const defaultDateFormat = "MM/DD/YYYY";
export const secondaryDateFormat = "MMMM Do YYYY";

/* Regex */
export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
  EMAIL: /^[a-z0-9.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
};

/* Authentication */
export const TOKEN = "TOKEN";
export const REFRESH_TOKEN ="REFRESH_TOKEN"
export const USER = "USER";

export const ROLES = {
  SUPER_ADMIN: "Super admin",
  ADMIN: "Admin",
  ORGANIZATION_USER: "Organization User"
};

export const LANGUAGES_FEATURES = {
  SALVATION_TEXT: "Salvation Text",
  TWENTY_ONE_DAY_CHALLENGE: "21 Day Challenge",
  GOSPEL_TEXT: "Gospel text",
};

export const SALVATION_TYPES = {
  QR_CODE: 'QR_CODE',
  SALVATION_LINK: 'SALVATION_LINK'
}
