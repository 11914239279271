import React, { useState, Fragment, useEffect } from "react";

export const ColorPicker = ({ value = "", onChange, readOnly }) => {
  const [color, setColor] = useState(null);
  useEffect(() => {
    if (value) {
      setColor(value);
    }
  }, [value]);
  return (
    <Fragment>
      <input
        style={{
          marginRight: "10px",
          pointerEvents: readOnly ? "none" : "unset",
        }}
        type="color"
        value={value}
        onChange={(e) => {
          setColor(e.target.value);
          onChange(e);
        }}
      />
      {color}
    </Fragment>
  );
};
