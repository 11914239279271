import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "../../common/constants";
import SalvationTextAdd from "./SalvationTextAdd";
import SalvationTextList from "./SalvationTextList";
import SalvationContent from "./SalvationContent";
const Organization = () => {
  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path={`${ROUTES.SALVATION_TEXT_CREATE}`}
          component={SalvationContent}
        />
        <Route
          exact
          path={`${ROUTES.SALVATION_TEXT_EDIT}`}
          component={SalvationTextAdd}
        />
        <Route
          exact
          path={`${ROUTES.SALVATION_TEXT_EDIT}/:id`}
          component={SalvationTextAdd}
        />
        <Route
          exact
          path={`${ROUTES.SALVATION_TEXT}`}
          component={SalvationTextList}
        />
      </Switch>
    </Fragment>
  );
};

export default Organization;
