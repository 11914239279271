import gql from "graphql-tag";

export const CREATE_DAY_CHALLENGE = gql`
  mutation createDayChallenge(
    $day: String!
    $dayContents: [DayChallengeContentInput]
  ) {
    createDayChallenge(data: { day: $day, day_contents: $dayContents }) {
      data {
        id
        day
        day_contents {
          id
          title
          sub_title
          sections
          image
          language {
            id
            name
          }
        }
        updated_at
      }
      status
      message
    }
  }
`;

export const UPDATE_DAY_CHALLENGE = gql`
  mutation updateDayChallenge(
    $day: String
    $order: Int
    $order_action: OrderAction
    $id: ID
  ) {
    updateDayChallenge(
      data: { day: $day, order: $order, order_action: $order_action }
      where: { id: $id }
    ) {
      data {
        id
        day
        day_contents {
          id
          title
          sub_title
          sections
          image
          language {
            id
            name
          }
        }
        updated_at
      }
      status
      message
    }
  }
`;

export const CREATE_DAY_CONTENT = gql`
mutation createDayContent(
  $title: String!
  $sub_title: String
  $day_challenge_id: ID!
  $language_id: ID
  $image: String
  $sections: [JSON],
  $videos:[VideoInput]
) {
  createDayContent(
    data: {
      title: $title
      sub_title: $sub_title
      day_challenge_id: $day_challenge_id
      language_id: $language_id
      image: $image
      sections: $sections
      videos:$videos
    }
  ) {
    data {
      title
      sub_title
      day_challenge_id
      language_id
      image
      sections
    }
    message
    status
  }
}
`;

export const DELETE_DAY_CONTENT = gql`
  mutation deleteDayContent($id: ID) {
    deleteDayContent(where: { id: $id }) {
      message
      status
    }
  }
`;

export const UPDATE_DAY_CONTENT = gql`
  mutation updateDayContent(
    $title: String!
    $sub_title: String
    $day_challenge_id: ID!
    $language_id: ID
    $image: String
    $sections: [JSON]!
    $id: ID
    $videos:[VideoInput]
  ) {
    updateDayContent(
      data: {
        title: $title
        sub_title: $sub_title
        day_challenge_id: $day_challenge_id
        language_id: $language_id
        image: $image
        sections: $sections,
        videos:$videos
      }
      where: { id: $id }
    ) {
      data {
        title
        sub_title
        day_challenge_id
        language_id
        image
        sections
      }
      message
      status
    }
  }
`;

export const GET_SIGNED_PUT_URL = gql`
  query getSignedPutUrl($fileName: String!, $contentType: String!) {
    getSignedPutUrl(
      data: {
        fileName: $fileName
        contentType: $contentType
        acl: "public-read"
      }
    ) {
      signedUrl
      getUrl
    }
  }
`;

export const DELETE_DAY_CHALLENGE = gql`
  mutation deleteDayChallenge($id: ID) {
    deleteDayChallenge(where: { id: $id }) {
      message
      status
    }
  }
`;
