import React, { useState, useEffect } from "react";
import { Card, Divider, Spin, Empty } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { get } from "lodash";
import client from "../../apollo";
import { GET_CHAPTERS, GET_CHAPTER_DETAIL } from "./graphql/Queries";
import  uploadThumbnail  from "../../assets/images/upload-picture.png";
import AddEditVideoModal from "./AddEditVideoModal";

const LanguagesChapterContent = (props) => {  
  const languageId = get(props, "match.params.id");
  const [loading, setQueryLoading] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [languageData, setLanguageData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [chapterDataLoading, setChapterDataLoading] = useState(false);
  const [chapterData, setChapterData] = useState();
  const [activeChapterId, setActiveChapterId] = useState();
  const [activeLanguageId, setActiveLanguageId] = useState();

  useEffect(() => {
    if (languageId) {
      getChaptersList(languageId);
    }

    return () => {
      setQueryLoading(false);
      setChapters([]);
      setLanguageData({});
      setShowModal(false);
      setChapterDataLoading(false);
      setChapterData();
      setActiveChapterId();
      setActiveLanguageId();
    }
  }, [languageId]);

  const getChaptersList = (languageId) => {
    setQueryLoading(true);
    client
      .query({
        query: GET_CHAPTERS,
        fetchPolicy: "network-only",
        variables: {
          where: {language_id: languageId} ,
        },
      }).then(({ data: { chapters } }) => {
        setChapters(chapters?.data);
        setLanguageData(chapters?.language);
      }).catch((e) => console.log(e))
      .finally(() => {
        setQueryLoading(false);
      });
  }

  const addEditChapterHandle = (e, isEdit = false, chapterId, languageId) => {
    e.preventDefault();
    setActiveChapterId(chapterId);
    setActiveLanguageId(languageId)
    if (isEdit) {
      setChapterDataLoading(true);
      client
        .query({
          query: GET_CHAPTER_DETAIL,
          fetchPolicy: "network-only",
          variables: {
            where: {
              chapter_id: chapterId,
              language_id: languageId
            }
          },
        }).then(({ data: { chapter } }) => {
          if (chapter?.chapter_id) {
            setChapterData(chapter);
          }
        }).catch((e) => console.log(e))
        .finally(() => {
          setChapterDataLoading(false);
        });
    }
    setShowModal(true);
  };

  return (
    <Card>
      {showModal && (
        <AddEditVideoModal
          showModal={showModal}
          setShowModal={setShowModal}
          setChapterData={setChapterData}
          chapterData={chapterData}
          isUpdate={chapterData ? true : false}
          chapterDataLoading={chapterDataLoading}
          activeChapterId={activeChapterId}
          activeLanguageId={activeLanguageId}
          getChaptersList={getChaptersList}
          isFieldDisabled={Boolean(languageData?.video_dam_id)}
        />
      )}
      <Spin spinning={loading}>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="language-title">
            <ArrowLeftOutlined
              className="mr-2"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                props?.history?.goBack();
              }}
            />
            {languageData?.name || "-"}
          </div>
        </div>
        <Divider />
        {
          chapters?.length === 0 && <Empty />
        }
        {
          chapters?.map((chapter) => {
            return (
              <div className="chapter-container" key={chapter?.chapter_id}>
                  <div className="chapter-title">
                      <div className="title-content">
                          <div className="title">{chapter?.chapter_title}</div>
                          {
                            chapter?.videos?.length > 0 ?
                              <div className="chapter-btn" onClick={(e) => addEditChapterHandle(e, true, chapter?.chapter_id, chapter?.language_id)}>Edit</div>
                            :
                              <div className="add-video-content">
                                <span>No videos added yet</span>
                                <div className="chapter-btn" onClick={(e) => addEditChapterHandle(e, false, chapter?.chapter_id, chapter?.language_id)}>Add Video</div>
                              </div>
                          }
                      </div>
                  </div>
                  {
                    chapter?.videos?.map((video, index) => {
                      return <div className="chapter-item" key={index}>
                        <div className="thumbnail-container">
                          <img 
                            src={video?.thumbnail_url || uploadThumbnail} 
                            alt={`${video?.description}`} 
                            role="presentation"
                            onError={(e) => {
                              e.target.src = uploadThumbnail;
                            }}
                          />
                        </div>
                        <div className="right-content">
                          <div className="video-url">{video?.video_url}</div>
                          <div className="video-description" dangerouslySetInnerHTML={{ __html: video?.description || '' }} />
                        </div>
                      </div>;
                    }) 
                  }
              </div>
            )
          })
        }
      </Spin>
    </Card>
  );
};

export default LanguagesChapterContent;
