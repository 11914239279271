import gql from "graphql-tag";

export const GET_TWENTY_ONE_DAY_CHALLENGE = gql`
  query dayChallenges(
    $skip: Int
    $first: Int
    $orderBy: DayChallengeOrderByInput
  ) {
    dayChallenges(skip: $skip, first: $first, orderBy: $orderBy) {
      total
      data {
        id
        day
        order
        day_contents {
          id
          image
          title
          language {
            id
            name
          }
          sub_title
          language_id
        }
        updated_at
      }
    }
  }
`;

export const GET_CHALLENGE = gql`
  query dayChallenge($id: ID!) {
    dayChallenge(where: { id: $id }) {
      id
      day
      order
      day_contents {
        id
        title
        sub_title
        sections
        videos
        image
        language_id
        language {
          id
          name
        }
        updated_at
      }
      updated_at
    }
  }
`;

export const GET_LANGUAGES = gql`
  query languages {
    languages {
      id
      name
    }
  }
`;
