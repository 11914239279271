import gql from "graphql-tag";

export const UPDATE_SALVATION_TEXT = gql`
  mutation updateSalvationText($version: String, $id: ID, $organization_id: ID, $is_active: Boolean) {
    updateSalvationText(data: { version: $version, organization_id: $organization_id, is_active: $is_active }, where: { id: $id }) {
      data {
        id
        version
        organization_id
      }
      status
      message
    }
  }
`;

export const UPDATE_SALVATION_CONTENT = gql`
  mutation updateSalvationContent(
    $title: String!
    $description: String
    $language_id: ID
    $blocks: JSON
    $salvationTextId: ID!
    $gospel_completion_text: String!
    $id: ID
  ) {
    updateSalvationContent(
      data: {
        title: $title
        description: $description
        language_id: $language_id
        salvation_text_id: $salvationTextId
        gospel_completion_text: $gospel_completion_text
        blocks: $blocks
      }
      where: { id: $id }
    ) {
      data {
        id
        title
        description
        salvation_text_id
        gospel_completion_text
        language_id
        language {
          id
          name
        }
        blocks
        created_at
        updated_at
      }
      status
      message
    }
  }
`;

export const CREATE_SALVATION_CONTENT = gql`
  mutation createSalvationContent(
    $title: String!
    $description: String
    $language_id: ID
    $blocks: JSON
    $salvationTextId: ID!
    $gospel_completion_text: String!
  ) {
    createSalvationContent(
      data: {
        title: $title
        description: $description
        language_id: $language_id
        salvation_text_id: $salvationTextId
        gospel_completion_text: $gospel_completion_text
        blocks: $blocks
      }
    ) {
      data {
        id
        title
        description
        salvation_text_id
        gospel_completion_text
        language_id
        language {
          id
          name
        }
        blocks
        created_at
        updated_at
      }
      status
      message
    }
  }
`;

export const CREATE_SALVATION_TEXT = gql`
  mutation createSalvationText(
    $version: String!
    $salvationContents: [SalvationTextContentInput]
    $organization_id: ID!
  ) {
    createSalvationText(
      data: { version: $version, salvation_contents: $salvationContents, organization_id: $organization_id }
    ) {
      data {
        version
        organization {
          id
          name
        }
        salvation_contents {
          id
          title
          description
          salvation_text_id          
          language_id
          language {
            id
            name
          }
          blocks
          created_at
          updated_at
        }
        created_at
        updated_at
      }
      status
      message
    }
  }
`;

export const DELETE_SALVATION_TEXT = gql`
  mutation deleteSalvationText($id: ID) {
    deleteSalvationText(where: { id: $id }) {
      message
      status
    }
  }
`;

export const DELETE_SALVATION_CONTENT = gql`
  mutation deleteSalvationContent($id: ID) {
    deleteSalvationContent(where: { id: $id }) {
      message
      status
    }
  }
`;
