import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from '@sentry/react';
import "./index.css";
import Routes from "./Routes";
import * as serviceWorker from "./serviceWorker";
import { AppContextProvider } from "./AppContext";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "./apollo";
import { Button, message } from "antd";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <AppContextProvider>
      <Routes />
    </AppContextProvider>
  </ApolloProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      message.info({
        content: (
          <div>
            New version available! Click Reload to get the latest version.
            <Button
              className="ml-1 mb-0"
              type="link"
              onClick={() => {
                registration &&
                  registration.waiting &&
                  registration.waiting.postMessage &&
                  registration.waiting.postMessage({ type: "SKIP_WAITING" });
                window.location.reload(true);
              }}
            >
              Reload
            </Button>
          </div>
        ),
        duration: 0,
      });
    }
  },
});
