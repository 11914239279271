import React, { useState, useEffect } from "react";
import { Input, Form, Checkbox, Modal, Radio, Row, Col } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { formValidatorRules } from "../../common/utils";
import client from "../../apollo";
import { UPDATE_LANGUAGE, CREATE_LANGUAGE } from "./graphql/Mutations";
const { required } = formValidatorRules;

const { TextArea } = Input;
const { confirm } = Modal;

const AddLanguageModal = (props) => {
  const {
    isUpdate,
    showModal,
    setShowModal,
    languageData,
    setLanguageData,
  } = props;
  const [loading, setLoading] = useState(false);
  const [fcbhSelected, setFcbhSelected] = useState(true);
  const [form] = Form.useForm();

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
    setLanguageData();
  };


  const onUserSubmitFinish = async (values) => {
    if (isUpdate) {
      if (((languageData?.book_id !== values?.book_id) || (languageData?.bible_id !== values?.bible_id) || (languageData?.video_dam_id !== values?.video_dam_id)) && fcbhSelected) {
        confirm({
          closable: true,
          icon: <ExclamationCircleOutlined />,
          title: "Alert",
          content: 'Changing "Book ID", "Bible ID" or "Video Dam ID" will reset your video selection. You need to add all videos according to chapter.',
          okText: "Yes",
          cancelText: "No",
          onOk: async () => {
            addEditLanguageHandler(values);
          },
        });
        return;
      }

    }

    addEditLanguageHandler(values);
  };

  const addEditLanguageHandler = async (values) => {
    setLoading(true);
    const variables = isUpdate
      ? { data: { ...values }, where: { id: languageData.id } }
      : { data: { ...values } };
    try {
      await client.mutate({
        mutation: isUpdate ? UPDATE_LANGUAGE : CREATE_LANGUAGE,
        variables: variables,
      });
      props.tableRef();
      setShowModal(false);
      setLanguageData();
      form.resetFields();
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  }

  const handleAdd = () => {
    setShowModal(true);
    form.submit();
  };

  useEffect(() => {
    if (languageData) {
      if (languageData?.service_type === 'FCBH') {
        setFcbhSelected(true)
      }
      else {
        setFcbhSelected(false)
      }
    }
  }, [languageData]);


  return (
    <Modal
      title={isUpdate ? "Edit language" : "Add language"}
      visible={showModal}
      confirmLoading={loading}
      onOk={handleAdd}
      className="dialog language"
      okText={isUpdate ? "Save" : "Add"}
      onCancel={handleCancel}
      width={700}
    >
      <Form
        form={form}
        initialValues={isUpdate ? languageData : { audience_target: "NORMAL", service_type: "FCBH" }}
        layout="vertical"
        onFinish={onUserSubmitFinish}
      >
        <Form.Item rules={[required]} name="name" label="Name">
          <Input allowClear />
        </Form.Item>
        <Form.Item
          rules={[required]}
          name="availability"
          label="Select Features"
        >
          <Checkbox.Group
            options={[
              { label: "Salvation Text", value: "SALVATION_TEXT" },
              { label: "21 Day Challenge", value: "TWENTY_ONE_DAY_CHALLENGE" },
              { label: "Gospel text", value: "GOSPEL_TEXT" },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="service_type"
          label="Content Source"
        >
          <Radio.Group>
            <Radio value="FCBH" onChange={() => {
              setFcbhSelected(true)
            }}>
              FCBH</Radio>
            <Radio value="DOOR43"
              onChange={() => {
                setFcbhSelected(false)
              }}>Dot Libras</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="audience_target"
          label="Audience Target"
        >
          <Radio.Group>
            <Radio value="NORMAL">Hearing Community</Radio>
            <Radio value="DEAF_COMMUNITY">Deaf Community</Radio>
          </Radio.Group>
        </Form.Item>
        {fcbhSelected && (
          <>

            <Row gutter={16} className="overlap-row-direction">
              <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item rules={[required]} name="book_id" label="Book ID">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item rules={[required]} name="bible_id" label="Bible ID">
                  <Input allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className="overlap-row-direction">
              <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item rules={[required]} name="text_dam_id" label="Text Dam ID">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item rules={[required]} name="audio_dam_id" label="Audio Dam ID">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="video_dam_id" label="Video Dam ID">
                  <Input allowClear />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {!fcbhSelected && (
          <>
            <Form.Item rules={[required]} name="owner_name" label="Author">
              <Input allowClear />
            </Form.Item>
            <Form.Item rules={[required]} name="repo_name" label="Repo">
              <Input allowClear />
            </Form.Item>
          </>
        )}
        <Form.Item rules={[required]} name="share_title" label="Share Title">
          <Input allowClear />
        </Form.Item>
        <Form.Item rules={[required]} name="share_description" label="Share Description">
          <TextArea allowClear rows={8} className="pr-4 pt-3" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddLanguageModal;
