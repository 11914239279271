import React, { useState, useContext, useEffect } from "react";
import { Avatar, Popover } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { ROUTES } from "../../../common/constants";
import avatar from "../../../assets/user_placeholder.png";
import { AppContext } from "../../../AppContext";

const UserProfile = ({ history }) => {
  const { getCurrentUser } = useContext(AppContext);
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  const { first_name, last_name } = getCurrentUser() || {};
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li
        onClick={() => {
          history.push(ROUTES.LOGOUT);
        }}
      >
        Logout
      </li>
    </ul>
  );

  return (
    <div className="gx-avatar-row">
      <Popover
        className="d-flex flex-row align-items-center"
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <Avatar
          src={avatar}
          className="gx-size-35 gx-pointer mr-2"
          alt="Avatar"
        ></Avatar>

        <span className="gx-avatar-name d-flex flex-row align-items-center">
          {`${first_name} ${last_name}`}
          <DownOutlined className=" gx-fs-xxs ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default withRouter(UserProfile);
