import React, { useState, useContext, useEffect } from "react";
import { Layout, Select } from "antd";
import { get, find } from "lodash";
import UserProfile from "./UserProfile";
import { AppContext } from "../../../AppContext";
import { GET_ORGANIZATIONS } from "../../../modules/organization/graphql/Queries";
import { ROUTES } from "../../../common/constants";
import client from "../../../apollo";

const { Header } = Layout;
const { Option } = Select;

function AppHeader() {
  const {getCurrentUserRole, getCurrentUser, dispatch, state: { selectedOrganization: { id: selectedId } }} = useContext(AppContext);
  const [organizationList, setOrganizationList] = useState([]);
  const { organization_id } = getCurrentUser() || {};

  const getSelectedOrganizationName = () => {
    if (organization_id && organizationList?.length > 0) {
      const organization = find(organizationList, (org) => {
        return org.id === organization_id
      })

      if (organization) {
        return organization?.name;
      }
      return "-";
    }
  }

  useEffect(() => {
    if (!selectedId) {
      client.query({
        query: GET_ORGANIZATIONS,
        fetchPolicy: "network-only"
      }).then((res) => {
        const orgList = get(res, "data.organizations.data");
        if (orgList?.length > 0) {
          setOrganizationList(orgList);
  
          if (getCurrentUserRole() === "ORGANIZATION_USER") {  
            const organization = find(orgList, (org) => {
              return org.id === organization_id
            })
      
            if (organization) {
              dispatch({type: "SET_SELECTED_ORGANIZATION", data: { id: organization?.id, uuid: organization?.uuid }});
            }
          } else {
            dispatch({type: "SET_SELECTED_ORGANIZATION", data: { id: "all", uuid: "all" }});
          }
        }
      }).catch(() => { 
      }).finally(() => {
      });
    }
  }, [dispatch, selectedId, getCurrentUserRole, organization_id]);

  const handleChange = (value, { key = "" }) => {
    dispatch({type: "SET_SELECTED_ORGANIZATION", data: { id: value, uuid: key }});
  }

  return (
    <Header>
      <div className="gx-search-bar gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg search-container">
        <div className="d-flex align-items-center">
          {/* <div className="gx-linebar">
            <div className="gx-icon-btn icon">
              <MenuFoldOutlined />
            </div>
          </div> */}
          {/* <Link to="/" className="gx-site-logo">
            <img src={logo} style={{ width: '150px' }} />
          </Link> */}
        </div>
      </div>
      {(window.location.pathname === ROUTES.DASHBOARD) && (getCurrentUserRole() === "SUPER_ADMIN" || getCurrentUserRole() === "ADMIN") && (
            <div className="organization-container">
                <Select 
                  className="mr-3" 
                  placeholder="Select Organization" 
                  onChange={handleChange} 
                  value={selectedId}
                >
                  {
                    organizationList.map((org) => <Option key={org?.uuid} value={org?.id}>
                    {org?.name}
                  </Option>
                  )}
                  <Option key="all" value="all">
                    All Organizations
                  </Option>
                </Select>
            </div>
          )
      }

      {
        getCurrentUserRole() === "ORGANIZATION_USER" && (
          <div className="organization-container label">
            {getSelectedOrganizationName()}
          </div>
        )
      }
      <div className="header-notification">
        {/* <BellOutlined
          className="notification-bell"
          onClick={this.toggleNotificationBar}
        /> */}
        <UserProfile />
      </div>
      {/* <Drawer
        placement="right"
        closable={false}
        onClose={this.toggleNotificationBar}
        visible={this.isNotificationOpened}
      ></Drawer> */}
    </Header>
  );
}
export default AppHeader;
