import React, { useRef } from "react";
import { Card, Button, Tooltip, Modal } from "antd";
import CommonTable from "../../components/CommonTable";
import { GET_TWENTY_ONE_DAY_CHALLENGE } from "./graphql/Queries";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import { formatDate } from "../../common/utils";
import { ROUTES, secondaryDateFormat } from "../../common/constants";
import {
  DELETE_DAY_CHALLENGE,
  UPDATE_DAY_CHALLENGE,
} from "./graphql/Mutations";
import client from "../../apollo";
import { find } from "lodash";
const { confirm } = Modal;
const TwentyOneDayChallengeList = ({ history }) => {
  const refreshListRef = useRef();
  const onEditHandle = (id) => {
    history.push(`${ROUTES.TWENTY_ONE_DAY_CHALLENGE_EDIT}/${id}`);
  };

  const onUpHandle = (id, order) => {
    client
      .mutate({
        mutation: UPDATE_DAY_CHALLENGE,
        variables: { order, order_action: "UP", id: parseInt(id) },
      })
      .then(() => {
        refreshListRef.current();
      })
      .catch((e) => e);
  };

  const onDownHandle = (id, order) => {
    client
      .mutate({
        mutation: UPDATE_DAY_CHALLENGE,
        variables: { order, order_action: "DOWN", id: parseInt(id) },
      })
      .then(() => {
        refreshListRef.current();
      })
      .catch((e) => e);
  };

  const onDeleteHandle = (id) => {
    confirm({
      closable: true,
      icon: <ExclamationCircleOutlined />,
      title: "Are you Sure?",
      content: "Do you want to remove this Challenge?",
      okType: "danger",
      onOk: async () => {
        client
          .mutate({
            mutation: DELETE_DAY_CHALLENGE,
            variables: { id: parseInt(id) },
          })
          .then(() => {
            refreshListRef.current();
          })
          .catch((e) => e);
      },
    });
  };

  const columns = [
    {
      title: "Day",
      dataIndex: "day",
    },
    {
      title: "Title",
      dataIndex: "day_contents",
      width: "40%",
      render: (value) => {
        const selectedContent = find(
          value,
          ({ language }) => language && language.name === "English",
        );
        if (!selectedContent) {
          return value && value[0] && value[0].title;
        }
        return <div className="break-word">{selectedContent && selectedContent.title}</div>;
      },
    },
    {
      title: "Last Updated On",
      dataIndex: "updated_at",
      render: (date) => formatDate(date, secondaryDateFormat),
    },
    {
      title: "Navigation",
      dataIndex: "id",
      width: "120px",
      render: (id, record) => {
        return (
          <div className="action-icons">
            <Tooltip title="Up">
              <Button type="link" onClick={() => onUpHandle(id, record.order)}>
                <ArrowUpOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Down">
              <Button
                type="link"
                onClick={() => onDownHandle(id, record.order)}
              >
                <ArrowDownOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "120px",
      render: (id) => {
        return (
          <div className="action-icons">
            <Tooltip title="Edit">
              <Button type="link" onClick={() => onEditHandle(id)}>
                <EditOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button type="link" danger onClick={() => onDeleteHandle(id)}>
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleAdd = () => {
    history.push(`${ROUTES.TWENTY_ONE_DAY_CHALLENGE_CREATE}`);
  };

  return (
    <Card>
      <div className="d-flex align-items-center justify-content-between">
        <h1>21 Day Challenge</h1>
        <Button type="primary" onClick={handleAdd}>
          Add Challenge Day
        </Button>
      </div>
      <hr />
      <CommonTable
        query={GET_TWENTY_ONE_DAY_CHALLENGE}
        fallbackSorting={"order_ASC"}
        columns={columns}
        setTableMethods={({ refreshList }) => {
          refreshListRef.current = refreshList;
        }}
        responseAccessor="data.dayChallenges.data"
        totalAccessor="data.dayChallenges.total"
      />
    </Card>
  );
};

export default TwentyOneDayChallengeList;
