/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { Input, Modal, Spin, Button, Upload, message, Table } from "antd";
import { get, map, cloneDeep, every } from "lodash";
import {
  DeleteOutlined,
  PlusOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import uploadThumbnail from "../../assets/images/upload-picture.png";
import { uploadFile } from "../../common/utils";
import client from "../../apollo";
import { GENERATE_SIGNED_URL, ADD_CHAPTER_VIDEOS } from "./graphql/Mutations";
import TextEditor from "../../components/TextEditor";

const editorToolbar = [
  ['bold', 'italic', 'strike', 'underline'],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  ['link']
]

const AddLanguageModal = (props) => {
  const {
    isUpdate,
    showModal,
    setShowModal,
    chapterData,
    setChapterData,
    chapterDataLoading,
    activeChapterId,
    activeLanguageId,
    getChaptersList,
    isFieldDisabled,
  } = props;
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [checkValidation, setCheckValidation] = useState(false);
  const [toggleData, setToggleData] = useState(false);

  useEffect(() => {
    return () => {
      setLoading(false);
      setCheckValidation(false);
      setTableData([]);
      setToggleData(false);
    };
  }, []);

  useEffect(() => {
    const dataCopy  = cloneDeep(tableData)?.map(
      (item, i) => ({ ...item, index: i })
    );
    setTableData(dataCopy);
  }, [tableData?.length]);

  useEffect(() => {
    if (isUpdate) {
      if (chapterData?.videos?.length > 0) {
        const tableDataCopy = chapterData?.videos?.map((video, itemIndex) => ({
          thumbnail_url: video?.thumbnail_url
            ? [
                {
                  uid: video?.id,
                  name: video?.video_url,
                  status: "done",
                  url: video?.thumbnail_url,
                },
              ]
            : [],
          video_url: video?.video_url,
          description: video?.description,
          index: itemIndex,
        }));
        setTableData(tableDataCopy);
      }
    }
  }, [isUpdate, chapterData]);

  const handleCancel = () => {
    setShowModal(false);
    setChapterData();
    setCheckValidation(false);
    setToggleData(false);
  };

  const addUpdateChapterHandler = async (data = []) => {
    await client
      .mutate({
        mutation: ADD_CHAPTER_VIDEOS,
        variables: {
          where: {
            chapter_id: activeChapterId,
            language_id: activeLanguageId,
          },
          data: data,
        },
      })
      .then(() => {
        getChaptersList(activeLanguageId);
        setShowModal(false);
        setLoading(false);
        setChapterData();
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAdd = async () => {
    const tableDataCopy = cloneDeep(tableData);
    const isAllVideoUrlAdded = every(tableDataCopy, (item) => Boolean(item?.video_url));
    if (!isAllVideoUrlAdded) {
      setCheckValidation(true);
      message?.error("Video URLs should not be empty.");
      return;
    }
    let videosData = cloneDeep(tableData);
    setLoading(true);
    if (tableDataCopy?.length > 0) {
      await Promise.all(
        map(tableDataCopy, async (item, index) => {
          if (item?.thumbnail_url?.length > 0) {
            const file = item?.thumbnail_url[0];
            const { name, type, originFileObj } = file;
            if (originFileObj) {
              const variables = {
                data: { fileName: name.replace(/ /g,"_"), contentType: type },
              };

              await client
                .mutate({
                  mutation: GENERATE_SIGNED_URL,
                  variables: variables,
                })
                .then(async (imageResponse) => {
                  const signedUrl = get(imageResponse, "data.getSignedPutUrl.signedUrl");

                  if (signedUrl) {
                    const status = await uploadFile(signedUrl, file?.originFileObj);
                    if (status === 200) {
                      const url = get(imageResponse, "data.getSignedPutUrl.getUrl");
                      videosData = map(videosData, (item, childIndex) => {
                        return index === childIndex
                          ? {
                              ...item,
                              thumbnail_url: url,
                            }
                          : { ...item };
                      });
                    } else {
                      message.error("Files are not uploaded on signed url");
                    }
                  }
                })
                .catch(() => {})
                .finally(() => {});
            } else {
              videosData = map(videosData, (item, childIndex) => {
                return index === childIndex
                  ? {
                      ...item,
                      thumbnail_url: file?.url,
                    }
                  : { ...item };
              });
            }
          } else {
          }
        })
      )
        .then(async () => {
          if (videosData?.length > 0) {
            const data = videosData?.map((item) => {
              return {
                thumbnail_url: Array?.isArray(item?.thumbnail_url)
                  ? undefined
                  : item?.thumbnail_url,
                video_url: item?.video_url?.trim(),
                description: item?.description?.trim(),
              };
            });
            addUpdateChapterHandler(data);
          }
        })
        .catch(() => {})
        .finally(() => {});
    } else {
      addUpdateChapterHandler();
    }
  };

  const handleChange = (info, key) => {
    const tableDataCopy = cloneDeep(tableData);
    if (info?.fileList?.length > 0) {
      const updatedTableData = tableDataCopy?.map((item, index) => {
        return index === key
          ? {
              ...item,
              thumbnail_url: [
                {
                  ...info.fileList[0],
                  url: URL?.createObjectURL(info?.file),
                },
              ],
            }
          : { ...item };
      });
      setTableData(updatedTableData);
      return;
    } else {
      const updatedTableData = tableDataCopy?.map((item, index) => {
        return index === key
          ? {
              ...item,
              thumbnail_url: [],
            }
          : { ...item };
      });
      setTableData(updatedTableData);
    }
  };

  const uploadButton = (
    <div className="add-avatar-container">
      <img src={uploadThumbnail} alt="add-avatar-thumbnail" role="presentation" />
    </div>
  );

  const deleteVideoHandler = (e, rowIndex) => {
    e?.preventDefault();
    e?.stopPropagation();
    setToggleData(false);
    const updatedList = cloneDeep(tableData);
    updatedList?.splice(rowIndex, 1);
    setTableData(updatedList);
  };

  const addVideoHandler = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setToggleData(false);
    setCheckValidation(false);
    const tableDataCopy = cloneDeep(tableData);
    tableDataCopy?.push({
      thumbnail_url: [],
      video_url: "",
      description: "",
      index: tableDataCopy?.length,
    });
    setTableData(tableDataCopy);
  };

  const onChangeInputHandler = (e, rowIndex, key) => {
    e?.preventDefault();
    e?.stopPropagation();
    const value = e?.target?.value;

    const tableDataCopy = cloneDeep(tableData)?.map((item, index) => {
      return index === rowIndex
        ? {
            ...item,
            [key]: value,
          }
        : { ...item };
    });
    setTableData(tableDataCopy);
  };

  const onChangeDescriptionHandler = (value, rowIndex, key) => {
    const tableDataCopy = cloneDeep(tableData)?.map((item, index) => {
      return index === rowIndex
        ? {
            ...item,
            [key]: value,
          }
        : { ...item };
    });
    setTableData(tableDataCopy);
  };

  const upDownChangeHandler = (e, oldIndex, newIndex) => {
    e?.preventDefault();
    e?.stopPropagation();
    setToggleData(false);
    if (newIndex < 0) return;
    const items = cloneDeep(tableData);
    const item = items?.splice(oldIndex, 1)[0];
    items?.splice(newIndex, 0, item);
    setTableData(items);
  };

  const columns = [
    {
      title: "",
      dataIndex: "",
      width: 30,
      className: "drag-visible click-for-refresh",
      render: (value, record, index) => (
        <div className="up-down-btn-container">
          <Button
            type="text"
            disabled={index === 0}
            onClick={(e) => upDownChangeHandler(e, index, index - 1)}
          >
            <ArrowUpOutlined />
          </Button>
          <Button
            type="text"
            disabled={index === tableData?.length - 1}
            onClick={(e) => upDownChangeHandler(e, index, index + 1)}
          >
            <ArrowDownOutlined />
          </Button>
        </div>
      ),
    },
    {
      title: "",
      width: 50,
      dataIndex: "thumbnail_url",
      render: (value, record, index) => {
        return (
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            fileList={value}
            onChange={(info) => handleChange(info, index)}
            showUploadList={{
              showPreviewIcon: false,
              showDownloadIcon: false,
              showRemoveIcon: true,
            }}
            maxCount={1}
            beforeUpload={() => {
              return false;
            }}
            disabled={isFieldDisabled}
          >
            {value?.length === 0 && uploadButton}
          </Upload>
        );
      },
    },
    {
      title: "",
      width: "40%",
      dataIndex: "video_url",
      render: (value, record, index) => {
        return (
          <Input
            placeholder="Enter Video URLs"
            disabled={isFieldDisabled}
            value={value}
            className={checkValidation && value?.length === 0 ? "custom-error-input" : ""}
            onChange={(e) => onChangeInputHandler(e, index, "video_url")}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "description",
      render: (value, record, index) => {
        return (
          <TextEditor
            className="language-video-description-editor"
            toolbar={editorToolbar}
            value={value || ''}
            onChange={(text) => {
              if (toggleData) {
                onChangeDescriptionHandler(text, index, "description");
              }
            }}
            onFocus={(range, source, editor) => {
              setToggleData(true);
            }}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: 50,
      render: (value, record, index) => {
        if (!isFieldDisabled) {
          return (
            <DeleteOutlined
              id="video-custom-delete-btn"
              onClick={(e) => deleteVideoHandler(e, index)}
            />
          );
        }
        return null;
      },
    },
  ];

  return (
    <Modal
      title={isUpdate ? "Edit Videos" : "Add Videos"}
      visible={showModal}
      confirmLoading={loading}
      onOk={handleAdd}
      className="dialog chapter-video"
      okText={isUpdate ? "Save Changes" : "Add"}
      onCancel={handleCancel}
      width={1280}
      destroyOnClose
      maskClosable={false}
    >
      <Spin spinning={chapterDataLoading}>
        {tableData?.length > 0 && (
          <Table
            pagination={false}
            showHeader={false}
            dataSource={tableData}
            columns={columns}
            rowKey="index"
          />
        )}
        {!isFieldDisabled && (
          <div className="add-btn-container">
            <Button type="dashed" onClick={(e) => addVideoHandler(e)} block icon={<PlusOutlined />}>
              Add field
            </Button>
          </div>
        )}
      </Spin>
    </Modal>
  );
};

export default AddLanguageModal;

