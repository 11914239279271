import React, { useRef } from "react";
import { Card, Tooltip, Button, Modal, Switch } from "antd";
import { find, get, clone } from "lodash";
import CommonTable from "../../components/CommonTable";
import { GET_SALVATION_TEXT } from "./graphql/Queries";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { formatDate } from "../../common/utils";
import { ROUTES, secondaryDateFormat } from "../../common/constants";
import { DELETE_SALVATION_TEXT, UPDATE_SALVATION_TEXT } from "./graphql/Mutations";
import client from "../../apollo";
const { confirm } = Modal;

const SalvationTextList = (props) => {
  const tableRef = useRef();

  const handleAdd = () => {
    props.history.push(ROUTES.SALVATION_TEXT_CREATE);
  };

  const onEditHandle = (id) => {
    props.history.push(`${ROUTES.SALVATION_TEXT_EDIT}/${id}`);
  };

  const onEnableDisabled = (id, oldState, newState) => {
    let cloneOldState = clone(oldState)
    let cloneNewState = clone(newState)    
    confirm({
      closable: true,
      icon: <ExclamationCircleOutlined />,
      title: "Are you Sure?",
      content: `Do you want to make it ${cloneOldState ? 'disable' : 'enable'}?`,
      okType: "danger",
      onOk: () => {
        client
          .mutate({
            mutation: UPDATE_SALVATION_TEXT,
            variables: { id, is_active: cloneNewState },
             refetchQueries: [
              {
                query: GET_SALVATION_TEXT,
                fetchPolicy: "network-only",
              },
            ],
          })
          .then(() => {
            tableRef.current();
          })
          .catch((e) => e);
      },
      onCancel : () => {

      }
    });
  }

  const onDeleteHandle = (id) => {
    confirm({
      closable: true,
      icon: <ExclamationCircleOutlined />,
      title: "Are you Sure?",
      content: "Do you want to remove this Salvation Text?",
      okType: "danger",
      onOk: async () => {
        client
          .mutate({
            mutation: DELETE_SALVATION_TEXT,
            variables: { id: parseInt(id) },
          })
          .then(() => {
            tableRef.current();
          })
          .catch((e) => e);
      },
    });
  };

  const columns = [
    {
      title: "Salvation Version",
      dataIndex: "version",
    },
    {
      title: "Organization",
      render: (value) => {
        const orgName = get(value, "organization.name")
        return <div className="break-word">{orgName || ''}</div>;
      },
    },
    {
      title: "Description",
      dataIndex: "salvation_contents",
      width: "40%",
      render: (value) => {
        const selectedContent = find(
          value,
          ({ language }) => language && language.name === "English",
        );
        if (!selectedContent) {
          return value && value[0] && value[0].title;
        }
        return <div className="break-word">{selectedContent && selectedContent.description}</div>;
      },
    },
    {
      title: "Last updated on",
      dataIndex: "updated_at",
      render: (value) => formatDate(value, secondaryDateFormat),
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "160px",
      render: (id, record) => {        
        const isActive = record.is_active
        return (
          <div className="action-icons">
            <Tooltip title="Edit">
              <Button type="link" onClick={() => onEditHandle(id)}>
                <EditOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                type="link"
                className="mb-0"
                danger
                onClick={() => onDeleteHandle(id)}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
            <Tooltip title={isActive ? "Enabled" : "Disabled"}>
              <Switch size="small" className="ml-1" 
                checked={record.is_active}                
                onChange={(e) => { 
                  onEnableDisabled(parseInt(record.id), record.is_active, e) 
                }} />
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <Card>
      <div className="d-flex align-items-center justify-content-between">
        <h1>Salvation Text</h1>
        <Button
          type="primary"
          onClick={() => {
            handleAdd();
          }}
        >
          Add Salvation Text
        </Button>
      </div>
      <hr />
      <CommonTable
        setTableMethods={({ refreshList }) => {
          tableRef.current = refreshList;
        }}
        query={GET_SALVATION_TEXT}
        columns={columns}
        responseAccessor="data.salvationTexts.data"
        totalAccessor="data.salvationTexts.total"
      />
    </Card>
  );
};

export default SalvationTextList;
