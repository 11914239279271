import React, { useContext, useState } from "react";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Form, Input, Button, Spin, message } from "antd";
import { REFRESH_TOKEN, ROUTES } from "../../common/constants";
import { formValidatorRules } from "../../common/utils";
import logo from "../../assets/logo.png";
import { AppContext } from "../../AppContext";
import apiInstance from "../../common/api";
import { get } from "lodash";
const { required, email } = formValidatorRules;

const Login = (props) => {
  const { initializeAuth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  function successCallback(accessToken) {
    initializeAuth(accessToken);
    props.history.replace("/");
  }

  const onFinish = async (values) => {
    setLoading(true);
    var queryString = Object.keys(values)
      .map((key) => key + "=" + values[key])
      .join("&");
    try {
      const response = await apiInstance("/login", {
        method: "POST",
        data: queryString,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      });
      const accessToken = get(response, "data.access_token");
      localStorage.setItem(REFRESH_TOKEN, get(response, "data.refresh_token"));
      if (successCallback) {
        successCallback(accessToken);
      }
    } catch (error) {
      if (get(error, "response.data.message")) {
        message.error(error.response.data.message);
      } else if (error && error.message) {
        message.error(error.message);
      } else {
        message.error("Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="gx-login-container">
      <Spin spinning={loading} wrapperClassName="gx-login-content">
        <div className="gx-login-header gx-text-center mb-0">
          <img src={logo} alt="Pocket Testament League" className="mb-4" />
        </div>
        <Form
          name="Login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          className="gx-login-form gx-form-row0"
        >
          <Form.Item name="email" rules={[required, email]}>
            <Input
              prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item name="password" rules={[required]}>
            <Input.Password
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item className="gx-text-center w-100">
            <Button
              type="primary"
              style={{ width: "100%" }}
              className="w-100"
              htmlType="submit"
            >
              Login
            </Button>
          </Form.Item>
          <Form.Item className="d-flex mb-0 reset-password">
            <a href={ROUTES.RESET}>Forgot Password?</a>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default Login;
