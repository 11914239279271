import React, { useState, useEffect } from "react";
import { Card, Spin, DatePicker, Row, Col } from "antd";
import { get } from "lodash";
import CommonTable from "../../../components/CommonTable";
import { GET_DONATIONS } from "../graphql/Queries";
import { formatDate, formatPrice } from "../../../common/utils";
import client from "../../../apollo";
import { secondaryDateFormat } from "../../../common/constants";

const { RangePicker } = DatePicker;

const dateFormat = 'MM/DD/YYYY';

const DonationList = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({})

  useEffect(() => {
    if (startDate && endDate) {
      setFilters({ start_date: startDate, end_date: endDate });
      getDonationsData({ start_date: startDate, end_date: endDate });
    } else {
      setFilters({});
      getDonationsData({});
    }
  }, [startDate, endDate]);

  const getDonationsData = (filters) => {
    client.query({
      query: GET_DONATIONS,
      variables: { where: filters },
      fetchPolicy: "network-only"
    }).then((res) => {
      setData(get(res, "data.donations"));
    }).catch(() => {
    }).finally(() => {
      setLoading(false)
    });
  }

  const onDateFilterChange = (dates) => {
    if (dates[0].length === 0 || dates[1].length === 0) {
      setStartDate(null)
      setEndDate(null)
    } else {
      setStartDate(dates[0])
      setEndDate(dates[1])
    }
  }

  const columns = [
    {
      title: "User Name",
      dataIndex: "user",
      width: "20%",
      render: (value) => value && `${value.first_name} ${value.last_name}`,
    },
    {
      title: "First Name",
      dataIndex: "user",
      width: "20%",
      render: (value) => value && `${value.first_name}`,
    },
    {
      title: "Last Name",
      dataIndex: "user",
      width: "20%",
      render: (value) => value && `${value.last_name}`,
    },    
    {
      title: "Amount",
      dataIndex: "amount",
      width: "20%",
      render: (value) => value && `$${value}`,
    },
    {
      title: "Date",
      dataIndex: "donation_date",
      width: "40px",
      render: (value) => formatDate(value, secondaryDateFormat),
    },    
  ];

  return (
    (loading && !data) ? <Spin className="d-flex align-items-center justify-content-center" />
      : <Card>
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="m-0">Donation</h1>
          <div className="d-flex align-items-center">
            <RangePicker
              format={dateFormat}
              onChange={(date, dateStrArr) => {
                onDateFilterChange(dateStrArr)
              }}
            />
          </div>
        </div>
        <hr />
        <Row className="mx-1 donation-header-box">
          <Col className="p-0 pr-2" xl={8} md={8} sm={8} xs={24}>
            <div className="d-flex flex-column justify-content-around">
              <div className="grey-bottom-border ml-3 p-1">
                <p className="circle-header-text">Total Revenue</p>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-center circle my-4">
                  <h1 className="font-size-22">{`${data && data.total_revenue ? formatPrice(data.total_revenue) : formatPrice(0)}`}</h1>
                </div>
              </div>
            </div>
          </Col>
          <Col className="p-0 pl-2 pr-2" xl={8} md={8} sm={8} xs={24}>
            <div className="d-flex flex-column justify-content-around grey-left-dashed-border grey-right-dashed-border">
              <div className="grey-bottom-border ml-3 py-1 mr-3">
                <p className="circle-header-text">Number of Gifts</p>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-center circle my-4">
                  <h1 className="font-size-22" >{`${data && data.number_of_gifts ? data.number_of_gifts : 0}`}</h1>
                </div>
              </div>
            </div>
          </Col>
          <Col className="p-0" xl={8} md={8} sm={8} xs={24}>
            <div className="d-flex flex-column justify-content-around">
              <div className="grey-bottom-border ml-3 py-1 mr-3">
                <p className="circle-header-text">Average Gift</p>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-center circle my-4">
                  <h1 className="font-size-22" >{`${data && data.average_gift ? formatPrice(data.average_gift) : formatPrice(0)}`}</h1>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <hr />
        <div className="d-flex">
          <h1 className="m-0">Donation Summary</h1>
        </div>
        <hr />
        <CommonTable
          query={GET_DONATIONS}
          filters={{ where: filters }}
          columns={columns}
          responseAccessor="data.donations.data"
          totalAccessor="data.donations.total"
        />
      </Card>
  )
}

export default DonationList;