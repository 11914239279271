import React, { Component, Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ComingSoon from "../components/ComingSoon";
import { ROUTES } from "../common/constants";
import UserList from "../modules/users/UserList";
import Error404 from "../Error404";
import Dashboard from "../modules/dashboard/Dashboard"
import Organization from "../modules/organization";
import LanguagesList from "../modules/languages";
import TwentyOneDaysChallenge from "../modules/twenty-one-day-challenge";
import SalvationText from "../modules/salvation-text";
import Notification from "../modules/notification/SendNotificationForm";
import Donations from "../modules/donations";

class ContentRoutes extends Component {
  render() {
    return (
      <Fragment>
        <Switch>
          <Route exact path='/' render={() => <Redirect to={ROUTES.DASHBOARD} />} />
          <Route path={ROUTES.ORGANIZATIONS} component={Organization} />
          <Route path={ROUTES.DONATION} component={Donations} />
          <Route path={ROUTES.USERS_MANAGEMENT} component={UserList} />
          <Route path={ROUTES.LANGUAGES} component={LanguagesList} />
          <Route path={ROUTES.SALVATION_TEXT} component={SalvationText} />
          <Route path={ROUTES.NOTIFICATION} component={Notification} />
          <Route
            path={ROUTES.TWENTY_ONE_DAY_CHALLENGE}
            component={TwentyOneDaysChallenge}
          />
          <Route path={ROUTES.DASHBOARD} component={Dashboard} />          
          <Route path="*" exact component={Error404} />
        </Switch>
      </Fragment>
    );
  }
}

export default ContentRoutes;
