import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { from, ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { TOKEN } from "./common/constants";
import { setContext } from "apollo-link-context";
import { ErrorLink } from "apollo-link-error";
import { isObject, get } from "lodash";
import { message } from "antd";
import history from "./histroy";
import * as Sentry from '@sentry/browser';

let disableToastTimeout = null;
export const cacheData = new InMemoryCache();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_URL,
});

const toast = ({ message: content, type }) => {
  message.destroy();
  switch (type) {
    case "info":
      message.info(content);
      break;
    case "success":
      message.success(content);
      break;
    case "warning":
      message.warning(content);
      break;
    case "error":
      message.error(content);
      break;
    default:
      break;
  }
};

const authLink = setContext((ctx, { headers }) => {
  const userToken = localStorage.getItem(TOKEN);
  let newHeaders = headers || {};

  newHeaders = {
    ...newHeaders,
    Authorization: userToken ? `Bearer ${userToken}` : "",
  };

  return {
    headers: newHeaders,
  };
});

const responseMessageLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const { data } = response;

    if (
      data &&
      isObject(data) &&
      Object.keys(data).length > 0 &&
      data[`${Object.keys(data)[0]}`] &&
      data[`${Object.keys(data)[0]}`].message
    ) {
      if (Object.keys(data)[0] === "forgotUserPassword") {
        if (data[`${Object.keys(data)[0]}`].status !== "ERROR") {
          setTimeout(() => {
            toast({
              message:
                data[`${Object.keys(data)[0]}`].message ||
                "Operation successful",
              type: "success",
            });
          }, 1000);
        }
      } else {
        setTimeout(() => {
          const response = data[`${Object.keys(data)[0]}`];

          if (!response) {
            return;
          }

          toast({
            message: response.message || "Operation successful",
            type: response.status === "ERROR" ? "error" : "success",
          });
        }, 1000);
      }
    }
    return response;
  });
});

const errorLink = new ErrorLink((options) => {
  const { graphQLErrors, networkError, response } = options;

  if (networkError && networkError.statusCode === 405) {
    if (disableToastTimeout) {
      clearTimeout(disableToastTimeout);
    }

    disableToastTimeout = setTimeout(() => {
      if (networkError.result && networkError.result.message) {
        toast({
          message: networkError.result.message,
          type: "error",
        });
      }
    }, 200);

    history.replace("/logout");
    return;
  }

  if (response) {
    response.errors.map((error) => {
      const { message, locations, path, extensions } = error;

      Sentry.captureException(
        new Error(
          `[Response error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );

      if (extensions && extensions.code === "FORBIDDEN") {
        history.replace("/access-denied");
      }

      if (
        extensions &&
        (extensions.code === "UNAUTHENTICATED")
      ) {
        if (message == "Your session expired. Sign in again.") {
          if (window.location.pathname !== '/login' && window.location.pathname !== '/authorize') {
            history.replace({
              pathname: "/authorize",
              state: { currentPage: window.location.pathname },
            });
          }
        } else {
          history.replace("/logout");
        }
      }

      return console.log(
        `[Response error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });
  }

  if (graphQLErrors && graphQLErrors.length > 0) {
    const isForBidden =
      get(graphQLErrors[0], "extensions.code") === "FORBIDDEN";
    const isSessionExpire =
      get(graphQLErrors[0], "extensions.code") === "UNAUTHENTICATED" &&
      get(graphQLErrors[0], "message") === "Your session expired. Sign in again."
      ;

    if (!isForBidden && !isSessionExpire) {
      setTimeout(() => {
        toast({
          message: graphQLErrors[0].message,
          type: "error",
        });
      }, 1000);
    }
  } else {
    setTimeout(() => {
      toast({
        message: "Something went wrong!",
        type: "error",
      });
    }, 1000);
  }


  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    Sentry.captureException(
      new Error(
        `[Network error]: ${networkError}`,
      ),
    );
  }
});

const client = new ApolloClient({
  cache: cacheData,
  link: from([responseMessageLink, errorLink, authLink, httpLink]),
});

export default client;
