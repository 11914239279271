import React, { useState, useEffect } from "react";
import {
  Select,
  Form,
  Input,
  Button,
  Row,
  Col,
  Tooltip,
  Card,
  Divider,
  Modal,
  Spin,
} from "antd";
import { useQuery ,useLazyQuery} from "react-apollo";
import { SortableContainer, arrayMove } from "react-sortable-hoc";
import {
  ArrowLeftOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { get, clone, isArray, map, cloneDeep, isEmpty, uniqBy,filter } from "lodash";
import { v4 as uuid } from "uuid";
import client from "../../apollo";
import history from "../../histroy";
import BlockCell from "./components/BlockCell";
import AddBlockDialog from "./components/AddBlockDialog";
import { GET_LANGUAGES, GET_ORGANIZATIONS } from "../organization/graphql/Queries";
import {
  UPDATE_SALVATION_CONTENT,
  CREATE_SALVATION_CONTENT,
  UPDATE_SALVATION_TEXT,
  DELETE_SALVATION_TEXT,
  CREATE_SALVATION_TEXT,
} from "./graphql/Mutations";
import { uploadAndGetUrl, formatDate, formValidatorRules, getBase64 } from "../../common/utils";
import { ROUTES, secondaryDateFormat } from "../../common/constants";
import TextEditor from "../../components/TextEditor";
import VideoBlockCell from "./components/VideoBlockCell";

const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;
const { required } = formValidatorRules;

const SalvationContent = (props) => {

  const { version = '',
    isEdit = true,
    isView = false,
    salvationText,
    salvationTextId,
    salvationContent: content,
    setIsContentActive,
    setIsEdit,
    setIsView,
    setLoading,
    setSalvationContent,
    updateFound,
  } = props;

  console.log(props)

  const [salvationContentIsEdit, setSalvationContentIsEdit] = useState(false);
  const [openBlock, setOpenBlock] = useState(false);

  const [salvationContentLoading, setSalvationContentLoading] = useState(false);
  const [editValue, setEditValue] = useState();
  const [blocks, setBlocks] = useState([]);

  const [languages, setLanguages] = useState([]);
  const [languageLoading, setLanguageLoading] = useState(true)

  const [getLanguages] = useLazyQuery(
    GET_LANGUAGES,
    {
      fetchPolicy: "network-only",
      onCompleted(res){
        const alreadySelectedLanguages = salvationContent?.map((item) => item?.language?.id)
        let uniqueLanguages = [];
        uniqueLanguages = filter(res?.languages?.data,(language)=>{
          if(!alreadySelectedLanguages?.includes(language?.id)){
            return uniqueLanguages?.push({language})
          }
        })
        if(content){
          const withDefaultLanguage = uniqBy([content?.language,...uniqueLanguages], 'id')
          setLanguages([...withDefaultLanguage])
        } else {
          setLanguages([...uniqueLanguages])
        }
        setLanguageLoading(false)
      },
      onError(){
        setLanguageLoading(false)
      }
    },
  );


  useEffect(() => {
    getLanguages()
  }, [])

  const { data: { organizations = [] } = {}, loading: orgLoading } = useQuery(
    GET_ORGANIZATIONS,
    {
      fetchPolicy: "network-only",
    },
  );

  useEffect(() => {
    if (isEdit) {
      setSalvationContentIsEdit(true);
    }
  }, [isEdit]);

  useEffect(() => {
    if (content) {
      getLanguages()
      setBlocks(content?.blocks);
    }
  }, [content]);

  const onLanguageChange = (e) => {
    const salvationContents = salvationText?.salvation_contents;

    const dayTextContents = salvationContents?.find((text) => {
      return text.language_id === e;
    });
    setBlocks(dayTextContents?.blocks);
    setSalvationContent(dayTextContents)
  };

  const url = props?.match?.url;

  useEffect(() => {
    if (ROUTES.SALVATION_TEXT_CREATE === url) {
      setSalvationContentIsEdit(true);
    }
  }, [url]);

  useEffect(() => {
    if (content?.blocks) {
      setBlocks(content?.blocks);
    }
  }, [content]);

  const onSalvationFinish = async (values) => {
    setSalvationContentLoading(true);
    const { version, ...rest } = values;
    values = rest;
    values.blocks = await Promise.all(
      blocks.map(async ({ image, text, videos }) => {
        if (image) {
          return {
            image: get(image, "file.originFileObj")
              ? await uploadAndGetUrl(
                get(image, "file.originFileObj"),
                "blocks",
                uuid(),
              )
              : image,
          };
        }
        else if (videos) {
          const videoArray = [];
          videoArray.push(await Promise.all(
            map(videos, async (item) => {
              if (!isEmpty(item?.thumbnail_url)) {
                return {
                  videos: get(item?.thumbnail_url?.[0], "originFileObj")
                    ?
                    {
                      ...item,
                      thumbnail_url: await uploadAndGetUrl(
                        get(item?.thumbnail_url?.[0], "originFileObj"),
                        "blocks",
                        uuid(),
                      )
                    }
                    : { ...item }
                }
              }
              else {
                return {
                  videos: { ...item, thumbnail_url: "" }
                }
              }
            })
          ))
          const videoObject = map(videoArray?.[0], (data) => {
            console.log(data?.videos)
            return data?.videos
          })
          return { videos: videoObject }
        }
        else {
          return { text };
        }
      }),
    );
    values.language_id = parseInt(values?.language_id);
    const currentOrganizationId = salvationText?.organization.id;
    if (salvationTextId) {
      if (salvationText.version !== version) {
        client
          .mutate({
            mutation: UPDATE_SALVATION_TEXT,
            variables: { version, id: parseInt(salvationTextId), organization_id: parseInt(currentOrganizationId) },
          })
          .catch((e) => e)
          .finally(() => {
            setLoading(false);
          });
      }
      const variables = {
        ...values,
        salvationTextId: parseInt(salvationTextId),
      };

      if (content && content.id) {
        variables.id = content.id;
        delete variables.organization_id
      }

      client
        .mutate({
          mutation: content
            ? UPDATE_SALVATION_CONTENT
            : CREATE_SALVATION_CONTENT,
          variables: variables,
        })
        .then(() => {
          setIsContentActive(false);
        })
        .catch((e) => e)
        .finally(() => {
          updateFound();
          setLoading(false);
        });
    } else {
      const cloneValues = clone(values)
      const organizationId = values.organization_id
      delete cloneValues.organization_id
      const variables = { version, salvationContents: cloneValues, organization_id: parseInt(organizationId) };
      setSalvationContentLoading(true);
      client
        .mutate({
          mutation: CREATE_SALVATION_TEXT,
          variables: variables,
        })
        .then(() => {
          props.history.goBack();
        })
        .catch((e) => e)
        .finally(() => {
          setSalvationContentLoading(false);
        });
    }
  };

  const Blocks = SortableContainer(
    ({ blocks, editBlock, deleteBlock, hideButtons }) => {
      return (
        <Row>
          <Col span={24}>
            {blocks.map((block, index) => {
              return (
                <>
                  {
                    block?.videos ? (
                      <VideoBlockCell
                        key={index}
                        index={index}
                        videos={block?.videos}
                        hideButtons={hideButtons}
                        deleteBlock={deleteBlock}
                        editBlock={editBlock}
                        isView={isView}
                        cellIndex={index}
                        block={block}
                        isEdit={isEdit}

                      />
                    ) : (
                      <BlockCell
                        key={index}
                        index={index}
                        cellIndex={index}
                        hideButtons={hideButtons}
                        deleteBlock={deleteBlock}
                        editBlock={editBlock}
                        block={block}
                        isView={isView}
                      />
                    )
                  }
                </>
              )
            })}
          </Col>
        </Row>
      );
    },
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setBlocks(arrayMove(blocks, oldIndex, newIndex));
  };

  const editBlock = (block, cellIndex) => {
    setEditValue({ block, cellIndex });
    setOpenBlock(true);
  };

  const deleteBlock = (block, cellIndex) => {
    blocks.splice(cellIndex, 1);
    setBlocks([...blocks]);
  };

  const onEditHandle = () => {
    setIsEdit(true);
  };

  const onDeleteHandle = (id) => {
    confirm({
      closable: true,
      icon: <ExclamationCircleOutlined />,
      title: "Are you Sure?",
      content: "Do you want to remove this Salvation Text?",
      okType: "danger",
      onOk: async () => {
        client
          .mutate({
            mutation: DELETE_SALVATION_TEXT,
            variables: { id: parseInt(id) },
          })
          .catch((e) => e)
          .finally(() => {
            history.push(ROUTES.SALVATION_TEXT);
          });
      },
    });
  };

  const salvationContent = get(props, "salvationText.salvation_contents");
  const currentOrganizationId = get(props, "salvationText.organization.id");
  const currentOrganizationName = get(props, "salvationText.organization.name");

  return (
    <Card>
      {/* Dialog start */}
      <AddBlockDialog
        edit={editValue}
        onAddBlock={(blockData) => {
          if (editValue && editValue.cellIndex !== (undefined || null)) {
            blocks[editValue.cellIndex] = blockData;
            setBlocks([...blocks]);
          } else {
            if (!blocks) {
              setBlocks([blockData]);
            } else {
              blocks.push(blockData);
              setBlocks([...blocks]);
            }
          }
          setEditValue();
        }}
        openBlock={openBlock}
        setEditValue={setEditValue}
        setOpenBlock={setOpenBlock}
      />
      {/* Dialog End */}
      {salvationContentIsEdit && (
        <Spin spinning={languageLoading}>
          <Form
            layout="vertical"
            initialValues={{ ...content, version }}
            onFinish={(values) => {
              onSalvationFinish(values);
            }}
          >
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="d-flex flex-row align-items-center justify-content-start">
                <h2>
                  <ArrowLeftOutlined
                    className="mr-2"
                    onClick={() => {
                      if (!salvationTextId) {
                        history.push(ROUTES.SALVATION_TEXT);
                      } else {
                        setIsContentActive(false);
                      }
                    }}
                  />
                </h2>
                <div className="d-flex flex-column ">
                  <h2>Salvation Text</h2>
                  {content && (
                    <div>
                      {`Last updated on ${formatDate(content.updated_at, secondaryDateFormat)}`}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <div className="d-flex col-lg-12 align-items-center justify-content-end">
                  <Tooltip title="Cancel">
                    <Button
                      className="mr-3"
                      onClick={() => {
                        if (isEdit) {
                          history.goBack();
                        } else {
                          props.history.goBack();
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                  <Tooltip title="Save Salvation Text">
                    <Button type="primary" loading={salvationContentLoading} htmlType="submit">
                      Save
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
            <Divider />
            <div className="form-group row">
              <div className="col-6">
                <Form.Item name="version" label="Add version" rules={[required]}>
                  <Input value={version} disabled={salvationText && salvationText.id} />
                </Form.Item>
                <Form.Item
                  name="language_id"
                  label="Select language"
                  rules={[required]}
                >
                  <Select
                    className="mr-3"
                    placeholder="Languages"
                    loading={languageLoading}
                    allowClear
                  >
                    {languages.map((language) => (
                      language?.id && <Option key={language?.id} value={language?.id}>
                        {language?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="organization_id"
                  label="Select Organization"
                  rules={currentOrganizationId ? [] : [required]}
                >
                  <Select
                    className="mr-3"
                    placeholder="Organizations"
                    loading={orgLoading}
                    disabled={salvationText?.id}
                    defaultValue={currentOrganizationName}
                    allowClear
                  >
                    {
                      organizations?.data?.map((organization) => (
                        organization?.id && <Option key={organization?.id} value={organization?.id}>
                          {organization?.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item name="title" label="Title" rules={[required]}>
                  <Input allowClear />
                </Form.Item>
                <Form.Item name="description" label="Description">
                  <TextArea allowClear rows={8} className="pr-4 pt-3" />
                </Form.Item>
                <Button
                  type="primary"
                  onClick={() => {
                    setOpenBlock(true);
                  }}
                >
                  Add Block
                </Button>
              </div>
              <div className="col-6">
                <Form.Item rules={[required]} name="gospel_completion_text" label="Gospel Completion Text">
                  <TextEditor className='gospel-ql-editor' />
                </Form.Item>
              </div>
            </div>
            <Blocks
              blocks={blocks}
              onSortEnd={onSortEnd}
              useDragHandle={true}
              deleteBlock={deleteBlock}
              editBlock={editBlock}
            />
          </Form>
        </Spin>
      )}
      {!salvationContentIsEdit && (
        <div className="d-flex flex-column">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className="d-flex flex-row align-items-center justify-content-start">
              <h2>
                <ArrowLeftOutlined
                  className="mr-2"
                  onClick={() => {
                    if (!salvationTextId) {
                      history.push(ROUTES.SALVATION_TEXT);
                    } else {
                      setIsContentActive(false);
                    }
                  }}
                />
              </h2>
              <div className="d-flex flex-column ">
                <h2>Salvation Text</h2>
                {content && (
                  <div>
                    {`Last updated on ${formatDate(content?.updated_at, secondaryDateFormat)}`}
                  </div>
                )}
              </div>
            </div>

            <div className="action-icons d-flex justify-content-end">
              <Select
                className="mr-3"
                placeholder="Languages"
                loading={languageLoading}
                defaultValue={
                  content?.language_id
                }
                onChange={onLanguageChange}
              >
                {
                  salvationContent?.map(({ language }) => (
                    language?.id && <Option key={language?.id} value={language?.id}>
                      {language?.name}
                    </Option>
                  ))}
              </Select>
              <Button
                type="link"
                className="m-0 pr-0"
                onClick={() => {
                  setIsView(false)
                  onEditHandle(salvationTextId)
                }}
              >
                <EditOutlined style={{ fontSize: "20px" }} />
              </Button>
              <Button
                type="link"
                className="mb-0"
                danger
                onClick={() => onDeleteHandle(salvationTextId)}
              >
                <DeleteOutlined style={{ fontSize: "20px" }} />
              </Button>
            </div>
          </div>
          <Divider />
          <div style={{ margin: "0 24px" }}>
            <Blocks
              blocks={blocks}
              onSortEnd={onSortEnd}
              useDragHandle={true}
              deleteBlock={deleteBlock}
              editBlock={editBlock}
            />
          </div>
        </div>
      )}
    </Card>
  );
};

export default SalvationContent;
