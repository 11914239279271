import React, { useEffect, useState } from "react";
import { Card, Button, Divider, Tabs, Spin } from "antd";
import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import { getHtmlElement } from "../../common/utils";
import { get, isEmpty, map } from "lodash";
import client from "../../apollo";
import { GET_ORGANIZATION } from "./graphql/Queries";
import { ColorPicker } from "../../components/ColorPicker";
import Covers from "./pages/Covers";
import uploadThumbnail from "../../assets/images/upload-picture.png";
import { SALVATION_TYPES } from "../../common/constants";

const { TabPane } = Tabs;

const OrganizationView = (props) => {
  const organizationId = get(props, "match.params.id");
  const [loading, setQueryLoading] = useState(false);
  const [organization, setOrganization] = useState();

  useEffect(() => {
    if (organizationId) {
      getOrganizationData(organizationId)
    }
  }, [organizationId]);

  const getOrganizationData = (organizationId) => {
    setQueryLoading(true);
    client
      .query({
        query: GET_ORGANIZATION,
        fetchPolicy: "network-only",
        variables: {
          id: parseInt(organizationId),
        },
      }).then(({ data: { organization } }) => {
        setOrganization(organization);
      }).catch((e) => console.log(e))
      .finally(() => {
        setQueryLoading(false);
      });
  }

  const {
    id,
    logo,
    name,
    colors,
    shared_link,
    intro_text,
    cover_image,
    salvation_content,
    covers,
    welcome_videos,
    salvation_links,
    day_challenge_links
  } = organization || {};

  const onEditHandle = (id) => {
    props.history.replace(`/organizations/edit/${id}`);
  };

  const onAddedCover = () => {
    getOrganizationData(organizationId)
  }

  return (
    <Card>
      <div className="d-flex flex-row align-items-center justify-content-between">
        <h2 className="d-flex flex-row align-items-center">
          <ArrowLeftOutlined
            className="mr-2"
            onClick={() => {
              props.history.goBack();
            }}
          />
          {loading ? <Spin></Spin> : <div className="break-word">{name}</div>}
        </h2>
      </div>
      <Divider />
      <Tabs defaultActiveKey="DETAILS" size="large">
        <TabPane tab="Details" key="DETAILS">
          <div className="action-icons d-flex justify-content-end">
            <Button
              type="link"
              className="m-0"
              onClick={() => onEditHandle(id)}
            >
              <EditOutlined style={{ fontSize: "20px" }} />
            </Button>
            {/* <Button
              type="link"
              className="mb-0"
              danger
              onClick={() => onDeleteHandle(id)}
            >
              <DeleteOutlined style={{ fontSize: "20px" }} />
            </Button> */}
          </div>
          {loading && <Spin />}
          {name && !loading && (
            <div className="d-flex flex-sm-column flex-lg-row">
              {logo &&
                <div className="col-lg-2 logo d-flex justify-content-center">
                  <img
                    className="logo"
                    src={logo}
                    alt={'org-image'}
                  />
                </div>}
              <div className="col-lg-8">
                <h1 className="break-word" >{name}</h1>
                <div className="mt-4 d-flex">
                  {shared_link && (
                    <div className="mr-5">
                      <h4 className="text-muted">Shared link</h4>
                      <div>{shared_link}</div>
                    </div>
                  )}
                  {colors && <div>
                    <h4 className="text-muted">Organization color:</h4>
                    <ColorPicker readOnly value={colors} />
                  </div>}
                </div>
                {intro_text && <div className="mt-4">
                  <h4 className="text-muted">Intro Text:</h4>
                  <div>{getHtmlElement(intro_text)}</div>
                </div>}
                {cover_image && (
                  <>
                    <Divider />
                    <h4 className="text-muted">Cover Image:</h4>
                    <img
                      className="cover-image-view"
                      alt={'org-cover-image'}
                      src={cover_image}
                    />
                  </>)}
                {!isEmpty(welcome_videos) && (
                  <>
                    <Divider />
                    <h4 className="text-muted">Welcome Video:</h4>
                    <div className="video-view">
                      <div className="thumbnail-container">
                        <img
                          src={welcome_videos?.[0]?.thumbnail_url || uploadThumbnail}
                          alt={`${welcome_videos?.[0]?.description}`}
                          role="presentation"
                          onError={(e) => {
                            e.target.src = uploadThumbnail;
                          }}
                        />
                      </div>
                      <div className="right-content">
                        <div className="video-url">{welcome_videos?.[0]?.video_url}</div>
                        <div className="video-description" dangerouslySetInnerHTML={{ __html: welcome_videos?.[0]?.description || '' }} />
                      </div>
                    </div>
                  </>
                )}
                {!isEmpty(salvation_links) && (
                  <>
                    <Divider />
                    <h4 className="text-muted">Salvation Links:</h4>
                    {map(salvation_links, (item) => {
                      if (item?.type === SALVATION_TYPES.QR_CODE) {
                        return (
                          <div className="salvation-video-view">
                            <h4>QR Code:</h4>
                            <div className="video-view">
                              <img
                                src={item?.thumbnail_url}
                                alt='qrCode'
                                role="presentation"
                                onError={(e) => {
                                  e.target.src = uploadThumbnail;
                                }}
                                height={100}
                                width={100}
                              />
                            </div>
                          </div>
                        )
                      } else {
                        return (
                          <div className="salvation-video-view">
                            <h4>Video:</h4>
                            <div className="video-view">
                              <div className="thumbnail-container">
                                <img
                                  src={item?.thumbnail_url || uploadThumbnail}
                                  alt={`${item?.description}`}
                                  role="presentation"
                                  onError={(e) => {
                                    e.target.src = uploadThumbnail;
                                  }}
                                />
                              </div>
                              <div className="right-content">
                                <div className="video-url">{item?.salvation_url}</div>
                                <div className="video-description">{item?.description || ''}</div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })}
                  </>
                )}
                {!isEmpty(day_challenge_links) && (
                  <>
                    <Divider />
                    <h4 className="text-muted">21 Day Challenge Videos:</h4>
                    <div className="video-view">
                      <div className="thumbnail-container">
                        <img
                          src={day_challenge_links?.[0]?.thumbnail_url || uploadThumbnail}
                          alt='thumbnail'
                          role="presentation"
                          onError={(e) => {
                            e.target.src = uploadThumbnail;
                          }}
                        />
                      </div>
                      <div className="right-content">
                        <div className="video-url">{day_challenge_links?.[0]?.day_challenge_url}</div>
                        <div className="video-description" >{day_challenge_links?.[0]?.description}</div>
                      </div>
                    </div>
                    <div className="video-view">
                      <div className="thumbnail-container">
                        <img
                          src={day_challenge_links?.[1]?.thumbnail_url || uploadThumbnail}
                          alt='thumbnail'
                          role="presentation"
                          onError={(e) => {
                            e.target.src = uploadThumbnail;
                          }}
                        />
                      </div>
                      <div className="right-content">
                        <div className="video-url">{day_challenge_links?.[1]?.day_challenge_url}</div>
                        <div className="video-description" >{day_challenge_links?.[1]?.description}</div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </TabPane>
        <TabPane tab="Covers" key="COVERS">
          <Covers organizationId={id} covers={covers}
            onAddedCover={() => { onAddedCover() }}
            onDeleteCover={(id) => { getOrganizationData(id || organizationId) }} />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default OrganizationView;
