import {ReactComponent as Dashboard} from  './dashboard.svg'
import {ReactComponent as DaysChallenge} from './challenge.svg'
import {ReactComponent as SalvationText} from './salvation-text.svg'
import {ReactComponent as Languages } from './languages.svg'
import {ReactComponent as Donation} from './donation.svg'
import {ReactComponent as Covers } from './covers.svg'
import {ReactComponent as Organizations} from './organization.svg'
import {ReactComponent as AdminManagement} from './admin-management.svg'
import {ReactComponent as Notification} from './notification.svg'

 const icons = {
  Dashboard,
  DaysChallenge,
  SalvationText,
  Languages,
  Donation,
  Covers,
  Organizations,
  AdminManagement,
  Notification,
}

export default icons