import React, { Fragment, useState } from "react";
import { Card, Button, Row, Col, Modal, message } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import CoverFormModal from "../components/CoverFormModal";
import { DELETE_COVER } from "../graphql/Mutations";
import client from "../../../apollo";

const { confirm } = Modal;

const Covers = (props) => {
  const [showCoverModal, setShowCoverModal] = useState(false);

  const handleAdd = () => {
    setShowCoverModal(true)
  };

  const onDeleteHandle = (id) => {
    const { onDeleteCover } = props
    confirm({
      closable: true,
      icon: <ExclamationCircleOutlined />,
      title: "Are you Sure?",
      content: "Do you want to remove this Cover?",
      okType: "danger",
      onOk: () => {        
        client.mutate({
          mutation: DELETE_COVER,
          variables: { id: parseInt(id) },         
        }).then(() => {
          onDeleteCover(props.organizationId);
        }).catch((error) => {
          if (error && error.message) {
            message.error(error.message);
          } else {
            message.error("Something went wrong");
          }
        });        
      },
    });
  };

  return (
    <Fragment>
      <div className="d-flex align-items-center justify-content-between">
        <h1 />
        <Button type="primary" onClick={handleAdd}>
          Add Cover
        </Button>
      </div>
      <div className="mt-4">
        <Row className="mr-4 ml-4">
          {props && props.covers && props.covers.length > 0 &&
            props.covers.map((content) => (
              <Col key={content.id} xl={6} md={8} sm={12} xs={24}>
                <Card className="cover-card p-1" onClick={() => { }}>
                  <div>
                    <img
                      style={{
                        width: "100%",
                        height: "190px",
                        objectFit: "cover",
                        alignSelf: "start",
                      }}
                      src={content.image}
                      alt={'cover-image'}
                    />
                  </div>
                  <div className="pt-1 d-flex align-items-center justify-content-between">
                    <div className="card-content-container">{content.name}</div>
                    <Button className="m-0" type="link" danger onClick={() => { onDeleteHandle(content.id) }}>
                      <DeleteOutlined />
                    </Button>
                  </div>
                </Card>
              </Col>
            ))}
        </Row>
      </div>
      {showCoverModal && props.organizationId && (
        <CoverFormModal
          organizationId={props.organizationId}
          showCoverModal={showCoverModal}
          setShowCoverModal={setShowCoverModal}
          onAddedCover={props.onAddedCover}
        />
      )}
    </Fragment>
  )
};

export default Covers;