import gql from "graphql-tag";

export const GET_USERS = gql`
  query users($skip: Int, $first: Int, $orderBy: UserOrderByInput) {
    users(skip: $skip, first: $first, orderBy: $orderBy) {
      total
      data {
        id
        first_name
        last_name
        phone_no
        is_active
        email
        roles
        organization_id
      }
    }
  }
`;
