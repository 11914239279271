import React, { useState, useRef, useEffect, useCallback } from "react";
import Modal from "antd/lib/modal/Modal";
import TextEditor from "../../../components/TextEditor";
import { Upload, Form, Button, message, Divider, Table, Input, Spin } from "antd";
import { getBase64 } from "../../../common/utils";
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { cloneDeep, debounce, every, isArray, isEmpty, isObject, map, uniqueId } from "lodash";
import uploadThumbnail from "../../../assets/images/upload-picture.png";

const AddBlockDialog = ({ onAddBlock, openBlock, setOpenBlock, edit, setEditValue }) => {
  const [blockModalImageUrl, setBlockModalImageUrl] = useState();
  const [image, setImage] = useState();
  const [blockText, setBlockText] = useState("");
  const [video, setVideo] = useState(false)
  const [loading, setLoading] = useState(true)
  const [form] = Form?.useForm();
  const [tableData, setTableData] = useState([{
    thumbnail_url: [],
    video_url: "",
    description: "",
    index: 0
  }]);
  const [checkValidation, setCheckValidation] = useState(false);
  const [videoData, setVideoData] = useState([{
    thumbnail_url: [],
    video_url: "",
    description: "",
    index: 0
  }])
  const formRef = useRef("");

  useEffect(() => {
    if (edit) {
      setLoading(true)
      const { block: { text, image, imageUrl, videos } = {} } = edit;
      if (text) {
        form.setFieldsValue({ text });
        setBlockText(text);
        setLoading(false)
      } else if (image) {
        if (!imageUrl) {
          setBlockModalImageUrl(image);
        } else {
          setBlockModalImageUrl(imageUrl);
        }
        setImage(image);
        setLoading(false)
      }
      else {
        setVideo(true)
        const videoDataCopy = videos?.map((video, itemIndex) => {
          return ({
            thumbnail_url: !isArray(video?.thumbnail_url)
              ? (typeof video?.thumbnail_url === 'undefined' || isEmpty(video?.thumbnail_url)) ? [] : [
                {
                  uid: uniqueId(),
                  name: video?.video_url,
                  status: "done",
                  url: video?.thumbnail_url,
                },
              ]
              : ((isArray(video?.thumbnail_url) && !isEmpty(video?.thumbnail_url)) ? video?.thumbnail_url : []),
            video_url: video?.video_url,
            description: video?.description,
            index: itemIndex,
          })
        });
        setVideoData(videoDataCopy)
        setTimeout(() => {
          setLoading(false)
        }, 500);
      }
      return;
    }
    setLoading(false)
  }, [edit]);

  const onAddBlockClick = () => {
    if (blockText || (image && blockModalImageUrl) || video) {
      if (blockText) {
        onAddBlock({ text: blockText })
      }
      else if (image) {
        onAddBlock({ image: image, imageUrl: blockModalImageUrl })
      } else {
        setVideo(true)
        const tableDataCopy = cloneDeep(edit ? videoData : tableData);
        const isAllVideoUrlAdded = every(tableDataCopy, (item) => Boolean(item?.video_url));
        if (!isAllVideoUrlAdded) {
          setCheckValidation(true);
          message.error("Video URLs should not be empty.");
          return;
        }
        onAddBlock({ videos: edit ? videoData : tableData })
      }
      formRef.current.resetFields();
      setOpenBlock(false);
      setBlockModalImageUrl();
      setBlockText();
      setImage();
      setVideo(false)
      setTableData([{}])
    } else {
      message.error("Please fill any one of field");
    }
  }

  const onChangeInputHandler = (e, rowIndex, key) => {
    e.preventDefault();
    e.stopPropagation();
    const value = e?.target?.value;
    if (value) {
      setVideo(true)
      const tableDataCopy = cloneDeep(typeof edit === 'undefined' ? tableData : videoData)?.map((item, index) => {
        return index === rowIndex
          ? {
            ...item,
            [key]: value,
          }
          : { ...item };
      });
      if (edit) {
        setVideoData(tableDataCopy)
      }
      else {
        setTableData(tableDataCopy);
      }
    }
    else if (value === '') {
      setVideoData([{}])
      setTableData([{}])
    }
    else {
      setVideo(false)
    }
  };

  const onChangeDescriptionHandler = (value, rowIndex, key) => {
    if (value) {
      setVideo(true)
      const tableDataCopy = cloneDeep(typeof edit === 'undefined' ? tableData : videoData)?.map((item, index) => {
        return index === rowIndex
          ? {
            ...item,
            [key]: value,
          }
          : { ...item };
      });
      if (edit) {
        setVideoData(tableDataCopy)
      }
      else {
        setTableData(tableDataCopy);
      }
    }
    else {
      setVideo(false)
    }
  };

  const upDownChangeHandler = (e, oldIndex, newIndex) => {
    e.preventDefault();
    e.stopPropagation();
    if (newIndex < 0) return;
    const items = cloneDeep(edit ? videoData : tableData);
    const item = items.splice(oldIndex, 1)[0];
    items.splice(newIndex, 0, item);
    if (edit) {
      setVideoData(items)
    }
    else {
      setTableData(items);
    }
  };


  const handleChange = (info, key) => {
    const tableDataCopy = cloneDeep(edit ? videoData : tableData);
    if (info?.fileList?.length > 0) {
      const updatedTableData = tableDataCopy?.map((item, index) => {
        return index === key
          ? {
            ...item,
            thumbnail_url: [
              {
                ...info.fileList[0],
                url: URL?.createObjectURL(info?.file),
              },
            ],
          }
          : { ...item };
      });
      setVideoData(updatedTableData)
      setTableData(updatedTableData);
      return;
    } else {
      const updatedTableData = tableDataCopy?.map((item, index) => {
        return index === key
          ? {
            ...item,
            thumbnail_url: [],
          }
          : { ...item };
      });
      if (edit) {
        setVideoData(updatedTableData)
      }
      else {
        setTableData(updatedTableData);
      }
    }
  };

  const uploadButton = (
    <div className="add-avatar-container">
      <img src={uploadThumbnail} alt="add-avatar-thumbnail" role="presentation" />
    </div>
  );




  const deleteVideoHandler = (e, rowIndex) => {
    e.preventDefault();
    e.stopPropagation();
    const updatedList = cloneDeep(edit ? videoData : tableData);
    updatedList.splice(rowIndex, 1);
    setTableData(updatedList);
    setVideoData(updatedList);
    if (edit && videoData?.length === 1) {
      setVideo(false)
      setTableData([{}])
      setVideoData([{}])
    }
  };


  const addVideoHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCheckValidation(false);
    const tableDataCopy = cloneDeep(edit ? videoData : tableData);
    tableDataCopy.push({
      thumbnail_url: [],
      video_url: "",
      description: "",
      index: tableDataCopy?.length,
    });
    if (edit) {
      setVideoData(tableDataCopy)
    }
    else {
      setTableData(tableDataCopy);
    }
  };





  const columns = [
    {
      title: "",
      dataIndex: "",
      width: 30,
      className: "drag-visible click-for-refresh",
      render: (value, record, index) => (
        <div className="up-down-btn-container">
          <Button
            type="text"
            disabled={index === 0}
            onClick={(e) => upDownChangeHandler(e, index, index - 1)}
          >
            <ArrowUpOutlined />
          </Button>
          <Button
            type="text"
            disabled={edit ? index === videoData?.length - 1 : index === tableData?.length - 1}
            onClick={(e) => upDownChangeHandler(e, index, index + 1)}
          >
            <ArrowDownOutlined />
          </Button>
        </div>
      ),
    },
    {
      title: "",
      width: 90,
      dataIndex: "thumbnail_url",
      render: (value, record, index) => {
        return (
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            fileList={value}
            onChange={(info) => handleChange(info, index)}
            showUploadList={{
              showPreviewIcon: false,
              showDownloadIcon: false,
              showRemoveIcon: true,
            }}
            maxCount={1}
            beforeUpload={() => {
              return false;
            }}
          >
            {(!value || value?.length === 0) && uploadButton}
          </Upload >
        );
      },
    },
    {
      title: "",
      width: "30%",
      dataIndex: "video_url",
      render: (value, record, index) => {
        return (
          <Input
            placeholder="Enter Video URLs"
            value={value}
            className={checkValidation && value?.length === 0 ? "custom-error-input" : ""}
            onChange={(e) => onChangeInputHandler(e, index, "video_url")}
            allowClear
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "description",
      render: (value, record, index) => {
        return (
          <TextEditor
            className="language-video-description-editor"
            value={value || ''}
            onChange={(text) => {
              onChangeDescriptionHandler(text, index, "description");
            }}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      width: 50,
      render: (value, record, index) => {

        return (
          <div className="d-flex delete-add-button" >
            <DeleteOutlined
              id="video-custom-delete-btn"
              onClick={(e) => deleteVideoHandler(e, index)}
            />
            <Button onClick={(e) => addVideoHandler(e)} className="video-add-button" icon={<PlusOutlined />} />
          </div>
        );


      },
    },
  ];

  return (
    <Modal
      destroyOnClose={true}
      title={edit ? " Edit Block" : "Add Block"}
      okText={edit ? " Edit Block" : "Add Block"}
      className={`${((edit && videoData) || (!edit && tableData)) ? "salvation-add-block width-max-content" : "salvation-add-block"}`}
      visible={openBlock}
      onOk={() => { onAddBlockClick() }}
      onCancel={() => {
        setOpenBlock(false);
        setBlockModalImageUrl();
        setBlockText();
        setImage();
        setVideo(false)
        setCheckValidation(false);
        setEditValue();
        setTableData([{}])
        form.setFieldsValue({ text: undefined });
      }}
    >
      {loading ? <Spin className="spin" /> :
        <Form form={form} ref={formRef}>
          {(!edit || (edit && !blockModalImageUrl && video === false)) && (
            <Form.Item name="text">
              <TextEditor
                defaultValue={blockText}
                value={blockText}
                disabled={blockModalImageUrl || video}
                onChange={(e) => {
                  setBlockText(e);
                }}
              />
            </Form.Item>
          )}
          {!edit && (
            <div className="mt-2 mb-2 d-flex align-item-center justify-content-center">
              <h5 className="text-align-center mb-0">OR</h5>
            </div>
          )}
          {(!edit || (edit && !blockText && video === false)) && (
            <Form.Item name="image">
              <Upload
                className="cover-image"
                disabled={blockText || video}
                accept="image/x-png, image/jpeg, image/jpg"
                customRequest={() => {
                  return false;
                }}
                showUploadList={false}
                listType="picture-card"
                onChange={(info) => {
                  setImage(info);
                  getBase64(info.file.originFileObj, (imageUrl) => {
                    setBlockModalImageUrl(imageUrl);
                  });
                }}
              >
                <div>
                  {blockModalImageUrl && (
                    <img
                      src={blockModalImageUrl}
                      alt="avatar"
                      style={{
                        width: "100%",
                        height: "102px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </div>
              </Upload>
              {blockModalImageUrl && (
                <Button
                  type="link"
                  danger
                  onClick={() => {
                    setBlockModalImageUrl(null);
                  }}
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: "0",
                  }}
                >
                  <DeleteOutlined />
                </Button>
              )}
            </Form.Item>
          )}
          {!edit && (
            <div className="mt-2 mb-2 d-flex align-item-center justify-content-center">
              <h5 className="text-align-center mb-0">OR</h5>
            </div>
          )}
          {(!edit || (edit && !blockModalImageUrl && !blockText)) &&
            <div className="videos-section">
              <p>VIDEOS</p>
              <Divider />
              {tableData?.length > 0 && (
                <Table
                  pagination={false}
                  showHeader={false}
                  dataSource={typeof edit === 'undefined' ? tableData : videoData}
                  columns={columns}
                  rowKey="index"
                  rowClassName={(blockText || blockModalImageUrl) && 'disabled-row'}
                />
              )}
            </div>}
        </Form>}
    </Modal>
  );
};

export default AddBlockDialog;
