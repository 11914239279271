import gql from "graphql-tag";

export const GET_ORGANIZATIONS = gql`
  query organizations(
    $skip: Int
    $first: Int
    $orderBy: OrganizationOrderByInput
  ) {
    organizations(skip: $skip, first: $first, orderBy: $orderBy) {
      total
      data {
        id
        name
        intro_text
        intro_texts
        logo
        shared_link
        colors
        cover_image
        created_by
        is_default
        creator {
          first_name
          last_name
        }        
        created_at
        updated_at
        uuid
        is_default_video_player
      }
    }
  }
`;

export const GET_ORGANIZATION = gql`
query organization($id: ID!) {
  organization(where: { id: $id }) {
    id
    name
    intro_text
    intro_texts
    logo
    shared_link
    colors
    covers{
      id,
      name,
      image,
      organization_id
    }
    created_by
    cover_image     
    created_at
    updated_at
    is_default_video_player
    is_custom
    welcome_videos{
      video_url
      thumbnail_url
      description
    }
    salvation_links{
      salvation_url
      thumbnail_url
      description
      type
    }
    day_challenge_links{
      day_challenge_url
      thumbnail_url
      description
    }
  }
}
`;

export const GET_LANGUAGES = gql`
  query languages {
    languages {
      total
      data {
        id
        name
        availability
        is_active
        created_at
        updated_at
      }
    }
  }
`;


export const GET_COVERS = gql`
  query covers(
    $skip: Int
    $first: Int
    $orderBy: CoverOrderByInput
  ) {
    covers(skip: $skip, first: $first, orderBy: $orderBy) {
      total
      data {
        id
        name
        organization_id 
        image        
        created_at
        updated_at
      }
    }
  }
`;