import React, { useReducer, createContext } from "react";

import { REFRESH_TOKEN, TOKEN, USER } from "./common/constants";
import client from "./apollo";
import api from "./common/api";
import jwtDecode from "jwt-decode";

let loggedInUser = localStorage.getItem(USER);
loggedInUser = loggedInUser ? JSON.parse(loggedInUser) : null;

const initialState = {
  currentUser: loggedInUser || {},
  authToken: localStorage.getItem(TOKEN),
  selectedOrganization: {}
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CURRENT_USER":
      const user = action.data || {};

      localStorage.setItem(
        USER,
        user && Object.keys(user).length ? JSON.stringify(user) : null,
      );
      return { ...state, currentUser: { ...user } };
    case "SET_CURRENT_ROLE":
      return { ...state, currentRole: action.data };
    case "LOGOUT":
      delete api.defaults.headers.common["Authorization"];
      localStorage.removeItem(USER);
      localStorage.removeItem(TOKEN);
      localStorage.removeItem(REFRESH_TOKEN);
      client.clearStore();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        user: {},
        selectedOrganization: {}
      };
    case "SET_FETCHING_USER_DETAILS":
      return { ...state, fetchingUserDetails: action.data };
    case "SET_AUTHENTICATED":
      return { ...state, authenticated: action.data };
    case "SET_TOKEN":
      localStorage.setItem(TOKEN, action.data);
      return { ...state, authToken: action.data };
    case "SET_INITIAL_SHOW_ALL_FIELDS_STATUS":
      return { ...state, initialShowAllFieldStatus: action.data };
    case "SET_SELECTED_ORGANIZATION":
      return { ...state, selectedOrganization: action.data };
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
});
function AppContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () => {
    return localStorage.getItem(TOKEN) || null;
  };

  const getCurrentUser = () => {
    let loggedInUser = localStorage.getItem(USER);

    loggedInUser = loggedInUser ? JSON.parse(loggedInUser) : null;
    return loggedInUser;
  };

  const getCurrentUserRole = () => {
    let loggedInUser = localStorage.getItem(USER);

    loggedInUser = loggedInUser ? JSON.parse(loggedInUser) : null;
    return loggedInUser && loggedInUser.roles && loggedInUser.roles[0];
  };

  const isAuthenticated = () => {
    return state.authenticated;
  };

  const initializeAuth = (authToken) => {
    const token = authToken || getToken();
    if (token) {
      try {
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const userData = jwtDecode(token);
        dispatch({ type: "SET_TOKEN", data: token });
        dispatch({ type: "SET_AUTHENTICATED", data: true });
        dispatch({ type: "SET_CURRENT_USER", data: userData });
        if (userData && userData.roles && userData.roles[0]) {
          dispatch({ type: "SET_CURRENT_ROLE", data: userData.roles[0] });
        }
      } catch (error) {
        return error;      
      }
    }
  };

  const value = {
    state,
    dispatch,
    isAuthenticated,
    getToken,
    initializeAuth,
    getCurrentUserRole,
    getCurrentUser,
  };

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
