import React, { Component } from "react";
import Sidebar from "./components/sidebar/Sidebar";
import AppHeader from "./components/header/AppHeader";
import { Layout } from "antd";
import ContentRoutes from "./ContentRoutes";
import "./App.css";

const { Content, Footer } = Layout;

class App extends Component {
  render() {
    return (
      <Layout className="gx-app-layout">
        <Sidebar />
        <Layout>
          <AppHeader />
          <Content className="gx-layout-content">
            <div className="gx-main-content-wrapper">
              <ContentRoutes />
            </div>
            <Footer>
              <div className="gx-layout-footer-content">Ptl &copy; {new Date().getFullYear()}</div>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default App;
