import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { isArray, isEmpty, isObject, map } from 'lodash';
import React from 'react';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import uploadThumbnail from "../../../assets/images/upload-picture.png";
import { DragHandle } from './BlockCell';




const VideoBlockCell = ({ videos, hideButtons, isView, editBlock, deleteBlock, cellIndex, block, isEdit }) => {
  const HandleButtons = () => {
    return (
      <>
        {!hideButtons && !isView && (
          <div className="d-flex justify-content-end align-items-center">
            <Tooltip title="Drag">
              <DragHandle />
            </Tooltip>
            <Tooltip title="Edit">
              <Button
                type="link"
                className="m-0 p-2 d-flex align-items-center"
                onClick={() => editBlock(block, cellIndex)}
              >
                <EditOutlined className='edit-delete-button' />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                type="link"
                className="m-0 p-2 d-flex align-items-center"
                danger
                onClick={() => deleteBlock(block, cellIndex)}
              >
                <DeleteOutlined className='edit-delete-button' />
              </Button>
            </Tooltip>
          </div>
        )}
      </>
    )
  }
  return (
    <>
      {
        videos?.length === 1 ?
          (map(videos, (video, index) => {
            const { thumbnail_url, video_url, description } = video || {};
            return (
              <div className="gx-dragndrop-item d-flex flex-column align-items-normal">
                <HandleButtons block={video} />
                <div className="video-view-div d-flex" key={index}>
                  <div className="thumbnail-container">
                    {video?.thumbnail_url ?
                      <img src={((isView || isEdit) && (!isObject(video?.thumbnail_url) || (isArray(video?.thumbnail_url) && !isEmpty(video?.thumbnail_url)))) ? (isArray(video?.thumbnail_url) ? video?.thumbnail_url?.[0]?.url : (typeof video?.thumbnail_url === 'undefined' ? [] : video?.thumbnail_url)) : (thumbnail_url?.[0]?.thumbUrl || uploadThumbnail)}
                        alt={`${description}`}
                        role="presentation"
                        height='100%' width='40px' />
                      :
                      <img src={uploadThumbnail}
                        alt={`${description}`}
                        role="presentation"
                        height='100%' width='40px' />
                    }
                  </div>
                  <div className="video-view-div-text">
                    <div className="video-url">{video_url}</div>
                    <div className="video-description" dangerouslySetInnerHTML={{ __html: description || '' }} />
                  </div>
                </div>
              </div>
            )
          }))
          : (
            <>
              <HandleButtons block={videos} />
              <div className='video-div'>
                {map(videos, (video, index) => {
                  const { thumbnail_url, video_url, description } = video || {};
                  return (
                    <>
                      <div className="video-view-div d-flex" key={index}>
                        <div className="thumbnail-container">
                          {video?.thumbnail_url ?
                            <img src={((isView || isEdit) && (!isObject(video?.thumbnail_url) || (isArray(video?.thumbnail_url) && !isEmpty(video?.thumbnail_url)))) ? (isArray(video?.thumbnail_url) ? video?.thumbnail_url?.[0]?.url : (typeof video?.thumbnail_url === 'undefined' ? [] : video?.thumbnail_url)) : (thumbnail_url?.[0]?.thumbUrl || uploadThumbnail)}
                              alt={`${description}`}
                              role="presentation"
                              height='100%' width='40px' />
                            :
                            <img src={uploadThumbnail}
                              alt={`${description}`}
                              role="presentation"
                              height='100%' width='40px' />
                          }
                        </div>
                        <div className="video-view-div-text">
                          <div className="video-url">{video_url}</div>
                          <div className="video-description" dangerouslySetInnerHTML={{ __html: description || '' }} />
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </>
          )
      }
    </>
  )
}


export default SortableElement(VideoBlockCell);