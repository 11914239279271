import gql from "graphql-tag";

export const CREATE_ORGANIZATION = gql`
mutation createOrganization($data: OrganizationCreateInput!) {
  createOrganization(data: $data) {
    data {
      id
      name
      intro_texts
      logo
      shared_link
      colors
      cover_image
      created_by        
      created_at
      updated_at
      is_default_video_player
      is_custom
      welcome_videos{
        video_url
        thumbnail_url
        description
      }
      salvation_links{
        salvation_url
        thumbnail_url
        description
        type
      }
      day_challenge_links{
        day_challenge_url
        thumbnail_url
        description
      }
    }
    status
    message
  }
}
`;

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($data: OrganizationUpdateInput!, $where: OrganizationWhereUniqueInput!) {
    updateOrganization(
      data: $data
      where: $where
    ) {
      data {
        id
        name
        intro_texts
        logo
        shared_link
        colors
        cover_image
        created_by        
        created_at
        updated_at
        is_default_video_player
      }
      status
      message
    }
  }
`;

export const GET_SIGNED_PUT_URL = gql`
  query getSignedPutUrl($fileName: String!, $contentType: String!) {
    getSignedPutUrl(
      data: {
        fileName: $fileName
        contentType: $contentType
        acl: "public-read"
      }
    ) {
      signedUrl
      getUrl
    }
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation deleteOrganization($id: ID) {
    deleteOrganization(where: { id: $id }) {
      message
      status
    }
  }
`;


export const CREATE_COVER = gql`
  mutation createCover(
    $name: String!    
    $image: String    
    $organization_id: ID    
  ) {
    createCover(
      data: {
        name: $name
        image: $image        
        organization_id: $organization_id
      }
    ) {
      data {
        id
        name
        organization_id
        image    
        created_at
        updated_at
      }
      status
      message
    }
  }
`;

export const DELETE_COVER = gql`
  mutation deleteCover($id: ID) 
   {
    deleteCover(where: { id: $id }) {      
      status
      message
    }
  }
`;