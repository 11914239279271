import React, { useState, useEffect, Fragment } from "react";
import { Table, Pagination } from "antd";
import { debounce, get, pick, cloneDeep } from "lodash";

import client from "../apollo";

const initialTableInfo = {
  total: 0,
  current: 0,
  pageSize: 10,
  results: [],
  tableSorter: { field: "", sortOrder: "" },
  showHeader: true,
};

function CommonTable(props) {
  const {
    query,
    columns,
    fetchRecords, // provide from outside.
    filters, // variables send to graphql query.
    fallbackSorting = "createdAt_DESC", // fallback sorting send along with request.
    responseAccessor, // provide path to the property name which have list.
    totalAccessor, // provide path to the property name total number of records.
    setTableMethods, // provide this method if you want to refresh table data from the parent component.
    onSortingChange, // handle sorting pending
    onPageChange, // invoke when page changed. pending
    onSuccess, // invoke when successfully get response.
    onError,
    height, // invoke when error occurred.
    onPageSizeChange, // for future use if needed.
    tableHeaderClasses,
    paginationProps,
  } = props;
  const tableProps = props.tableProps || {};
  const formatedPaginationProps = paginationProps || {}; // provide ant design pagination related options.
  const [tableInfo, setTableInfo] = useState(initialTableInfo);
  const [loading, setLoading] = useState(false);
  const [requestDebounceJob, setRequestDebounceJob] = useState(null);
  const { total, current, pageSize } = tableInfo || {};

  useEffect(() => {
    let newTableInfo = { ...tableInfo, current: 0, results: [] };

    setTableInfo(newTableInfo);
    getList(newTableInfo);
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (setTableMethods) {
      setTableMethods({ refreshList });
    }
    // eslint-disable-next-line
  }, [tableInfo]);

  // call by parent element to refresh the table data.
  function refreshList() {
    getList(tableInfo);
  }

  function getOrderByField(sorter) {
    const { field, order } = sorter || {};
    let fieldName = field;
    let sortOrder = null;

    if (order === "ascend") {
      sortOrder = "ASC";
    } else if (order === "descend") {
      sortOrder = "DESC";
    }

    if (fieldName && sortOrder) {
      fieldName = `${fieldName}_${sortOrder}`;

      return fieldName;
    }

    return fallbackSorting || "createdAt_DESC";
  }

  function getList(updatedTableInfo) {
    if (requestDebounceJob) {
      requestDebounceJob.cancel();
      setRequestDebounceJob(null);
    }

    const debounceJob = debounce(() => {
      if (!query && !fetchRecords) {
        return;
      }

      if (!query) {
        return;
      }

      setLoading(true);

      const { current, total, pageSize, tableSorter } = updatedTableInfo;
      const formatedPageSize = pageSize || 10;
      const skip = (current || 0) * formatedPageSize;
      const formatedFilters = filters || {};
      const orderBy = getOrderByField(tableSorter);
      const queryVariables = {
        ...formatedFilters,
        skip,
        first: formatedPageSize,
        orderBy,
      };

      // For Future Purpose if needed.
      // if (fetchRecords) {
      //   fetchRecords(tableInfo)
      // }

      client
        .query({
          query,
          variables: queryVariables,
          fetchPolicy: "network-only",
        })
        .then((res) => {
          const results = cloneDeep(get(res, responseAccessor) || []);
          const totalRecords = get(res, totalAccessor) || 0;

          setTableInfo((prevTableInfo) => {
            const updatedInfo = {
              ...prevTableInfo,
              total: totalRecords,
              results,
            };

            return updatedInfo;
          });

          if (onSuccess) {
            onSuccess(results);
          }
        })
        .catch((err) => {
          setTableInfo((prevInfo) => {
            return { ...prevInfo, results: [] };
          });

          if (onError) {
            onError({ results: [] });
          }

          return err;
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);

    setRequestDebounceJob(debounceJob);
    debounceJob();
  }

  function handleTableChange(pagination, filters, sorter) {
    let tableSorter = tableInfo.tableSorter || {};
    let newTableSorter = null;

    if (
      sorter &&
      (tableSorter.field !== sorter.field || tableSorter.order !== sorter.order)
    ) {
      newTableSorter = pick(sorter, ["field", "order"]);
    }

    let newTableInfo = { ...tableInfo, current: 0 };

    if (newTableSorter) {
      newTableInfo.tableSorter = newTableSorter;
    }

    setTableInfo(newTableInfo);
    getList(newTableInfo);
  }

  function handlePageChange(page) {
    const newTableInfo = { ...tableInfo, current: page - 1 };

    if (onPageChange) {
      onPageChange(cloneDeep(newTableInfo));
    }

    setTableInfo(newTableInfo);
    getList(newTableInfo);
  }

  return (
    <Fragment>
      <Table
        className="gx-table-responsive"
        columns={columns || []}
        rowKey={(record) => {
          return record.id;
        }}
        {...tableProps}
        // scroll={{ x: '100%', y: height ? height() : 'unset' }} // set y if you want vertical scroll.
        loading={loading}
        pagination={false}
        dataSource={tableInfo.results}
        onChange={handleTableChange}
      />
      <div
        className={`${
          tableHeaderClasses || ""
        } flex-shrink-0 d-flex justify-content-end flex-wrap mt-2`}
      >
        <Pagination
          current={(current || 0) + 1}
          pageSize={pageSize}
          total={total}
          showSizeChanger={false}
          onChange={handlePageChange}
          {...formatedPaginationProps}
        />
        {props.children ? <div>{props.children}</div> : null}
      </div>
    </Fragment>
  );
}

export default CommonTable;
