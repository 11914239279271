import gql from "graphql-tag";

export const CREATE_USER = gql`
  mutation createUser(
    $first_name: String!
    $last_name: String
    $email: String!
    $phone_no: String
    $roles: [Role]!
    $organization_id: ID
  ) {
    createUser(
      data: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        phone_no: $phone_no
        roles: $roles
        organization_id: $organization_id
      }
    ) {
      data {
        id
        first_name
        last_name
        phone_no
        is_active
        email
        roles
        organization_id
      }
      status
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $first_name: String
    $last_name: String
    $email: String
    $phone_no: String
    $roles: [Role]
    $is_active: Boolean
    $id: ID
    $organization_id: ID
  ) {
    updateUser(
      data: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        phone_no: $phone_no
        roles: $roles
        is_active: $is_active
        organization_id: $organization_id
      }
      where: { id: $id }
    ) {
      data {
        id
        first_name
        last_name
        phone_no
        is_active
        email
        roles
        organization_id
      }
      status
      message
    }
  }
`;
